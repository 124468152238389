import * as React from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Error404 } from './features/Error404';
import { Alert } from './services/alert';
import { Wohnmarktanalysen } from './features/Wohnmarktanalysen';
import { Kampagnenfilter } from './features/Kampagnenfilter';
import { Reporting_Analysis } from './features/Reporting_Analysis';
import { Reporting_Overview } from './features/Reporting_Overview';
import { Reporting_Timing } from './features/Reporting_Timing';
import { Onboarding } from './features/Onboarding';
import { Schulung } from './features/Schulung';
import { useAuth0 } from './utils/react-auth0-spa';
import Auth from './services/auth';
import AppLoading from './components/AppLoading';

// Overwrite default google maps loader to load places API with google maps (needed for address auto-complete)
// @ts-ignore
import GoogleMapsLoader from 'google-maps';
import {ReactElement, useEffect, useState} from "react";
import {API} from "./services/API";

const oldLoad = GoogleMapsLoader.load;
GoogleMapsLoader.load = (fn: any) => {
  GoogleMapsLoader.LIBRARIES = ['places'];
  GoogleMapsLoader.KEY = 'AIzaSyDEG4lyorD61vnJoAHG0FkQERZ-McElZyg';
  oldLoad(fn);
};

const App = (): ReactElement => {

    const { isAuthenticated, loginWithRedirect, loading, getTokenSilently, user } = useAuth0();
    const [appLoading, setAppLoading] = useState(true);

    if (loading) {
      return <AppLoading />;
    }

  if (!isAuthenticated) {
      loginWithRedirect({});
      return <div />;
    }

  (async (): Promise<void> => {
    Auth.setAccessToken(await getTokenSilently());
    Auth.setUser(user);
    await API.get('API/auswertungsdatensatz/statusliste')
    setAppLoading(false);

  })();

  if (appLoading) {
    return <AppLoading />;
  }

    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Wohnmarktanalysen} />
            <Route
              exact
              path="/wohnmarktanalysen"
              component={Wohnmarktanalysen}
            />
            <Route
              exact
              path="/kampagnenfilter"
              component={Kampagnenfilter}
            />
            <Route
              exact
              path="/reporting"
              component={Reporting_Overview}
            />
            <Route
              exact
              path="/reporting_analysis"
              component={Reporting_Analysis}
            />
            <Route
              exact
              path="/reporting_timing"
              component={Reporting_Timing}
            />
            <Route exact path="/onboarding" component={Onboarding} />
            <Route exact path="/schulung" component={Schulung} />

            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </div>
    );
}

export default App;
