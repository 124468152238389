import * as React from 'react';
import ReactMapboxGl, {
  Feature,
  Image,
  Layer,
  Popup,
  Source,
  ZoomControl 
} from 'react-mapbox-gl';
import marker from '../assets/map/marker-icon.png';
import "mapbox-gl/dist/mapbox-gl.css";

interface State {
  zoom: [number];
  positions: any[];
  pin : any,
  center : [number , number]
}

interface Props {
  position?: any;
  onPositionChange?: (position: [number, number]) => void;
}

export class SimpleMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      positions : [],
      pin : {},
      zoom : [ DEFAULT_ZOOM ],
      center : DEFAULT_CENTER
    };

    this.selectPin = this.selectPin.bind(this);

  }

  selectPin(pos: { objekt_standort: { coordinates: [number,number] } }) {
    this.setState({...this.state,pin:pos,center:pos.objekt_standort.coordinates,zoom:[11]});
  }

  componentDidMount() {
    this.setState({positions:this.props.position.slice(0, 50)});
  }

  componentDidUpdate = (nextProps: { position: any }) => {
    if (nextProps.position !== this.props.position) {
      this.setState({...this.state,positions:this.props.position.slice(0, 50)});
    }
  }

  render() {

    let hideMarker = false;
    if (this.state.positions.length === 0) hideMarker=true; 
    let positions = this.state.positions;

    return (
      <div style={{ width: '100%', height: 700 }}>
        <MapboxMap
          style={"mapbox://styles/iibmaps/ckromvmcq3up519o1au7nfo37"}
          zoom={this.state.zoom}
          center={this.state.center}
          containerStyle={{ height: '100%', width: '100%' }}
        >
          <ZoomControl position='top-left'/>
          <Source
            id="residential-map-areas-source"
            tileJsonSource={{
              type: 'raster',
              tiles: ['https://prod.gcp-maps.iib-it.de/areas/{z}/{x}/{y}.png'],
              scheme: 'tms',
              tileSize: 256,
            }}
          />
          <Source
            id="residential-map-ratings-source"
            tileJsonSource={{
              type: 'raster',
              tiles: ['https://prod.gcp-maps.iib-it.de/ratings/{z}/{x}/{y}.png'],
              scheme: 'tms',
              tileSize: 256,
            }}
          />
          <Layer
            id="residential-map-areas"
            type="raster"
            sourceId="residential-map-areas-source"
            minZoom={10}
            maxZoom={18}
            before="building"
          />
          <Layer
            id="residential-map-ratings"
            type="raster"
            sourceId="residential-map-ratings-source"
            minZoom={10}
            maxZoom={18}
          />
          <Image id="marker-image" url={marker} options={{ width: 100 }} />
          
          <Layer
            id="locations"
            type="symbol"
            layout={{
              'icon-image': 'marker-image',
              'icon-anchor': 'bottom',
              'icon-allow-overlap': true,
            }}
          >

          { 
            !hideMarker &&
            positions.length > 0 &&
            positions.map((pos: any) => (
              <Feature
                key={pos.objekt_standort.coordinates[0]+ pos.objekt_standort.coordinates[1]}
                coordinates={[pos.objekt_standort.coordinates[0], pos.objekt_standort.coordinates[1]]}
                onClick={() => this.selectPin(pos)}
              />
            ))
          }
          </Layer>

          {
            this.state.pin && 
            this.state.pin.objekt_strasse &&
            this.state.pin.objekt_hausnummer &&
            this.state.pin.objekt_plz &&
            this.state.pin.objekt_ort && (
              <Popup
                  key={this.state.pin.objekt_standort.coordinates[0] + this.state.pin.objekt_standort.coordinates[1]}
                  coordinates={[
                    this.state.pin.objekt_standort.coordinates[0],
                    this.state.pin.objekt_standort.coordinates[1],
                  ]}
                  onClick={() => this.setState({...this.state,pin:{}})}
                  offset={[0, -20]}
                >
                  {this.state.pin.objekt_strasse} {this.state.pin.objekt_hausnummer},{' '}{this.state.pin.objekt_plz} {this.state.pin.objekt_ort}
              </Popup>
              )
            }
        </MapboxMap>

      </div>
    );
  }
}

const MapboxMap = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAP_API ? process.env.REACT_APP_MAP_API : '',
  customAttribution:"Copyright(C) " +
                    new Date().getFullYear() +
                    " by <a href='http://www.iib-institut.de/impressum/' title='Wohnlagenkarte' onclick='window.open(this.href); return false;'>iib-institut.de</a>"
});

const DEFAULT_ZOOM = 4.9;
const DEFAULT_CENTER:[number,number] = [ 10.26517,  51.2481];