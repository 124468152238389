import * as React from 'react';
import '../assets/css/bootstrap.min.css';
import '../assets/css/animate.css';
import '../assets/css/style.css';
import { Link, NavLink, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  location?: any;
}

interface State {}

export class Navigation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  getNavLinkClass = (path: string) => {
    return window.location.pathname === path ? 'active' : '';
  };

  render() {
    let years: number[] = [];
    for (let year = new Date().getFullYear() - 1; year >= 2017; year--) {
      years.push(year);
    }

    return (
      <React.Fragment>
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="logo-big" />
            <div className="logo-element" />
          </li>
          {/* <li className={this.getNavLinkClass('/overview')}>
            <NavLink to="/overview">
              <FontAwesomeIcon icon="home" />
              <span className="nav-label">Übersicht</span>
            </NavLink>
          </li> */}
          <li
            className={
              this.getNavLinkClass('/reporting') ||
              this.getNavLinkClass('/reporting_analysis') ||
              this.getNavLinkClass('/reporting_timing')
            }
          >
            <NavLink activeClassName="active" to="/reporting">
              <FontAwesomeIcon icon="file-alt" />
              <span className="nav-label">Übersicht</span>
            </NavLink>
          </li>
          <li
            className={this.getNavLinkClass('/wohnmarktanalysen')}
            id="wohnmarktanalysen"
          >
            <NavLink activeClassName="active" to="/wohnmarktanalysen">
              <FontAwesomeIcon icon="chart-line" />
              <span className="nav-label">Wohnmarktanalysen</span>
            </NavLink>
          </li>
          <li
            className={this.getNavLinkClass('/kampagnenfilter')}
            id="kampagnenfilter"
          >
            <NavLink activeClassName="active" to="/kampagnenfilter">
              <FontAwesomeIcon icon="bullhorn" />
              <span className="nav-label">Kampagnenfilter</span>
            </NavLink>
          </li>
        </ul>
        <ul className="nav metismenu" id="side-menu">
          <li className={this.getNavLinkClass('/onboarding')} id="onboarding">
            <NavLink activeClassName="active" to="/onboarding">
              <FontAwesomeIcon icon="tasks" />
              <span className="nav-label">Onboarding</span>
            </NavLink>
          </li>
        </ul>
        <ul className="nav metismenu" id="side-menu">
          <li className={this.getNavLinkClass('/schulung')}>
            <NavLink activeClassName="active" to="/schulung">
              <FontAwesomeIcon icon="graduation-cap" />
              <span className="nav-label">Schulung</span>
            </NavLink>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
