import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import Auth from '../services/auth';
import { Alert } from '../services/alert';
import { API } from '../services/API';

interface State {
  show: boolean;
  showSendModal: boolean;
  question: string;
}

interface Props {
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  title: string;
  toggle?: boolean;
  initalShow?: boolean;
  sendQuestion?: any;
  tooltipMessage?: string;
}

interface questionMail {
  userEmail: string;
  question: string;
  chartTitle: string;
  selectedPartner: { id: string; name: string } | null;
  selectedCustomer: { id: string; name: string } | null;
  dateRange: string;
  token: string;
}

export class Box extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      show: true,
      showSendModal: false,
      question: '',
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.sendQuestion = this.sendQuestion.bind(this);
    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
    this.toggleQuestionModal = this.toggleQuestionModal.bind(this);
  }

  toggleQuestionModal() {
    let showSendModal = !this.state.showSendModal;
    let username = Auth.getUsername();
    let token = Auth.getToken();

    if (!username || !token) {
      Alert.error(
        'Ihre Sitzung ist abgelaufen - Bitte loggen sie sich erneut ein.',
      );
      showSendModal = false;
    }

    this.setState(prevState => ({
      showSendModal,
    }));
  }

  async sendQuestion() {
    let username = await Auth.getUsername();
    let token = await Auth.getToken();

    let questionMail = {
      token: token,
      userEmail: username,
      question: this.state.question,
      chartTitle: this.props.sendQuestion.title,
      selectedPartner: this.props.sendQuestion.filter.partner
        ? {
            id: this.props.sendQuestion.filter.partner.id,
            name: this.props.sendQuestion.filter.partner.firma,
          }
        : null,
      selectedCustomer: this.props.sendQuestion.filter.kunde
        ? {
            id: this.props.sendQuestion.filter.kunde.id,
            name: this.props.sendQuestion.filter.kunde.firma,
          }
        : null,
      dateRange: this.props.sendQuestion.filter.zeitraum
        ? this.props.sendQuestion.filter.zeitraum
        : this.props.sendQuestion.filter.zeitraum_von +
          ' ' +
          this.props.sendQuestion.filter.zeitraum_bis,
    } as questionMail;

    API.post('T3API/contact/question', questionMail)
      .then((s: any) => {
        Alert.success(
          'Ihre Frage wurde an uns gesendet! Wir werden uns in Kürze mit Ihnen in Verbindung setzen.',
        );
      })
      .catch((e: any) => {
        Alert.error('Ihre Frage konnte nicht gesendet werden');
      });

    this.toggleQuestionModal();
  }

  componentDidMount() {
    if (typeof this.props.initalShow !== 'undefined')
      this.setState({
        show: this.props.initalShow,
      });
  }

  handleChangeQuestion(event: any) {
    this.setState({
      question: event.target.value,
    });
  }

  handleToggle() {
    if (this.props.toggle) {
      this.setState(prevState => ({
        show: !prevState.show,
      }));
    }
  }

  render() {
    return (
      <div className={'col-lg-' + this.props.size + ' widget_custom'}>
        <div className="ibox ">
          <div className="ibox-title" onClick={this.handleToggle}>
            <h5>{this.props.title}</h5>
            <div className="ibox-tools">
              {this.props.sendQuestion && (
                <span className="mr-2" onClick={this.toggleQuestionModal}>
                  <FontAwesomeIcon icon={'paper-plane'} />
                </span>
              )}
              {this.props.toggle && (
                <span className="mr-2">
                  {this.state.show ? (
                    <FontAwesomeIcon icon={'chevron-down'} />
                  ) : (
                    <FontAwesomeIcon icon={'chevron-up'} />
                  )}
                </span>
              )}
              {this.props.tooltipMessage && (
                <span data-tip={this.props.tooltipMessage}>
                  <FontAwesomeIcon icon={'info-circle'} />
                  <ReactTooltip multiline />
                </span>
              )}
            </div>
          </div>
          {this.state.show && (
            <div className="ibox-content">{this.props.children}</div>
          )}
        </div>
        {this.props.sendQuestion &&
          this.props.sendQuestion.title &&
          this.props.sendQuestion.filter && (
            <Modal
              show={this.state.showSendModal}
              onHide={this.toggleQuestionModal}
            >
              <Modal.Body>
                <p>Frage zu "{this.props.sendQuestion.title}"</p>
                <div className="row mb-2 mt-4 justify-content-center">
                  <div className="col-lg-2">
                    <span className="float-right">
                      <FontAwesomeIcon icon={'paper-plane'} size={'3x'} />
                    </span>
                  </div>
                  <div className="col-lg-10">
                    <h3>Frage zum Diagramm stellen:</h3>
                    <span>
                      Gewählter Zeitraum{' '}
                      <strong>{this.props.sendQuestion.filter.zeitraum}</strong>
                    </span>
                    <br />
                    <br></br>
                    <textarea
                      className="form-control"
                      rows={3}
                      onChange={this.handleChangeQuestion}
                      value={this.state.question}
                    ></textarea>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
                <Button variant="primary" onClick={this.toggleQuestionModal}>
                  Abbruch
                </Button>
                <Button
                  variant="primary"
                  onClick={this.sendQuestion}
                  disabled={this.state.question.length === 0}
                >
                  Senden
                </Button>
              </Modal.Footer>
            </Modal>
          )}
      </div>
    );
  }
}
