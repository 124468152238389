import * as React from "react";
import Highcharts from "highcharts";
// import HC_exporting from 'highcharts/modules/exporting';

import HighchartsReact from "highcharts-react-official";
import funnel from "highcharts/modules/funnel.js";
import { API } from "../services/API";
import Config from "../services/config";

require("highcharts/modules/exporting")(Highcharts);

funnel(Highcharts);
Highcharts.setOptions({
  chart: {
    spacingLeft: 0,
    spacingRight: 0
  }
});
interface State {
  trichterData: any; //Highcharts.Options;
  loading: boolean;
  chartDataLength: number;
  data: any;
}

interface Props {
  dataType: string;
  uuid: string;
}

const colors = [
  // '#039EFA',
  // '#B33469',
  "#F9F871",
  "#00D6E4",
  "#618AE5",
  "#EF6C9C",
  "#00BDFE",
  "#00E9B8",
  "#9062AC"
];

export class TrichterChart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      trichterData: {},
      loading: true,
      chartDataLength: 0,
      data: []
    };
  }

  componentDidMount() {
    this.loadChartData();
  }

  componentDidUpdate() {
    // this.loadChartData();
  }

  loadChartData() {
    if (this.props.dataType === "overview") {
      API.get(`API/auswertungskonfiguration/${this.props.uuid}/trichter`).then(
        s => {
          console.log(s);
          this.setState({
            chartDataLength: s.gesamt,
            data: [
              // ['Leads eingegangen', s.gesamt],
              ["Interne Abrufe", s.interne],
              ["Externe Abrufe", s.externe],
              // ["Kein Interesse", s.keininteresse],
              ["Interessenten", s.interessenten],
              // ["Nicht bearbeitet", s.unbearbeitet],
              // ["Angerufen", s.telefoniert],
              // ["Termin vereinbart", s.termin],
              ["Auftrag", s.weitergeleitet]
            ],
            loading: false
          });
        }
      );
    }
  }

  render() {
    let trichterData = {
      title: {
        text: ""
      },
      plotOptions: {
        series: {
          dataLabels: {
            // inside: true,
            enabled: true,
            format: "<b>{point.name}</b> ({point.y:,.0f})",
            softConnector: false
          },
          center: ["30%", "50%"],
          neckWidth: "10%",
          neckHeight: "30%",
          width: "60%",
          height: "100%",
          animation: false
        }
      },
      series: [
        {
          type: "funnel",
          name: "Anzahl WMAs",
          data: this.state.data
        }
      ],
      colors: Config.getChartColors(),
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG"
            ]
            //   symbol: null,
            //   menuItems: null,
            //   text: 'Download',
            //   onclick: function() {
            //     this.exportChart({
            //       type: 'image/png',
            //     });
            //   },
          }
        }
      }
    } as any;

    return this.state.loading ? (
      <p>Lade Funnel-Daten...</p>
    ) : this.state.chartDataLength === 0 ? (
      <p>Keine Daten vorhanden</p>
    ) : (
      <HighchartsReact highcharts={Highcharts} options={trichterData} />
    );
  }
}
