import * as React from 'react';
import { Layout } from '../components/Layout';
import { Loading } from '../components/Loading';
import { Link } from 'react-router-dom';
import { Box } from '../components/Box';

import { Tabs, Tab } from 'react-bootstrap';

import { API } from '../services/API';

import { MotivWidget } from '../components/widgets/motivWidget';
import { StatsFilter } from '../components/StatsFilter';

import { DoughnutChart } from '../components/DoughnutChart';
import { TrichterChart } from '../components/TrichterChart';

import { SimpleMap } from '../components/SimpleMap';
import { Alert } from '../services/alert';
import { AbrufeWidget } from '../components/widgets/abrufeWidget';
import Config from '../services/config';
import { ImageWidget } from '../components/widgets/imageWidget';

interface Props {
  match?: any;
}

interface State {
  loading: boolean;
  startDate: Date;
  endDate: Date;
  startseitenauswertung: Object;
  uuid: string;
  kunden: Array<any>;
  motivaufteilung: Array<any>;
  selectedKundeId: number | string;
  selectedPartnerId: number | string;
  activeTab: string;
  vertragstypenauswertung: Array<any>;
  abrufaufteilung: Object;
  positions: any;
  activeTabKey: string;
}

export class Reporting_Analysis extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      uuid: '',
      startDate: new Date(),
      endDate: new Date(),
      startseitenauswertung: {},
      motivaufteilung: [],
      kunden: [],
      selectedKundeId: '',
      selectedPartnerId: '',
      activeTab: 'Übersicht',
      vertragstypenauswertung: [],
      abrufaufteilung: {},
      positions: [],
      activeTabKey: 'overview',
    };

    this.refresh = this.refresh.bind(this);
    this.load = this.load.bind(this);
    this.handleChangeTabKey = this.handleChangeTabKey.bind(this);
    this.loadAbrufaufteilung = this.loadAbrufaufteilung.bind(this);
    this.loadVertragstypenauswertung = this.loadVertragstypenauswertung.bind(
      this,
    );
    this.loadMotivaufteilung = this.loadMotivaufteilung.bind(this);
    this.loadStartseitenauswertung = this.loadStartseitenauswertung.bind(this);
    this.handleChangeTabTitle = this.handleChangeTabTitle.bind(this);
  }

  componentDidMount() {
    // //open menu
    // document.body.className = document.body.className.replace(
    //   'mini-navbar',
    //   '',
    // );
    //load initial data
    this.loadStartseitenauswertung().then(s => {
      //load data
      this.load();
    });
  }

  componentDidUpdate() {
    //this.load();
  }

  handleChangeTabTitle(event: any) {
    this.setState({
      activeTab: event,
    });
  }

  handleChangeTabKey(event: any) {
    this.setState({
      activeTabKey: event,
    });
  }

  async load() {
    this.setState({
      loading: true,
    });
    //load parts
    this.loadMotivaufteilung();
    this.loadVertragstypenauswertung();
    this.loadAbrufaufteilung();
    this.renderMap();

    this.setState({
      loading: false,
    });
  }

  async loadAbrufaufteilung() {
    await API.get(
      `API/auswertungskonfiguration/${this.state.uuid}/abrufaufteilung`,
    )
      .then((a: any) => {
        this.setState({
          abrufaufteilung: a,
        });
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Vertragstypen aufgetreten');
      });
  }

  async loadVertragstypenauswertung() {
    await API.get(
      `API/auswertungskonfiguration/${this.state.uuid}/vertragstypenauswertung`,
    )
      .then((a: any) => {
        this.setState({
          vertragstypenauswertung: a,
        });
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Vertragstypen aufgetreten');
      });
  }

  async loadStartseitenauswertung() {
    await API.get(`API/auswertungskonfiguration/startseitenauswertung`)
      .then((a: any) => {
        //set date
        let sd = new Date(a.zeitraum_von);
        let ed = new Date(a.zeitraum_bis);
        this.setState({
          startDate: sd,
          endDate: ed,
          uuid: a.id,
          startseitenauswertung: a,
        });

        if (a.kunde) {
          this.setState({
            selectedKundeId: a.kunde.id,
          });
        }
        if (a.partner) {
          this.setState({
            selectedPartnerId: a.partner.id,
          });
        }
        return Promise.resolve();
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Daten aufgetreten');
        return Promise.reject();
      });
  }

  async patchStartseitenauswertung() {
    await API.patch(
      `API/auswertungskonfiguration/${this.state.uuid}/auswerten`,
      {},
    )
      .then((a: any) => {
        //set date
        let sd = new Date(a.zeitraum_von);
        let ed = new Date(a.zeitraum_bis);

        let kundenId;
        if (a.kunde) kundenId = a.kunde.id;
        else kundenId = '';
        let partnerId;
        if (a.partner) partnerId = a.partner.id;
        else partnerId = '';
        this.setState({
          selectedKundeId: kundenId,
          selectedPartnerId: partnerId,
          startDate: sd,
          endDate: ed,
          uuid: a.id,
          startseitenauswertung: a,
        });

        return Promise.resolve();
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Daten aufgetreten');
        return Promise.reject();
      });
  }

  async loadMotivaufteilung() {
    await API.get(
      `API/auswertungskonfiguration/${this.state.uuid}/motivaufteilung`,
    )
      .then((a: any) => {
        let motive = [] as any;
        {
          Object.keys(a).map(function(key) {
            motive.push(a[key]);
          });
        }

        this.setState({
          motivaufteilung: motive,
        });
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Motive aufgetreten');
      });
  }

  refresh(dataFromCheckbox: any, tab: string) {
    API.put(`API/auswertungskonfiguration/${this.state.uuid}`, {
      id: this.state.uuid,
      bezeichnung: 'Auswertung Übersicht',
      zeitraum_von: dataFromCheckbox.startDate,
      zeitraum_bis: dataFromCheckbox.endDate,
      kunde: !dataFromCheckbox.selectedKundeId
        ? null
        : {
            id: dataFromCheckbox.selectedKundeId,
          },
      partner: !dataFromCheckbox.selectedPartnerId
        ? null
        : {
            id: dataFromCheckbox.selectedPartnerId,
          },
    })
      .then(s => {
        this.patchStartseitenauswertung()
          .then((a: any) => {
            this.load();
            this.setState({ activeTabKey: tab });
          })
          .catch(e => {});
      })
      .catch(error => {
        Alert.error('Fehler beim Update der Abfrage aufgetreten');
      });
  }

  renderMap() {
    API.get(`API/auswertungsdatensatz?auswertung=${this.state.uuid}`).then(
      (s: any) => {
        this.setState({
          positions: s,
        });
      },
    );
  }

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <Layout title={'Reporting // W-Analysen'}>
        <div className="tabs-container">
          <ul className="nav nav-tabs" role="tablist">
            <li>
              <Link className="nav-link" to={'/reporting'}>
                Übersicht
              </Link>
            </li>
            <li>
              <Link className="nav-link active" to={'/reporting_analysis'}>
                W-Analysen
              </Link>
            </li>
            <li>
              <Link className="nav-link " to={'/reporting_timing'}>
                Timing
              </Link>
            </li>
          </ul>
        </div>
        <div className="row mt-3">
          {/* <Box size={12} title="Filter"> */}
          <div className="col-lg-12">
            <div className="ibox bg-white" style={{ padding: 20 }}>
              <StatsFilter
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                selectedKundeId={this.state.selectedKundeId}
                selectedPartnerId={this.state.selectedPartnerId}
                startseitenauswertung={this.state.startseitenauswertung}
                onButtonClicked={(e: any) => this.refresh(e, 'overview')}
              />
              {/* </Box> */}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <Box
            size={6}
            title="Wann: Tageszeit-Übersicht"
            tooltipMessage="Diese detaillierte Zeitachse zeigt Ihnen nicht nur auf, an welchen Tagen <br/>die meisten Abrufe getätigt wurden (horizontale Achse), sondern auch zu welcher Uhrzeit (vertikale Achse).<br/>
Unser Tipp:<br/>
Hieraus lassen sich Schlüsse für diverse Marketingmaßnahmen ziehen. <br/>Zum Beispiel an welchen Wochentagen Sie zu welcher Uhrzeit das Frontend Tool <br/>besonders präsent platzieren sollten, da Ihre Interessenten zu diesem Zeitpunkt besonders aktiv sind.<br/>
Oder auch, wann sie typischerweise mehr oder weniger Personal am Telefon benötigen."
            sendQuestion={{
              title: 'Wann: Tageszeit-Übersicht',
              filter: this.state.startseitenauswertung,
            }}
          >
            {this.state.uuid && this.state.uuid !== '' && (
              <ImageWidget
                imageUrl={`${Config.getAPIURL()}/API/auswertungskonfiguration/${
                  this.state.uuid
                }/tageszeit`}
                alt="Wann: Tageszeit-Übersicht"
              ></ImageWidget>
            )}
          </Box>
          <Box
            size={6}
            title="Was: Abrufe nach Motiven"
            tooltipMessage="Analog zur Balkengrafik auf der Startseite zeigt dieses Diagramm, warum Kunden die Wohnmarktanalyse bestellt haben.<br/> Das hilft ihnen dabei zu priorisieren, um welche Abrufe sie sich zu erst kümmern sollten.<br/>
Durch Anklicken einzelner Kategorien/Motive in der Legende können sie die entsprechenden Abrufe aus der Grafik nehmen.<br/>
Unser Tipp:<br/>
Es kommt häufig vor, dass Nutzer sich noch nicht auf ein Motiv festlegen wollen und daher<br/> „Sonstiges“ oder „Nur bewerten“ auswählen. Diese Abfragen sollten Sie jedoch keineswegs ignorieren <br/>– hier versteckt sich großes Potential! Denn selbst wenn diese Nutzer sich mit Ihrer Entscheidung noch etwas Zeit lassen wollen,<br/> ob sie nun verkaufen, kaufen oder doch mieten wollen, können Sie bereits hier ansetzen und einen wertvollen Kontakt für die Zukunft herstellen."
            sendQuestion={{
              title: 'Was: Abrufe nach Motiven',
              filter: this.state.startseitenauswertung,
            }}
          >
            {/* <div style={{ maxHeight: '400px' }}> */}
            <DoughnutChart dataType="motiv" uuid={this.state.uuid} />
            {/* </div> */}
          </Box>
        </div>

        <div className="row">
          <Box
            size={6}
            title="Wie: Abrufe nach Objekttyp im zeitlichen Verlauf"
            sendQuestion={{
              title: 'Wie: Abrufe nach Objekttyp im zeitlichen Verlauf',
              filter: this.state.startseitenauswertung,
            }}
          >
            {this.state.uuid && this.state.uuid !== '' && (
              <ImageWidget
                imageUrl={`${Config.getAPIURL()}/API/auswertungskonfiguration/${
                  this.state.uuid
                }/timeline`}
                alt="Wie: Abrufe nach Objekttyp im zeitlichen Verlauf"
              ></ImageWidget>
            )}
          </Box>
          <Box
            size={6}
            title="Wer: Interne und externe Abrufe im zeitlichen Verlauf"
            sendQuestion={{
              title: 'Wer: Interne und externe Abrufe im zeitlichen Verlauf',
              filter: this.state.startseitenauswertung,
            }}
          >
            {this.state.uuid && this.state.uuid !== '' && (
              <ImageWidget
                imageUrl={`${Config.getAPIURL()}/API/auswertungskonfiguration/${
                  this.state.uuid
                }/timeline?mode=1`}
                alt="Wer: Interne und externe Abrufe im zeitlichen Verlauf"
              ></ImageWidget>
            )}
          </Box>
        </div>
        <div className="row">
          <Box
            size={6}
            title="Wo: Landkarte"
            sendQuestion={{
              title: 'Wo: Landkarte',
              filter: this.state.startseitenauswertung,
            }}
          >
            <div className="ibox-map">
              <SimpleMap position={this.state.positions} />
            </div>
          </Box>
        </div>
      </Layout>
    );
  }
}
