class Auth {

  static accessToken: string;

  static user: any;

  static accessTokenData: any;

  public static setAccessToken(accessToken: string): void {
    localStorage.setItem('accessToken', accessToken);
    Auth.accessToken = accessToken;
    Auth.accessTokenData = JSON.parse(atob(accessToken.split('.')[1]));
  }

  public static getAccessToken(): string {
    return this.accessToken;
  }

  // TODO
  public static hasValidUser(): boolean {
    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('user');
    if (token && user) {
      Auth.accessToken = token;
      Auth.user = user;
      return true;
    }

    return false;
  }

  public static setUser(user: any): void {
    localStorage.setItem('user', user);
    Auth.user = user;
  }

  public static getUser(): any {
    return this.user;
  }

  public static hasPermission(permissions: string[]): boolean {
    if (!Auth.accessTokenData) return false;

    const userPermissions = Auth.accessTokenData.permissions;

    for (let i = 0; i < permissions.length; i += 1) {
      const permission = permissions[i];
      if (userPermissions.indexOf(permission) > -1) return true;
    }

    return false;
  }

  public static getUsername() {
    return Auth.accessTokenData['https://iib-it.de/email'];
  }

  public static getToken() {
    return this.accessToken;
  }

  public static logout() {
    window.location.href = `https://${process.env.REACT_APP_AUTH_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&returnTo=${window.location.protocol}//${window.location.host}`;
  }

}

export default Auth;
