import * as React from "react";
import { Layout } from "../components/Layout";
import { Loading } from "../components/Loading";
import { Link } from "react-router-dom";
import { Box } from "../components/Box";

import { API } from "../services/API";

import { StatsFilter } from "../components/StatsFilter";

import { BarChart } from "../components/BarChart";
import { Alert } from "../services/alert";
import Config from "../services/config";
import { ImageWidget } from "../components/widgets/imageWidget";
import { TimingWidget } from "../components/widgets/timingWidget";

interface Props {
  match?: any;
}

interface State {
  loading: boolean;
  startDate: Date;
  endDate: Date;
  startseitenauswertung: Object;
  uuid: string;
  kunden: Array<any>;
  motivaufteilung: Array<any>;
  selectedKundeId: number | string;
  selectedPartnerId: number | string;
  activeTab: string;
  vertragstypenauswertung: Array<any>;
  abrufaufteilung: Object;
  positions: any;
  activeTabKey: string;
  timingData: any;
}

export class Reporting_Timing extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      uuid: "",
      startDate: new Date(),
      endDate: new Date(),
      startseitenauswertung: {},
      motivaufteilung: [],
      timingData: [],
      kunden: [],
      selectedKundeId: "",
      selectedPartnerId: "",
      activeTab: "Übersicht",
      vertragstypenauswertung: [],
      abrufaufteilung: {},
      positions: [],
      activeTabKey: "overview"
    };

    this.refresh = this.refresh.bind(this);
    this.load = this.load.bind(this);
    this.handleChangeTabKey = this.handleChangeTabKey.bind(this);
    this.loadAbrufaufteilung = this.loadAbrufaufteilung.bind(this);
    this.loadVertragstypenauswertung = this.loadVertragstypenauswertung.bind(
      this
    );
    this.loadMotivaufteilung = this.loadMotivaufteilung.bind(this);
    this.loadStartseitenauswertung = this.loadStartseitenauswertung.bind(this);
    this.handleChangeTabTitle = this.handleChangeTabTitle.bind(this);
    this.loadTiming = this.loadTiming.bind(this);
  }

  componentDidMount() {
    //open menu
    // document.body.className = document.body.className.replace(
    //   'mini-navbar',
    //   '',
    // );
    //load initial data
    this.loadStartseitenauswertung().then(s => {
      //load data
      this.load();
    });
  }

  componentDidUpdate() {
    //this.load();
  }

  handleChangeTabTitle(event: any) {
    this.setState({
      activeTab: event
    });
  }

  handleChangeTabKey(event: any) {
    this.setState({
      activeTabKey: event
    });
  }

  async load() {
    this.setState({
      loading: true
    });
    //load parts
    this.loadMotivaufteilung();
    this.loadVertragstypenauswertung();
    this.loadAbrufaufteilung();
    this.loadTiming();

    this.setState({
      loading: false
    });
  }

  async loadAbrufaufteilung() {
    await API.get(
      `API/auswertungskonfiguration/${this.state.uuid}/abrufaufteilung`
    )
      .then((a: any) => {
        this.setState({
          abrufaufteilung: a
        });
      })
      .catch(e => {
        Alert.error("Fehler beim Laden der Vertragstypen aufgetreten");
      });
  }

  async loadVertragstypenauswertung() {
    await API.get(
      `API/auswertungskonfiguration/${this.state.uuid}/vertragstypenauswertung`
    )
      .then((a: any) => {
        this.setState({
          vertragstypenauswertung: a
        });
      })
      .catch(e => {
        Alert.error("Fehler beim Laden der Vertragstypen aufgetreten");
      });
  }

  async loadStartseitenauswertung() {
    await API.get(`API/auswertungskonfiguration/startseitenauswertung`)
      .then((a: any) => {
        //set date
        let sd = new Date(a.zeitraum_von);
        let ed = new Date(a.zeitraum_bis);
        this.setState({
          startDate: sd,
          endDate: ed,
          uuid: a.id,
          startseitenauswertung: a
        });

        if (a.kunde) {
          this.setState({
            selectedKundeId: a.kunde.id
          });
        }
        if (a.partner) {
          this.setState({
            selectedPartnerId: a.partner.id
          });
        }
        return Promise.resolve();
      })
      .catch(e => {
        Alert.error("Fehler beim Laden der Daten aufgetreten");
        return Promise.reject();
      });
  }

  async patchStartseitenauswertung() {
    await API.patch(
      `API/auswertungskonfiguration/${this.state.uuid}/auswerten`,
      {}
    )
      .then((a: any) => {
        //set date
        let sd = new Date(a.zeitraum_von);
        let ed = new Date(a.zeitraum_bis);

        let kundenId;
        if (a.kunde) kundenId = a.kunde.id;
        else kundenId = "";
        let partnerId;
        if (a.partner) partnerId = a.partner.id;
        else partnerId = "";
        this.setState({
          selectedKundeId: kundenId,
          selectedPartnerId: partnerId,
          startDate: sd,
          endDate: ed,
          uuid: a.id,
          startseitenauswertung: a
        });

        return Promise.resolve();
      })
      .catch(e => {
        Alert.error("Fehler beim Laden der Daten aufgetreten");
        return Promise.reject();
      });
  }

  async loadMotivaufteilung() {
    await API.get(
      `API/auswertungskonfiguration/${this.state.uuid}/motivaufteilung`
    )
      .then((a: any) => {
        let motive = [] as any;
        {
          Object.keys(a).map(function(key) {
            motive.push(a[key]);
          });
        }

        this.setState({
          motivaufteilung: motive
        });
      })
      .catch(e => {
        Alert.error("Fehler beim Laden der Motive aufgetreten");
      });
  }

  loadTiming() {
    API.get(`API/auswertungskonfiguration/${this.state.uuid}/langeabrufdauer`)
      .then((a: any) => {
        this.setState({
          timingData: a
        });
      })
      .catch(e => {
        Alert.error("Fehler beim Laden der Timing-Daten aufgetreten");
      });
  }

  refresh(dataFromCheckbox: any, tab: string) {
    API.put(`API/auswertungskonfiguration/${this.state.uuid}`, {
      id: this.state.uuid,
      bezeichnung: "Auswertung Übersicht",
      zeitraum_von: dataFromCheckbox.startDate,
      zeitraum_bis: dataFromCheckbox.endDate,
      kunde: !dataFromCheckbox.selectedKundeId
        ? null
        : {
            id: dataFromCheckbox.selectedKundeId
          },
      partner: !dataFromCheckbox.selectedPartnerId
        ? null
        : {
            id: dataFromCheckbox.selectedPartnerId
          }
    })
      .then(s => {
        this.patchStartseitenauswertung()
          .then((a: any) => {
            this.load();
            this.setState({ activeTabKey: tab });
          })
          .catch(e => {});
      })
      .catch(error => {
        Alert.error("Fehler beim Update der Abfrage aufgetreten");
      });
  }

  renderMap() {
    API.get(`API/auswertungsdatensatz?auswertung=${this.state.uuid}`).then(
      (s: any) => {
        this.setState({
          positions: s
        });
      }
    );
  }

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <Layout title={"Reporting // Timing"}>
        <div className="tabs-container">
          <ul className="nav nav-tabs" role="tablist">
            <li>
              <Link className="nav-link" to={"/reporting"}>
                Übersicht
              </Link>
            </li>
            <li>
              <Link className="nav-link " to={"/reporting_analysis"}>
                W-Analysen
              </Link>
            </li>
            <li>
              <Link className="nav-link active" to={"/reporting_timing"}>
                Timing
              </Link>
            </li>
          </ul>
        </div>
        <div className="row mt-3">
          {/* <Box size={3} title="Filter"> */}
          <div className="col-lg-12">
            <div className="ibox bg-white" style={{ padding: 20 }}>
              <StatsFilter
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                selectedKundeId={this.state.selectedKundeId}
                selectedPartnerId={this.state.selectedPartnerId}
                startseitenauswertung={this.state.startseitenauswertung}
                onButtonClicked={(e: any) => this.refresh(e, "timing")}
              />
              {/* </Box> */}
            </div>
          </div>
        </div>
        <div className="row">
          <Box
            size={6}
            title="Dauer bis zur Kontaktaufnahme"
            tooltipMessage="Der grüne Balken zeigt, in welchem Zeitraum ein eingegangener Kontakt angerufen wurde.<br/>
Der rote Balken zeigt, in welchem Zeitraum die jeweils dazugehörige Beraterfassung heruntergeladen wurde.<br/>
Bitte beachten Sie: Da der rote Balken für die ergänzende Beraterfassung steht, <br/>ergibt das Addieren der roten und grünen Kennzahlen nicht die Gesamtzahl der Abrufe.<br/>
Hinweis: Ein Lead gilt bereits nach wenigen Stunden als „kalt“. <br/>Demnach sollte die Kontaktaufnahme binnen 24 Stunden abgeschlossen sein.<br/>
In den Blocks auf der rechten Seite sehen Sie den Anteil der zu lange <br/>unbearbeiteten Abrufe in absoluter Anzahl und als Anteil an der Gesamtsumme von Abrufen.<br/> Diese Zahlen sollten für ein erfolgreiches Arbeiten mit der Wohnmarktanalyse möglichst gegen 0 gehen."
          >
            {this.state.uuid && this.state.uuid !== "" && (
              <ImageWidget
                imageUrl={`${Config.getAPIURL()}/API/auswertungskonfiguration/${
                  this.state.uuid
                }/abrufdauer`}
                alt="Dauer bis zur Kontaktaufnahme"
              ></ImageWidget>
            )}
          </Box>
          <div className="col-lg-6">
            <Box size={12} title="Download WMA nach mehr als 7 Tagen">
              <TimingWidget
                timingData={this.state.timingData}
                type="lange_lv"
              ></TimingWidget>
            </Box>
            <Box size={12} title="Kontaktaufnahme später als 7 Tage">
              <TimingWidget
                timingData={this.state.timingData}
                type="lange_kontaktaufnahme"
              ></TimingWidget>
            </Box>
          </div>
        </div>
        {/* <div className="row">
          <Box
            size={6}
            title="Bearbeitungsdauer"
            tooltipMessage="Hier werden Ihnen die Durchschnittswerte der Bearbeitungsdauer eines Interessenten angezeigt.<br/>
In der Tabelle werden die durchschnittlichen Zeiten in Minimum (schnellste Reaktionszeit),<br/> Mittelwert und Maximum (langsamste Reaktionszeit) aufgeführt.<br/>
Die Mittelwerte dieser Statistiken werden in dem Schaubild grafisch dargestellt.<br/>
So können Sie ablesen, wie lange Ihr Team durchschnittlich bis zu einem ersten Anruf <br/>und später bis zur Vereinbarung eines Termins benötigt und wann sie den Kontakt<br/> für weitere Zwecke / Prozesse in ein CRM, an einen Kollegen oder eine andere Abteilung weiterleiten."
          >
            <BarChart uuid={this.state.uuid}></BarChart>
          </Box>
        </div> */}
      </Layout>
    );
  }
}
