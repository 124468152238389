import * as React from 'react';
import { Layout } from '../components/Layout';
import { Loading } from '../components/Loading';
import { Link } from 'react-router-dom';
import { Box } from '../components/Box';

import { API } from '../services/API';

import { MotivWidget } from '../components/widgets/motivWidget';
import { StatsFilter } from '../components/StatsFilter';

import { DoughnutChart } from '../components/DoughnutChart';
import { TrichterChart } from '../components/TrichterChart';

import { SimpleMap } from '../components/SimpleMap';
import { Alert } from '../services/alert';
import { AbrufeWidget } from '../components/widgets/abrufeWidget';
import Config from '../services/config';
import { ImageWidget } from '../components/widgets/imageWidget';

interface Props {
  match?: any;
}

interface State {
  loading: boolean;
  startDate: Date;
  endDate: Date;
  startseitenauswertung: Object;
  uuid: string;
  kunden: Array<any>;
  motivaufteilung: Array<any>;
  selectedKundeId: number | string;
  selectedPartnerId: number | string;
  activeTab: string;
  vertragstypenauswertung: Array<any>;
  abrufaufteilung: Object;
  positions: any;
  activeTabKey: string;
}

export class Reporting_Overview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      uuid: '',
      startDate: new Date(),
      endDate: new Date(),
      startseitenauswertung: {},
      motivaufteilung: [],
      kunden: [],
      selectedKundeId: '',
      selectedPartnerId: '',
      activeTab: 'Übersicht',
      vertragstypenauswertung: [],
      abrufaufteilung: {},
      positions: [],
      activeTabKey: 'overview',
    };

    this.refresh = this.refresh.bind(this);
    this.load = this.load.bind(this);
    this.handleChangeTabKey = this.handleChangeTabKey.bind(this);
    this.loadAbrufaufteilung = this.loadAbrufaufteilung.bind(this);
    this.loadVertragstypenauswertung = this.loadVertragstypenauswertung.bind(
      this,
    );
    this.loadMotivaufteilung = this.loadMotivaufteilung.bind(this);
    this.loadStartseitenauswertung = this.loadStartseitenauswertung.bind(this);
    this.handleChangeTabTitle = this.handleChangeTabTitle.bind(this);
  }

  componentDidMount() {
    //open menu
    // document.body.className = document.body.className.replace(
    //   'mini-navbar',
    //   '',
    // );
    //load initial data
    this.loadStartseitenauswertung().then(s => {
      //load data
      this.load();
    });
  }

  componentDidUpdate() {
    //this.load();
  }

  handleChangeTabTitle(event: any) {
    this.setState({
      activeTab: event,
    });
  }

  handleChangeTabKey(event: any) {
    this.setState({
      activeTabKey: event,
    });
  }

  async load() {
    this.setState({ loading: true });
    //load parts
    this.loadMotivaufteilung();
    this.loadVertragstypenauswertung();
    this.loadAbrufaufteilung();
    this.renderMap();
    this.setState({ loading: false });
  }

  async loadAbrufaufteilung() {
    await API.get(
      `API/auswertungskonfiguration/${this.state.uuid}/abrufaufteilung`,
    )
      .then((a: any) => {
        this.setState({
          abrufaufteilung: a,
        });
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Vertragstypen aufgetreten');
      });
  }

  async loadVertragstypenauswertung() {
    await API.get(
      `API/auswertungskonfiguration/${this.state.uuid}/vertragstypenauswertung`,
    )
      .then((a: any) => {
        this.setState({
          vertragstypenauswertung: a,
        });
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Vertragstypen aufgetreten');
      });
  }

  async loadStartseitenauswertung() {
    await API.get(`API/auswertungskonfiguration/startseitenauswertung`)
      .then((a: any) => {
        //set date
        let sd = new Date(a.zeitraum_von);
        let ed = new Date(a.zeitraum_bis);
        this.setState({
          startDate: sd,
          endDate: ed,
          uuid: a.id,
          startseitenauswertung: a,
        });
        if (a.kunde) {
          this.setState({
            selectedKundeId: a.kunde.id,
          });
        }
        if (a.partner) {
          this.setState({
            selectedPartnerId: a.partner.id,
          });
        }
        return Promise.resolve();
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Daten aufgetreten');
        return Promise.reject();
      });
  }

  async patchStartseitenauswertung() {
    await API.patch(
      `API/auswertungskonfiguration/${this.state.uuid}/auswerten`,
      {},
    )
      .then((a: any) => {
        //set date
        let sd = new Date(a.zeitraum_von);
        let ed = new Date(a.zeitraum_bis);

        let kundenId;
        if (a.kunde) kundenId = a.kunde.id;
        else kundenId = '';
        let partnerId;
        if (a.partner) partnerId = a.partner.id;
        else partnerId = '';
        this.setState({
          selectedKundeId: kundenId,
          selectedPartnerId: partnerId,
          startDate: sd,
          endDate: ed,
          uuid: a.id,
          startseitenauswertung: a,
        });

        return Promise.resolve();
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Daten aufgetreten');
        return Promise.reject();
      });
  }

  async loadMotivaufteilung() {
    await API.get(
      `API/auswertungskonfiguration/${this.state.uuid}/motivaufteilung`,
    )
      .then((a: any) => {
        let motive = [] as any;
        {
          Object.keys(a).map(function(key) {
            motive.push(a[key]);
          });
        }

        this.setState({
          motivaufteilung: motive,
        });
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Motive aufgetreten');
      });
  }

  refresh(dataFromCheckbox: any, tab: string) {
    API.put(`API/auswertungskonfiguration/${this.state.uuid}`, {
      id: this.state.uuid,
      bezeichnung: 'Auswertung Übersicht',
      zeitraum_von: dataFromCheckbox.startDate,
      zeitraum_bis: dataFromCheckbox.endDate,
      kunde: !dataFromCheckbox.selectedKundeId
        ? null
        : {
            id: dataFromCheckbox.selectedKundeId,
          },
      partner: !dataFromCheckbox.selectedPartnerId
        ? null
        : {
            id: dataFromCheckbox.selectedPartnerId,
          },
    })
      .then(s => {
        this.patchStartseitenauswertung()
          .then((a: any) => {
            this.load();
            this.setState({ activeTabKey: tab });
          })
          .catch(e => {});
      })
      .catch(error => {
        Alert.error('Fehler beim Update der Abfrage aufgetreten');
      });
  }

  renderMap() {
    API.get(`API/auswertungsdatensatz?auswertung=${this.state.uuid}`).then(
      (s: any) => {
        this.setState({
          positions: s,
        });
      },
    );
  }

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <Layout title={'Reporting // Übersicht'} uuid={this.state.uuid}>
        <div id="overviewSite">
          <div className="tabs-container">
            <ul className="nav nav-tabs" role="tablist">
              <li>
                <Link className="nav-link active" to={'/reporting'}>
                  Übersicht
                </Link>
              </li>
              <li id="wAnalysis">
                <Link className="nav-link " to={'/reporting_analysis'}>
                  W-Analysen
                </Link>
              </li>
              <li id="timing">
                <Link className="nav-link " to={'/reporting_timing'}>
                  Timing
                </Link>
              </li>
            </ul>
          </div>
          <div className="row mt-3">
            {/* <Box size={12} title="Filter"> */}
            <div className="col-lg-12">
              <div className="ibox bg-white" style={{ padding: 20 }}>
                <StatsFilter
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  selectedKundeId={this.state.selectedKundeId}
                  startseitenauswertung={this.state.startseitenauswertung}
                  selectedPartnerId={this.state.selectedPartnerId}
                  onButtonClicked={(e: any) => this.refresh(e, 'overview')}
                />
              </div>
            </div>
            {/* </Box> */}
          </div>
          <div className="row row1" id="overviewRow">
            <Box
              size={4}
              title="Abrufe nach Objekttyp"
              tooltipMessage="Dies ist die Gesamtanzahl aller abgerufenen<br/> Wohnmarktanalysen in Ihrem ausgewählten Zeitraum.<br/> Gegliedert werden die Analysen hier nach<br/> den jeweiligen Objekttypen (Mietwohnungen, <br/>Eigentumswohnungen, Häuser oder Mehrfamilienhäuser)."
            >
              <DoughnutChart dataType="overview" uuid={this.state.uuid} />
            </Box>
            <Box
              size={4}
              title="Abrufe nach Motiv"
              tooltipMessage="Warum Kunden die Wohnmarktanalyse bestellt haben, wird Ihnen in dieser Statistik aufgeführt.<br/> Das hilft ihnen dabei zu priorisieren, um welche Abrufe sie sich zu erst kümmern sollten.<br/>
Hierfür wird Ihnen sowohl die absolute Zahl der jeweiligen Nutzer aufgeführt (Spalte 1),<br/> sowie der prozentuale Anteil der Gesamtabrufer (Spalte 2).<br/><br/>
Unser Tipp:<br/>
Es kommt häufig vor, dass Nutzer sich noch nicht auf ein Motiv festlegen wollen<br/> und daher „Sonstiges“ oder „Nur bewerten“ auswählen. Diese Abfragen sollten Sie jedoch<br/> keineswegs ignorieren – hier versteckt sich großes Potential! Denn selbst wenn diese<br/> Nutzer sich mit Ihrer Entscheidung noch etwas Zeit lassen wollen, <br/>ob sie nun verkaufen, kaufen oder doch mieten wollen, können Sie bereits hier ansetzen und<br/> einen wertvollen Kontakt für die Zukunft herstellen."
            >
              <MotivWidget motivaufteilung={this.state.motivaufteilung} />
            </Box>
            <Box
              size={4}
              title="Sales Funnel"
              tooltipMessage="Der Sales Funnel bietet Ihnen eine gute Übersicht über den Bearbeitungsstand der Wohnmarktanalysen.<br/>
Zunächst wird das Verhältnis zwischen internen und externen Abrufen aufgezeigt, danach werden Ihnen die Ergebnisse der Telefonate grafisch veranschaulicht.<br/>
Was bedeuten die einzelnen Bereiche?<br/>
Interne Abrufe:<br/>
Wie viele Abrufe wurden von Ihnen und Ihren Kollegen eingegeben?<br/>
Externe Abrufe:<br/>
Wie viele Abrufe wurden über das Frontend-Tool auf ihrer Internetseite getätigt?<br/>
Nicht bearbeitet:<br/>
Eingehende Abrufe, die im Cockpit noch nicht bearbeitet wurden. Diese Anzahl sollte im Idealfall am Ende eines Arbeitstages gegen 0 gehen.<br/>
Angerufen:<br/>
Stufe 1 der Bearbeitung des Interessenten: Anruf.<br/>
Bei wie vielen eingegangenen Analysen wurde im Cockpit die Telefonnummer des Nutzers abgerufen?<br/>
Termin:<br/>
Stufe 2 der Bearbeitung des Interessenten: Termin.<br/>
Mit wie vielen Nutzern wurde ein konkreter Besichtigungstermin vereinbart?<br/>
Auftrag:<br/>
Stufe 3 der Bearbeitung des Interessenten: Auftrag.<br/>
Wie viele Abrufe wurden aus dem Cockpit exportiert, bzw. an einen Kollegen oder Ihr CRM übergeleitet? <br/>Unsere Annahme ist hierbei ein erfolgreicher Besichtigungstermin und damit ein angestoßener Vermarktungsprozess."
            >
              <TrichterChart uuid={this.state.uuid} dataType="overview" />
            </Box>
          </div>
          <div className="row">
            <Box
              size={3}
              title="Anzahl Abrufe"
              tooltipMessage="Die Gesamtanzahl aller Abrufe wird hier für den gewünschten Zeitraum zusammengerechnet<br/> und mit dem Durchschnitt anderer Banken verglichen (Benchmarking).<br/>
Den Zeitraum können Sie jederzeit in dem Filter am Kopf der Seite anpassen.<br/>
Verglichen werden Ihre Ergebnisse hier mit den durchschnittlich<br/> eingegangenen Abrufen anderer Bankinstitute im gewählten Zeitraum. <br/>Diese sind je nach Bilanzgröße in die iib ImmoBanking Kategorien (XS, S, M, L oder XL) eingeteilt. <br/>Welcher Kategorie Sie angehören, wird Ihnen durch die Markierung verdeutlicht."
            >
              <AbrufeWidget
                vertragstypenauswertung={this.state.vertragstypenauswertung}
                abrufaufteilung={this.state.abrufaufteilung}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            </Box>
            <div className="col-lg-5">
              <div className="row">
                <Box
                  size={12}
                  title="Abrufe nach Objekttyp im zeitlichen Verlauf"
                  tooltipMessage="Auf dieser Zeitachse werden die Gesamtabrufe (rot) sowie die einzelnen Objekttypen nach Häufigkeit der Abrufe angezeigt<br/> – wann wurde welcher Typ (Haus, Eigentumswohnung, Mietwohnung oder Mehrfamilienhaus) am häufigsten aufgerufen?"
                >
                  {this.state.uuid && this.state.uuid !== '' && (
                    <ImageWidget
                      imageUrl={`${Config.getAPIURL()}/API/auswertungskonfiguration/${
                        this.state.uuid
                      }/timeline?mode=1&cachebuster=${this.state.startDate}`}
                      alt="Abrufe nach Objekttyp im zeitlichen Verlauf"
                    ></ImageWidget>
                  )}
                </Box>
              </div>
              <div className="row">
                <Box
                  size={12}
                  title="Interne und externe Abrufe im zeitlichen Verlauf"
                  tooltipMessage="Interne Abrufe (gelb):<br/>
Wie viele Abrufe wurden von Ihnen und Ihren Kollegen eingegeben?<br/>
Externe Abrufe (rot):<br/>
Wie viele Abrufe wurden über das Frontend-Tool auf ihrer Internetseite getätigt?<br/><br/>
Unser Tipp:<br/>
Das ImmoBanking Konzept funktioniert besonders gut, wenn etwa die Hälfte Ihrer Abrufe <br/>intern und die andere Hälfte extern getätigt werden. Denn so ist gewährleistet, <br/>dass die Berater in Ihrer Bank das Tool auch aktiv im Arbeitsalltag einsetzen."
                >
                  {this.state.uuid && this.state.uuid !== '' && (
                    <ImageWidget
                      imageUrl={`${Config.getAPIURL()}/API/auswertungskonfiguration/${
                        this.state.uuid
                      }/timeline?mode=1&cachebuster=${this.state.startDate}`}
                      alt="Abrufe nach Objekttyp im zeitlichen Verlauf"
                    ></ImageWidget>
                  )}
                </Box>
              </div>
            </div>
            <Box
              size={4}
              title="Landkarte"
              tooltipMessage="Wo befinden Sich die Immobilien, die in dem ausgewählten Zeitraum analysiert wurden?<br/>
Dabei handelt es sich um die Adresse des Objekts, nicht um die Adresse des Nutzers/Interessenten."
            >
              <div className="ibox-map">
                <SimpleMap position={this.state.positions} />
              </div>
            </Box>
          </div>
        </div>
      </Layout>
    );
  }
}
