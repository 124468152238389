import * as React from 'react';
import { API } from '../../services/API';
import Auth from "../../services/auth";

interface State {
  loading: boolean;
  failed: boolean;
  reloadCount: number;
}

interface Props {
  imageUrl: string;
  alt: string;
}

export class ImageWidget extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.onError = this.onError.bind(this)
    this.onLoad = this.onLoad.bind(this)
    this.assembleUrl = this.assembleUrl.bind(this)

    this.state = {
      loading: true,
      failed: false,
      reloadCount: 0,
    };
  }

  onLoad() {
    this.setState({ loading: false });
  }

  onError() {
    this.setState({ loading: false, failed: true });
  }

  assembleUrl(){
    const glue = this.props.imageUrl.includes('?')?'&':'?';
    return `${this.props.imageUrl}${glue}jwtToken=${Auth.accessToken}`
  }

  render() {
    return (
      <div>
        <img
          src={this.assembleUrl()}
          className="d-block w-100"
          alt={this.props.alt}
          onLoad={this.onLoad}
          onError={this.onError}
        />
        {this.state.failed && !this.state.loading && (
          <p>Diagramm konnte nicht geladen werden.</p>
        )}
        {this.state.loading && <p>Lade Diagramm-Daten...</p>}
      </div>
    );
  }
}
