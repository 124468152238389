import * as React from "react";
import "../assets/css/bootstrap.min.css";
import "../assets/css/animate.css";
import "../assets/css/style.css";
import Auth from "../services/auth";
import { Link, Redirect, withRouter } from "react-router-dom";

import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn,
  faChartLine,
  faHome,
  faBullseye,
  faFileAlt,
  faBars,
  faSignOutAlt,
  faInfoCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faSquareFull,
  faCheckSquare,
  faPhone,
  faTimes,
  faDownload,
  faSort,
  faTrashAlt,
  faAddressCard,
  faStopwatch,
  faShieldAlt,
  faPaperPlane,
  faTasks,
  faPrint,
  faSpinner,
  faCircle,
  faExclamationCircle,
  faCheckCircle,
  faQuestion,
  faRoad,
  faGraduationCap,
  faStickyNote,
  faUser,
  faSync
} from "@fortawesome/free-solid-svg-icons";
import { Navigation } from "./Navigation";
import { Alert } from "../services/alert";

import { ScrollTo } from "react-scroll-to";

library.add(
  faBullhorn,
  faChartLine,
  faHome,
  faBullseye,
  faFileAlt,
  faBars,
  faSignOutAlt,
  faInfoCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faSquareFull,
  faCheckSquare,
  faPhone,
  faTimes,
  faDownload,
  faSort,
  faTrashAlt,
  faAddressCard,
  faStopwatch,
  faShieldAlt,
  faPaperPlane,
  faTasks,
  faPrint,
  faSpinner,
  faCircle,
  faExclamationCircle,
  faCheckCircle,
  faQuestion,
  faRoad,
  faGraduationCap,
  faStickyNote,
  faUser,
  faSync
);

interface Props {
  title?: string;
  location?: any;
  uuid?: string;
}

interface State {
  redirectToLogin: boolean;
  isTourOpen: boolean;
  mustChangePassword: boolean;
}

export class Layout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      isTourOpen: false,
      mustChangePassword: false
    };

    this.handleMenuToggle = this.handleMenuToggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.toggleTour = this.toggleTour.bind(this);
  }

  componentDidMount() {

  }

  disableBody = (target: any) => disableBodyScroll(target);
  enableBody = (target: any) => enableBodyScroll(target);

  componentDidCatch(error: Error | null, info: object) {
    if (error && error.message === "Access denied")
      this.setState({ redirectToLogin: true });
    Alert.error(
      "Error",
      "Please contact IIB or try again later"
    );
  }

  handleMenuToggle() {
    const cls = "mini-navbar";

    if (document.body.className.indexOf(cls) > -1) {
      document.body.className = document.body.className.replace(cls, "");
    } else {
      document.body.className = document.body.className + " mini-navbar";
    }
  }

  handleLogout() {
    Auth.logout();
    this.setState({
      redirectToLogin: true
    });
  }


  toggleTour() {
    this.setState(prevState => ({
      isTourOpen: !prevState.isTourOpen
    }));
  }

  render() {
    const steps = [
      {
        selector: "#overviewSite",
        content:
          "Willkommen auf der Startseite des ErfolgsCockpits. Hier finden Sie alle wichtigen Daten und Auswertungen auf einen Blick."
      },
      {
        selector: "#toggleNav",
        content:
          "Mit dem Klick auf dieses Icon öffnet oder schließt sich die Navigationsleiste."
      },
      {
        selector: "#statsFilter",
        content:
          "Legen Sie hier den Zeitraum fest, den Sie auswerten möchten. Hierzu können Sie unter ‚Zeitraum‘ eine voreingestellte Zeitspanne (1 Woche, 1 Monat,...) auswählen, oder in den Eingabefeldern ‚Von‘ ‚Bis‘ einen individuellen Zeitraum eingeben. Die Änderung hat Auswirkungen auf alle Grafiken dieser Seite."
      },
      {
        selector: "#overviewRow",

        content:
          "Diese Grafiken und Schaubilder geben Ihnen detaillierte Informationen über die Abrufe in dem von Ihnen gesetzten Zeitraum. Genauere Informationen zu den einzelnen Schaubildern erhalten Sie, wenn Sie über das (I-Symbol) fahren."
      },
      {
        selector: "#wAnalysis",
        content:
          "Hier beantworten weitere Statistiken die „W-Fragen“: Wann, Was, Wie, Wer, Wo. Nähere Informationen finden Sie auch hier über den i-Button."
      },
      {
        selector: "#timing",
        content:
          "Unter diesem Reiter finden Sie Analysen über Ihre Abarbeitungszeiten – wie lange benötigen Sie und Ihre Kollegen, bis ein Kunde angerufen wird? Und wie viele Langfassungen wurden hierzu heruntergeladen?"
      },
      {
        selector: "#wohnmarktanalysen",
        content:
          "Hier finden Sie die gesamte Abruferliste des von Ihnen gefilterten Zeitraums. Sie können diese Liste neu filtern, via Excel exportieren, oder direkt in der Liste arbeiten. Über das „>“-Icon erhalten Sie mehr Informationen zu dem Kunden. Sie können jedoch auch direkt auf „Telefon Icon“ die Telefonnummer ermitteln und den Kunden anrufen. Zusätzlich bekommen Sie die wichtigsten Informationen über den Abrufer. Nach dem Telefonat können Sie Notizen zu dem Gespräch eintragen, den Kunden an das CRM übergeben oder den Status der Wohnmarktanalyse ändern und vieles mehr..."
      },
      {
        selector: "#kampagnenfilter",
        content:
          "Im Kampagnenfilter können Sie nach verschiedenen Sanierungsgraden filtern und sich beispielsweise nur jene Objekte anzeigen lassen, die sanierungsbedürftig sind – eine tolle Möglichkeit, den Kunden einen Finanzierungsplan zur Sanierung seiner Immobilie vorzuschlagen."
      },
      {
        selector: "#onboarding",
        content:
          "Die wichtigsten Aufgaben für einen erfolgreichen Start mit der Wohnmarktanalyse sind hier aufgelistet. Diese können nach Zuständigkeitsbereich gefiltert werden. So haben Sie den gesamten Onboarding Prozess immer im Blick."
      }
    ];

    if (this.state.redirectToLogin) return <Redirect to="/" />;

    return (
      <div id="wrapper">
        <Tour
          steps={steps}
          isOpen={this.state.isTourOpen}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          onRequestClose={this.toggleTour}
        />
        <nav className="navbar-default navbar-static-side" role="navigation">
          <div className="sidebar-collapse">
            <Navigation />
            <div
              style={{
                position: "fixed",
                bottom: 20,
                left: 20,
                width: "100%"
              }}
            >
              <ScrollTo>
                {({ scrollTo }) => (
                  <a
                    onClick={() =>
                      scrollTo({
                        x: 0,
                        y: 0,
                        smooth: true
                      })
                    }
                  >
                    <FontAwesomeIcon
                      icon={"chevron-up"}
                      size={"2x"}
                      style={{ color: "#fff" }}
                    ></FontAwesomeIcon>
                  </a>
                )}
              </ScrollTo>
            </div>
          </div>
        </nav>

        <div id="page-wrapper" className="gray-bg">
          <div className="row border-bottom">
            <nav
              className="navbar navbar-static-top white-bg"
              role="navigation"
              style={{ marginBottom: 0 }}
            >
              <div className="navbar-header">
                <span
                  id="toggleNav"
                  className="navbar-minimalize minimalize-styl-2 btn btn-primary"
                  onClick={this.handleMenuToggle}
                >
                  <FontAwesomeIcon icon="bars" />
                </span>
              </div>
              <h1>{this.props.title ? this.props.title : ""}</h1>
              <ul className="nav navbar-top-links navbar-right">
                <li>
                  <a
                    // className="m-r-sm text-muted welcome-message"
                    href="http://portal.iib-institut.de"
                    target="_blank" rel="noreferrer"
                  >
                    <FontAwesomeIcon icon="user" /> {Auth.getUsername()}
                  </a>
                </li>
                {window.location.pathname.search("reporting") !== -1 &&
                  window.location.pathname.search("reporting_") === -1 && (
                    <React.Fragment>
                      {this.props.uuid && false && Auth.getToken() && (
                        <li>
                          <a
                            target="_blank"
                            href={`https://forms.iib-it.de/T3API/pdf/download/${
                              this.props.uuid
                            }?token=${Auth.getToken()}`} rel="noreferrer"
                          >
                            <FontAwesomeIcon icon="print" /> Report drucken
                          </a>
                        </li>
                      )}
                      <li>
                        <a onClick={this.toggleTour}>
                          <FontAwesomeIcon icon="road" /> Tour starten
                        </a>
                      </li>
                    </React.Fragment>
                  )}
                <li>
                  <a onClick={this.handleLogout}>
                    <FontAwesomeIcon icon="sign-out-alt" /> Abmelden
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="wrapper wrapper-content animated fadeInRight">
            {this.props.children}
          </div>
        </div>

      </div>
    );
  }
}
