import SweetAlert from 'sweetalert2';

export class Alert {
  static error(msg: string, text?: string) {
    SweetAlert.fire({
      type: 'error',
      title: msg,
      text: text || '',
    });
  }

  static success(msg: string, text?: string) {
    SweetAlert.fire({
      type: 'success',
      title: msg,
      text: text || '',
    });
  }

  static warning(msg: string, text?: string) {
    SweetAlert.fire({
      type: 'warning',
      title: msg,
      text: text || '',
    });
  }

  static async confirm(
    msg: string,
    text: string,
    confirmButtonText: string,
    cancelButtonText: string,
  ) {
    const result = await SweetAlert.fire({
      type: 'warning',
      title: msg,
      text: text,
      showCloseButton: true,
      showCancelButton: true,
    });
    return result.value;
  }
}
