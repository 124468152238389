import * as React from 'react';
import { Layout } from '../components/Layout';
import { Loading } from '../components/Loading';
import { Box } from '../components/Box';
import { TableFilter } from '../components/TableFilter';

import { API } from '../services/API';

import { Alert } from '../services/alert';
import { CollapsibleAnalysenTable } from '../components/CollapsibleAnalysenTable';

interface Props {
  match?: any;
}

interface State {
  loading: boolean;
  uuid: string;
  refreshHelper: number;
  auswertungskonfiguration: any;
}

export class Wohnmarktanalysen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      uuid: '',
      refreshHelper: 0,
      auswertungskonfiguration: [],
    };

    this.refresh = this.refresh.bind(this);
    this.loadListenfilter = this.loadListenfilter.bind(this);
  }

  componentDidMount() {
    //toggle menu
    document.body.className = document.body.className + ' mini-navbar';
    //load initial data
    this.loadListenfilter().then(s => {
      //load data
      this.setState({
        loading: false,
      });
    });
  }

  async loadListenfilter() {
    await API.get(`API/auswertungskonfiguration/listenfilter`)
      .then((a: any) => {
        this.setState({
          auswertungskonfiguration: a,
          uuid: a.id,
        });

        return Promise.resolve();
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Daten aufgetreten');
        return Promise.reject();
      });
  }

  refresh() {
    this.setState((prevState: any) => ({
      refreshHelper: prevState.refreshHelper + 1,
    }));
  }

  render() {
    if (this.state.loading) return <Loading />;

    return (
      <Layout title="Wohnmarktanalysen">
        <div className="row">
          <Box size={12} title="Filter" toggle initalShow={false}>
            <TableFilter
              auswertungskonfiguration={this.state.auswertungskonfiguration}
              uuid={this.state.uuid}
              onRefresh={this.refresh}
            />
          </Box>
        </div>
        <div className="row">
          <div className="col-12 ">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Einzelabrufe</h5>
              </div>
              <div className="ibox-content">
                <CollapsibleAnalysenTable
                  auswertungskonfiguration={this.state.auswertungskonfiguration}
                  uuid={this.state.uuid}
                  refreshHelper={this.state.refreshHelper}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
