import * as React from 'react';
import { API } from '../../services/API';

interface State {
  loading: boolean;
}

interface Props {
  timingData: any;
  type: string;
}

export class TimingWidget extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.timingData !== prevProps.timingData)
      this.setState({
        loading: false,
      });
  }

  render() {
    return !this.state.loading ? (
      <div>
        <div className="row d-flex align-items-end">
          <div className="col-lg-8" style={{ alignContent: 'baseline' }}>
            <p style={{ fontSize: '35px' }}>
              {this.props.timingData[0] &&
              this.props.timingData[0][this.props.type]
                ? this.props.timingData[0][this.props.type]
                : 0}
            </p>
          </div>
          <div className="col-lg-4">
            <p className="float-right" style={{ fontSize: '25px' }}>
              {this.props.timingData[0] &&
                Math.round(
                  this.props.timingData[0][this.props.type + '_prozent']
                    ? this.props.timingData[0][this.props.type + '_prozent']
                    : 0,
                ) + '%'}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <p>
              {this.props.type === 'lange_kontaktaufnahme' &&
                'Kontakte wurden nie oder erst nach über einer Woche kontaktiert.'}
              {this.props.type === 'lange_lv' &&
                'Wohnmarktanalysen wurden erst nach über einer Woche oder nie abgerufen.'}
            </p>
          </div>
          <div className="col-lg-4">
            <p className="float-right">% aller Abrufe</p>
          </div>
        </div>
      </div>
    ) : (
      <p>Lade Diagramm-Daten...</p>
    );
  }
}
