import { formatRelative } from "date-fns";

export default class Config {
  /* USED */
  static getAPIURL(): string {
    // return "";
    return "https://api.geo-real.it";
  }

  /*USED */
  static getChartColors() {
    return [
      "#F79646",
      "#9B0F10",
      "#64BD63",
      "#4F81BD",
      "#FFD44B",
      //custom colors
      "#E8743B",
      "#A34534",
      "#58508d",
      "#945ECF",
      "#13A4B4",
      "#6C8893",
      "#2F6497",
      "#a1dbb1",
      "#ED4A7B"
    ];
  }

  /* USED */
  static getOfferVariants(): {
    type: number;
    label: string;
  }[] {
    return [
      {
        type: 3,
        label: "Häuser"
      },
      {
        type: 4,
        label: "Mehrfamilienhäuser"
      },
      {
        type: 2,
        label: "Eigentumswohnungen"
      },
      {
        type: 1,
        label: "Mietwohnungen"
      }
    ];
  }

  /* USED */
  static getOfferVariantLabelByType(type: number) {
    const offerVariant = Config.getOfferVariants().filter(
      (variant: any) => variant.type === type
    );
    if (offerVariant.length === 0) return null;
    return offerVariant[0].label;
  }

  /* USED */
  static getGoals(): {
    id: number;
    label: string;
  }[] {
    return [
      {
        id: 0,
        label: "Verkaufen"
      },
      {
        id: 1,
        label: "Kaufen"
      },
      {
        id: 2,
        label: "Wohnung vermieten"
      },
      {
        id: 3,
        label: "Wohnung mieten"
      },
      {
        id: 4,
        label: "Finanzieren"
      },
      {
        id: 5,
        label: "Bausparen"
      },
      {
        id: 6,
        label: "Sonstiges"
      },
      {
        id: 7,
        label: "Nur bewerten"
      },
      {
        id: 8,
        label: "Erben/Vererben"
      },
      {
        id: 9,
        label: "Versichern"
      }
    ];
  }
  /* USED */
  static getGoalLabel(id: number) {
    const goalLabel = Config.getGoals().filter((goal: any) => goal.id === id);
    if (goalLabel.length === 0) return null;
    return goalLabel[0].label;
  }
  /* USED */
  static getGoalLabelsById(selectedGoals: Array<number>) {
    let goals = selectedGoals;
    let labels = [];
    for (let index = 0; index < goals.length; index++) {
      labels.push(this.getGoalLabel(goals[index]));
    }
    return labels as Array<string>;
  }
  /* USED */
  static getGoalId(label: string) {
    const goalId = Config.getGoals().filter(
      (goal: any) => goal.label === label
    );
    if (goalId.length === 0) return null;
    return goalId[0].id;
  }
  /* USED */
  static getGoalLabels() {
    let goals = this.getGoals();
    let labels = [];
    for (let index = 0; index < goals.length; index++) {
      labels.push(goals[index].label);
    }
    return labels;
  }

  /* USED */
  static getGoalIds(selectedGoals: Array<string>) {
    let goals = selectedGoals;
    let ids = [];
    for (let index = 0; index < goals.length; index++) {
      ids.push(this.getGoalId(goals[index]));
    }
    return ids as Array<number>;
  }

  static getOfferTypes(): {
    id: number;
    short: string;
    label: string;
  }[] {
    return [
      {
        id: 30,
        short: "hk",
        label: "Haus Kauf"
      },
      {
        id: 31,
        short: "hm",
        label: "Haus Miete"
      },
      {
        id: 32,
        short: "wk",
        label: "Wohnung Kauf"
      },
      {
        id: 33,
        short: "wm",
        label: "Wohnung Miete"
      }
    ];
  }

  static getOfferTypeLabel(id: number) {
    const offerType = Config.getOfferTypes().filter(
      (type: any) => type.id == id
    );
    if (offerType.length === 0) return null;
    return offerType[0].label;
  }

  static getProducts(): {
    id: number;
    short: string;
    name: string;
  }[] {
    return [
      { id: 1, short: "QC", name: "Quickcheck" },
      { id: 2, short: "PS", name: "Preisstudie" },
      {
        id: 3,
        short: "EPS",
        name: "Erweiterte Preisstudie"
      },
      {
        id: 4,
        short: "WMA",
        name: "Wohnmarktauswertung"
      },
      {
        id: 5,
        short: "WMMA",
        name: "Wohn- und Mietmarktauswertung"
      },
      {
        id: 6,
        short: "MMA",
        name: "Mietmarktauswertung"
      },
      { id: 7, short: "GEW", name: "Gewerbe" },
      {
        id: 8,
        short: "MMA",
        name: "+GEW Mietmarktauswertung + Gewerbe"
      },
      {
        id: 9,
        short: "WMA",
        name: "+GEW Wohn- und Mietmarktauswertung + Gewerbe"
      },
      {
        id: 12,
        short: "ADHOC",
        name: "Sonderauswertung"
      }
    ];
  }

  static getAgeTypes() {
    return [
      { id: 0, label: "Bestand" },
      { id: 1, label: "Neubau" },
      { id: 2, label: "BJK" }
    ];
  }

  static getKgsLevels() {
    return [
      { id: 8, label: "Gemeinde" },
      { id: 11, label: "Stadtbezirk" },
      { id: 14, label: "Stadtteil" }
    ];
  }

  static getAgeTypeLabel(ageTypeId: number) {
    const ageType = Config.getAgeTypes().filter(
      (type: any) => type.id == ageTypeId
    );
    if (ageType.length === 0) return null;
    return ageType[0].label;
  }

  static getClients(): { name: string }[] {
    return [
      {
        name: "6B47 Real Estate Investors Germany GmbH"
      },
      { name: "Aachener Grundvermögen" },
      { name: "Ammon Beschläge GmbH" },
      { name: "Ampega Invest GmbH" },
      { name: "A-RT Rheinpark GmbH & Co. KG" },
      { name: "Aspa Bauträger" },
      { name: "Aurelis" },
      {
        name: "Aurelis Real Estate GmbH & Co. KG"
      },
      { name: "AXA" },
      { name: "B&L-Gruppe" },
      { name: "Baustolz GmbH" },
      {
        name: "BAUWENS Development GmbH & Co. KG"
      },
      { name: "Bayerische Versorgungskammer" },
      { name: "Baywobau Baubetreuung GmbH" },
      { name: "Becken Asset Management GmbH" },
      { name: "Behrendt Gruppe GmbH & Co KG" },
      { name: "Berger Liegenschaften GmbH" },
      { name: "Berger Objekt GmbH" },
      { name: "Bonava Deutschland GmbH" },
      { name: "BPD Immobilienentwicklung GmbH" },
      { name: "Büschl" },
      { name: "BUWOG Group" },
      { name: "Cayros" },
      { name: "CD Deutsche Eigenheim" },
      {
        name: "Centralaufnahmeeinrichtungen Betriebs GmbH"
      },
      { name: "CommerzReal" },
      { name: "Consult" },
      { name: "CORESTATE Capital Advisors GmbH" },
      { name: "CORESTATE Capital Development" },
      { name: "Corpus Sireo Aurum GmbH" },
      {
        name: "CORPUS SIREO Projektentwicklung Wohnen GmbH"
      },
      { name: "CorpusSireo" },
      {
        name: "CREAREAL Projektgesellschaft Garching mbH"
      },
      { name: "Cubatur7 GmbH" },
      { name: "DeepGreen" },
      { name: "DERECO Asset Management GmbH" },
      { name: "Deutsche Asset One GmbH" },
      { name: "Deutsche Eigenheim" },
      { name: "Development Partner AG" },
      { name: "Die Mehrwertbauer GmbH" },
      { name: "Die Wohnkompanie NRW GmbH" },
      {
        name: "Diringer & Scheidel GmbH & Co. Beteiligungs KG"
      },
      { name: "DORNIEDEN Generalbau GmbH" },
      {
        name: "Dream Global Advisors Germany GmbH"
      },
      {
        name: "DREAM GLOBAL ADVISORS GERMANY GMBH"
      },
      { name: "Dresdner Wohnbau GmbH" },
      { name: "Emag GmbH" },
      { name: "Empira AG" },
      { name: "Epple Projekt GmbH" },
      {
        name: "EPS Wüstenrot Haus- und Städtebau GmbH"
      },
      { name: "Ev. Stiftung Pflege Schönau" },
      {
        name: "EYEMAXX International Holding & Consulting GmbH"
      },
      { name: "Fairhome" },
      { name: "FirstRetail" },
      { name: "GERCH Development GmbH" },
      { name: "GeRo Real Estate AG" },
      { name: "Gongoll e.K." },
      { name: "GWB Reutllingen" },
      {
        name: "GWH-Wohnungsbaugesellschaft mbH Hessen"
      },
      { name: "Hamacher Immobilien" },
      { name: "HanseMerkur Grundvermögen AG" },
      { name: "Hartmann-Thoma plan werk GmbH" },
      { name: "HAT Wohnen Gmbh & Co. KG" },
      { name: "HGG  Dritte Projektgesellschaft" },
      { name: "HIB" },
      { name: "HIH Projektentwicklung GmbH" },
      { name: "Hines Immobilien GmbH" },
      {
        name: "HOLDING GMBH & CO.KG - BÜSCHL UNTERNEHMENSGRUPPE"
      },
      {
        name: "HOWOGE Wohnungsbaugesellschaft mbH"
      },
      { name: "iib Consult" },
      { name: "iib Dr. Hettenbach Institut" },
      { name: "INDUSTRIA Bauen und Wohnen GmbH" },
      {
        name: "Instone Real Estate Development GmbH"
      },
      {
        name: "Interboden Innovative Lebenswelten GmbH & Co. KG"
      },
      { name: "Invesco Real Estate GmbH" },
      { name: "Investa Development GmbH" },
      {
        name: "Investa RuBu1 / RuBu2 GmbH & Co. KG"
      },
      { name: "IrishöfeGmbH" },
      { name: "Isaria Wohnbau AG" },
      { name: "J. Molitor Immobilien GmbH" },
      { name: "Jones Lang LaSalle SE" },
      {
        name: "Justus Grosse Projektentwicklung GmbH"
      },
      { name: "Kanada Bau" },
      { name: "KAURI CAB Development" },
      { name: "KGAL GmbH & Co. KG" },
      {
        name: "kueppersliving Immobilienentwicklung GmbH & Co. KG"
      },
      { name: "Landmarken AG" },
      { name: "LandmarkenAG" },
      { name: "Lang & Cie" },
      { name: "Lars Bunte" },
      {
        name: "LBBW Immobilien Development GmbH"
      },
      {
        name: "LBBW Immobilien Kommunalentwicklung GmbH"
      },
      { name: "LEG NRW GmbH" },
      { name: "LEG Solution GmbH" },
      { name: "Leopold Stiefel Gruppe" },
      {
        name: "Lidl Dienstleistung GmbH & Co. KG"
      },
      {
        name: "Lorac Investment Management S.à r.l."
      },
      { name: "Mainzer Aufbaugesellschaft mbH" },
      { name: "Matrix Immobilien GmbH" },
      {
        name: "Mattheuser Immobilienvertriebs GmbH"
      },
      { name: "Max&Moritz Mikare" },
      { name: "Maximus Immobilien GmbH" },
      { name: "MLP Finanzdienstleistungs AG" },
      { name: "More Management S.à.rl" },
      { name: "MPP Meding Plan" },
      { name: "MUCRealEstate" },
      { name: "Mutscheller" },
      { name: "MyslikBau GmbH" },
      { name: "NAS Invest GmbH" },
      { name: "NOS NP2 GmbH" },
      { name: "Oertzen Projektentwicklung GmbH" },
      { name: "OFB Projektentwicklung GmbH" },
      { name: "OPES Immobilien GmbH" },
      { name: "Optimal Wohnbau" },
      { name: "PATRIZIA Deutschland GmbH" },
      {
        name: "Penta Consult Verwaltungsgesellschaft mbH"
      },
      { name: "Pfleiderer" },
      { name: "PlanetHome Group GmbH" },
      { name: "Platin 1525. GmbH" },
      {
        name: "PLB Provinzial-Leben-Baubetreuungs-GmbH"
      },
      {
        name: "Pöttinger Wohn- und Industriebau GmbH & Co. KG"
      },
      { name: "PRIME site development GmbH" },
      { name: "Primus Development GmbH" },
      { name: "Pro Concept Holding AG" },
      { name: "Project Bisping gmbh" },
      {
        name: "Prologis Germany Management GmbH"
      },
      {
        name: "Provinzial NordWest Asset Management GmbH"
      },
      { name: "Qosy GmbH" },
      {
        name: "Quadriga Projektentwicklung GmbH & Co. KG"
      },
      { name: "RCM Baumanagement" },
      { name: "RegioWert GmbH" },
      { name: "Reisch" },
      { name: "REVITALIS Real Estate AG" },
      {
        name: "SachsenInvest Real Estate GmbH & Co. KG"
      },
      { name: "SAGA Aktiengesellschaft" },
      { name: "SBT Immobilien Gruppe" },
      {
        name: "Schlossallee Bickenbach GmbH & Co.KG"
      },
      { name: "Schmeing Management GmbH" },
      {
        name: "Schürrer & Fleischer Immobilien GmbH"
      },
      { name: "SG-Development" },
      { name: "SLS Projektentwicklung GmbH" },
      { name: "solidare Service GmbH" },
      { name: "Sontowski+Partner" },
      { name: "Sparda Immobilien GmbH" },
      { name: "SSN Development GmbH & Co KG" },
      { name: "Stadt Dieburg" },
      { name: "Stadt Rheinstetten" },
      { name: "Stadtverwaltung Kehl" },
      { name: "STRABAG Real Estate GmbH" },
      { name: "Strenger Bauen & Wohnen" },
      {
        name: "SüdWERT Wohnprivatisierungsgesellschaft mbH"
      },
      { name: "SV Lebensversicherung" },
      { name: "SW Invest Finance Trade GmbH" },
      { name: "TalanxImmobilien" },
      { name: "TICOi.BuildGmbH&Co.KG" },
      {
        name: "Troll TR Rechtsanwalt (Insolvenzverwalter)"
      },
      {
        name: "Versorgungswerk der Zahnärztekammer Nordrhein K.d.ö.R."
      },
      { name: "Vilis" },
      { name: "Vista GmbH" },
      {
        name: "Vivum / Zweite ANIMA-Oldenburg Projektgesellschaft"
      },
      { name: "Vivum GmbH" },
      { name: "Volksbank Kraichgau" },
      { name: "Volksbank Ulm-Biberach" },
      { name: "WCB Weber-Consulting Beratung" },
      { name: "Weisenburger Bau GmbH" },
      { name: "Weisenburger Immobilien GmbH" },
      { name: "Weisenburger Projekt GmbH" },
      {
        name: "Wirtschaftsförderung Solingen GmbH & Co. KG"
      },
      { name: "WOGE-Saar" },
      { name: "WURTZ bauen+wohnen gmbh" },
      {
        name: "Wüstenrot Haus- und Städtebau GmbH"
      },
      { name: "WvM Vertriebsagentur GmbH" }
    ];
  }
}
