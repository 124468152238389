import * as React from "react";
import { Layout } from "../components/Layout";
import { Loading } from "../components/Loading";

interface Props {
  match?: any;
}

interface State {
  loading: boolean;
}

export class Schulung extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  render() {
    if (this.state.loading) return <Loading />;

    return (
      <Layout title="Schulung">
        <div className="row mt-3">
          <div className="col-lg-6">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Das ErfolgsCockpit</h5>
              </div>
              <div className="ibox-content">
                <div style={{ height: "315px" }}>
                  <iframe
                    src="https://www.youtube.com/embed/ROhnHZb82ko"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <p className="mt-2 font-weight-bold">
                  Dieses Video gibt Ihnen einen Gesamtüberblick über das
                  ErfolgsCockpit. Aus welchen Modulen setzt sich das Cockpit
                  zusammen und wie kann man diese nutzen? Dieses
                  Einführungsvideo zeigt Ihnen alles, was Sie für den Einstieg
                  wissen müssen.
                </p>
                <p>
                  Den Leitfaden zum Nachschlagen können Sie hier herunterladen:{" "}
                  <a
                    // href="./static/media/Leitfaden_ErfolgsCockpit.pdf"
                    // href="/media/Leitfaden_ErfolgsCockpit.pdf"
                    href={require("../assets/media/Leitfaden_ErfolgsCockpit.pdf")}
                    target="_blank"
                  >
                    Leitfaden (PDF)
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Die Wohnmarktanalyse | Teil 1: Kundenversion</h5>
              </div>
              <div className="ibox-content">
                <div style={{ height: "315px" }}>
                  <iframe
                    src="https://www.youtube.com/embed/mpQ7GusTlYk"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <p className="mt-2 font-weight-bold">
                  Wie ist die Kundenversion der Wohnmarktanalyse aufgebaut?
                  Zusammensetzung und Erläuterungen zu den einzelnen Seiten
                  finden Sie in diesem Kurzvideo.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Die Wohnmarktanalyse | Teil 2: Beraterfassung</h5>
              </div>
              <div className="ibox-content">
                <div style={{ height: "315px" }}>
                  <iframe
                    src="https://www.youtube.com/embed/vOMZuJSGLdY"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <p className="mt-2 font-weight-bold">
                  Wie baut die Beraterfassung der Wohnmarktanalyse auf die
                  Kundenfassung auf und was ist ihr Mehrwert?
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Der Telefonleitfaden</h5>
              </div>
              <div className="ibox-content">
                <div style={{ height: "315px" }}>
                  <iframe
                    src="https://www.youtube.com/embed/tqqqdsyumak"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <p className="mt-2 font-weight-bold">
                  Um ein Lead in einen Kunden zu verwandeln, bedarf es einer
                  überzeugenden Gesprächsführung beim ersten Telefonat. Dieser
                  Leitfaden zeigt anhand eines Beispielanrufs, worauf Sie achten
                  sollten.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
