import * as React from 'react';

import { CustomCheckbox } from '../components/CustomCheckbox';
import AsyncSelect from 'react-select/async';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Config from '../services/config';
import { API } from '../services/API';
import { Alert } from '../services/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button } from 'react-bootstrap';
import Auth from "../services/auth";

interface State {
  auswertungskonfiguration: any;
  startDate: Date | null;
  endDate: Date | null;
  kunden: Array<any>;
  partner: Array<any>;
  selectedKundeId: number | string;
  selectedPartnerId: number | string;
  selectedGoals: Array<any>;
  selectedObjectType: number | string;
  bezeichnung: string;
  zeitraum: string;
  bestand: string;
  datenschutz: boolean;
  persExportieren: boolean;
  selectedNeubau: any;
  selectedInternalExternal: any;
  neubauData: any;
  internalExternalData: any;
  loading: boolean;
  benutzerfreigabe: boolean;
  showPartners: boolean;
  showExcelModal: boolean;
}

interface Props {
  uuid: string;
  onRefresh?: any;
  kampagnenFilter?: any;
  auswertungskonfiguration: any;
}

const checkboxValues = Config.getGoalLabels();
const objectTypes = Config.getOfferVariants();

export class TableFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      showPartners: false,
      auswertungskonfiguration: [],
      benutzerfreigabe: false,
      startDate: null,
      endDate: null,
      kunden: [],
      partner: [],
      selectedKundeId: '',
      selectedPartnerId: '',
      selectedGoals: [],
      selectedObjectType: '',
      bezeichnung: '',
      zeitraum: '',
      bestand: '',
      datenschutz: false,
      persExportieren: false,
      showExcelModal: false,
      selectedNeubau: '',
      selectedInternalExternal: '',
      neubauData: {},
      internalExternalData: {},
    };

    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleShowExcelModal = this.handleShowExcelModal.bind(this);
    this.getShowPartners = this.getShowPartners.bind(this);
    this.handleChangeBenutzerfreigabe = this.handleChangeBenutzerfreigabe.bind(
      this,
    );
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeKunde = this.handleChangeKunde.bind(this);
    this.handleChangePartner = this.handleChangePartner.bind(this);
    this.handleChangeObjectType = this.handleChangeObjectType.bind(this);
    this.refresh = this.refresh.bind(this);
    this.loadKunden = this.loadKunden.bind(this);
    this.loadPartner = this.loadPartner.bind(this);
    this.handleChangeBezeichnung = this.handleChangeBezeichnung.bind(this);
    this.handleChangePersExportieren = this.handleChangePersExportieren.bind(
      this,
    );
    this.preselectDate = this.preselectDate.bind(this);
    this.handleChangeZeitraum = this.handleChangeZeitraum.bind(this);
    this.handleChangeDatenschutz = this.handleChangeDatenschutz.bind(this);
    this.handleChangeNeubau = this.handleChangeNeubau.bind(this);
    this.handleChangeInternalExternal = this.handleChangeInternalExternal.bind(
      this,
    );
    this.saveInfosFromAPI = this.saveInfosFromAPI.bind(this);
    this.getInternalExternalFilter = this.getInternalExternalFilter.bind(this);
  }

  componentDidMount() {
    //this.getFilter();
    if (this.props.auswertungskonfiguration) {
      this.saveInfosFromAPI(this.props.auswertungskonfiguration);
    }
    if (!this.props.auswertungskonfiguration) {
      this.getFilter();
    }
    this.getShowPartners();
    this.getObjecttypFilter();
    this.getInternalExternalFilter();
    this.loadKunden();

    this.loadPartner();
    this.setState({ loading: false });
  }

  // componentDidUpdate(oldProps: any) {
  //   const newProps = this.props;
  //   if (oldProps.kampagnenFilter !== newProps.kampagnenFilter) {
  //     this.getData();
  //   }
  // }

  handleShowExcelModal() {
    this.setState(prevState => ({
      showExcelModal: !prevState.showExcelModal,
    }));
  }

  isValidDate(d: any) {
    if (Object.prototype.toString.call(d) === '[object Date]') {
      // it is a date
      if (isNaN(d.getTime())) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  saveInfosFromAPI(a: any) {
    this.setState({
      auswertungskonfiguration: a,
      bezeichnung: a.bezeichnung,
      selectedKundeId: a.kunde && a.kunde.id && a.kunde.id,
      selectedPartnerId: a.partner && a.partner.id && a.partner.id,
      selectedNeubau: a.neubau,
      selectedInternalExternal: a.abrufer,
      benutzerfreigabe: a.nur_mit_benutzerfreigabe,
      persExportieren: a.persoenliche_daten_ergaenzen,
      selectedObjectType: a.typ,
      startDate:
        this.isValidDate(new Date(a.zeitraum_von)) === true
          ? new Date(a.zeitraum_von)
          : new Date(),
      endDate: this.isValidDate(new Date(a.zeitraum_bis))
        ? new Date(a.zeitraum_bis)
        : new Date(),
      selectedGoals: Config.getGoalLabelsById(a.ziele),
    });
  }

  getFilter() {
    API.get(`API/auswertungskonfiguration/${this.props.uuid}`)
      .then((a: any) => {
        this.saveInfosFromAPI(a);
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Filter aufgetreten');
      });
  }

  getObjecttypFilter() {
    API.get(
      'API/auswertungskonfiguration/newchoices?property=entity.neubau',
    ).then(s => {
      this.setState({
        neubauData: s,
      });
    });
  }
  getInternalExternalFilter() {
    API.get(
      'API/auswertungskonfiguration/newchoices?property=auswertungskonfiguration.abrufer',
    ).then(s => {
      this.setState({
        internalExternalData: s,
      });
    });
  }

  asyncLoadKunden = (inputValue: any, callback: any) => {
    API.get(
      `API/auswertungskonfiguration/newautocomplete?property=auswertungskonfiguration.kunde&searchfor=${inputValue}`,
    )
      .then((a: any) => {
        let kunden = [];
        for (let index = 0; index < a.length; index++) {
          kunden.push({ value: a[index].id, label: a[index].firma });
        }

        callback(kunden);
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Kunden aufgetreten');
      });
  };

  loadKunden() {
    API.get(
      `API/auswertungskonfiguration/newautocomplete?property=auswertungskonfiguration.kunde&searchfor=`,
    )
      .then((a: any) => {
        let kunden = [];
        for (let index = 0; index < a.length; index++) {
          kunden.push({ value: a[index].id, label: a[index].firma });
        }

        this.setState({
          kunden, //: a,
        });
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Kunden aufgetreten');
      });
  }

  asyncLoadPartner = (inputValue: any, callback: any) => {
    API.get(
      `API/auswertungskonfiguration/newautocomplete?property=auswertungskonfiguration.partner&searchfor=${inputValue}`,
    )
      .then((a: any) => {
        let partner = [];
        for (let index = 0; index < a.length; index++) {
          partner.push({ value: a[index].id, label: a[index].firma });
        }

        callback(partner);
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Partner aufgetreten');
      });
  };

  loadPartner() {
    API.get(`API/auswertungskonfiguration/hatpartner`).then((success: any) => {
      if (success === true) {
        API.get(
          `API/auswertungskonfiguration/newautocomplete?property=auswertungskonfiguration.partner&searchfor=`,
        )
          .then((a: any) => {
            let partner = [];
            for (let index = 0; index < a.length; index++) {
              partner.push({
                value: a[index].id,
                label: a[index].firma,
              });
            }
            this.setState({
              partner, //: a,
            });
          })
          .catch(e => {
            Alert.error('Fehler beim Laden der Partner aufgetreten');
          });
      }
    });
  }

  preselectDate(range: string) {
    let firstDay = new Date();
    let lastDay = new Date();
    var date = new Date();
    if (range === 'thisYear') {
      firstDay = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0);
      lastDay = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0, 0);
    }
    if (range === 'thisMonth') {
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    if (range === 'thisWeek') {
      var first = date.getDate() - date.getDay() + 1;
      var last = first + 6;
      firstDay = new Date(date.setDate(first));
      lastDay = new Date(date.setDate(last));
    }
    if (range === 'lastYear') {
      firstDay = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0, 0);
      lastDay = new Date(new Date().getFullYear() - 1, 11, 31, 0, 0, 0, 0);
    }
    if (range === 'lastMonth') {
      firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    }
    if (range === 'lastWeek') {
      first = date.getDate() - date.getDay() - 6;
      last = first + 6;
      firstDay = new Date(date.setDate(first));
      lastDay = new Date(date.setDate(last));
    }

    this.setState({
      startDate: firstDay,
      endDate: lastDay,
    });
  }

  handleChangeZeitraum(event: any) {
    this.setState({
      zeitraum: event.target.value,
    });

    this.preselectDate(event.target.value);
  }

  handleChangeDatenschutz() {
    this.setState(prevState => ({
      datenschutz: !prevState.datenschutz,
    }));
  }
  handleChangePersExportieren() {
    this.setState(prevState => ({
      persExportieren: !prevState.persExportieren,
    }));
  }
  handleChangeBenutzerfreigabe() {
    this.setState(prevState => ({
      benutzerfreigabe: !prevState.benutzerfreigabe,
    }));
  }
  handleChangeNeubau(event: any) {
    this.setState({
      selectedNeubau: event.target.value,
    });
  }
  handleChangeInternalExternal(event: any) {
    this.setState({
      selectedInternalExternal: event.target.value,
    });
  }
  handleChangeObjectType(event: any) {
    this.setState({
      selectedObjectType: event.target.value,
    });
  }
  handleChangeKunde(event: any) {
    let value = null;
    if (event && event.value) value = event.value;
    this.setState({
      selectedKundeId: value, //event.target.value,
    });
  }
  handleChangePartner(event: any) {
    let value = null;
    if (event && event.value) value = event.value;
    this.setState({
      selectedPartnerId: value, //event.target.value,
    });
  }

  handleChangeBezeichnung(event: any) {
    this.setState({
      bezeichnung: event.target.value,
    });
  }
  handleChangeStartDate(date: any) {
    this.setState({
      startDate: date,
    });
  }
  handleChangeEndDate(date: any) {
    this.setState({
      endDate: date,
    });
  }

  handleCheckbox(dataFromCheckbox: any) {
    let checkboxArray = this.state.selectedGoals;
    if (!this.state.selectedGoals.includes(dataFromCheckbox)) {
      checkboxArray.push(dataFromCheckbox);
    } else {
      var index = checkboxArray.indexOf(dataFromCheckbox); // <-- Not supported in <IE9
      if (index !== -1) {
        checkboxArray.splice(index, 1);
      }
    }
    this.setState({
      selectedGoals: checkboxArray,
    });
  }

  refresh() {
    this.setState({ loading: true });
    //append all config values
    let konfig = {
      ...this.state.auswertungskonfiguration,
      ziele: Config.getGoalIds(this.state.selectedGoals),
      zeitraum_von: this.state.startDate,
      zeitraum_bis: this.state.endDate,
      bezeichnung: this.state.bezeichnung,
      neubau: this.state.selectedNeubau,
      abrufer: this.state.selectedInternalExternal,
      persoenliche_daten_ergaenzen: this.state.persExportieren,
      nur_mit_benutzerfreigabe: this.state.benutzerfreigabe,
      kunde: this.state.selectedKundeId
        ? {
            id: this.state.selectedKundeId,
          }
        : null,
      partner: this.state.selectedPartnerId
        ? {
            id: this.state.selectedPartnerId,
          }
        : null,
      typ:
        this.state.selectedObjectType !== null
          ? parseInt(this.state.selectedObjectType.toString())
          : this.state.selectedObjectType,
      mod_aussenwaende:
        this.props.kampagnenFilter && this.props.kampagnenFilter.daemmung
          ? this.props.kampagnenFilter.daemmung
          : null,
      mod_bad:
        this.props.kampagnenFilter && this.props.kampagnenFilter.bad
          ? this.props.kampagnenFilter.bad
          : null,
      mod_dacherneuerung:
        this.props.kampagnenFilter && this.props.kampagnenFilter.dach
          ? this.props.kampagnenFilter.dach
          : null,
      mod_elektroinstallationen:
        this.props.kampagnenFilter && this.props.kampagnenFilter.elektro
          ? this.props.kampagnenFilter.elektro
          : null,
      mod_fenster_und_aussentueren:
        this.props.kampagnenFilter && this.props.kampagnenFilter.fenster
          ? this.props.kampagnenFilter.fenster
          : null,
      mod_fussboeden:
        this.props.kampagnenFilter && this.props.kampagnenFilter.fussboden
          ? this.props.kampagnenFilter.fussboden
          : null,
      mod_heizungsanlage:
        this.props.kampagnenFilter && this.props.kampagnenFilter.heizung
          ? this.props.kampagnenFilter.heizung
          : null,
      mod_innenwaende_und_tueren:
        this.props.kampagnenFilter && this.props.kampagnenFilter.innenwaende
          ? this.props.kampagnenFilter.innenwaende
          : null,
    };

    API.put(`API/auswertungskonfiguration/${this.props.uuid}`, konfig)
      .then((a: any) => {
        API.patch(
          `API/auswertungskonfiguration/${this.props.uuid}/auswerten`,
          {},
        ).then((patchData: any) => {
          this.saveInfosFromAPI(patchData);
          // this.getFilter();
          this.props.onRefresh();
          Alert.success('Tabelle aktualisiert');
          this.setState({ loading: false });
        });
      })
      .catch(e => {
        Alert.error('Fehler beim Aktualisieren der Tabelle aufgetreten');
        this.setState({ loading: false });
      });
  }

  customStylesSearchableSelect = {
    control: (base: any, state: any) => ({
      ...base,
      height: '2.05rem',
      minHeight: '2.05rem',
      borderColor: '#e5e6e7',
    }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      color: '#000',
    }),
  };

  getShowPartners() {
    API.get('API/auswertungskonfiguration/hatpartner')
      .then((a: any) => {
        this.setState({ showPartners: a });
      })
      .catch(e => {});
  }

  render() {
    return (
      <div className="filter-table">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Kunde</label>
              <AsyncSelect
                styles={this.customStylesSearchableSelect}
                isClearable={true}
                placeholder="Bitte Kunden wählen"
                defaultValue="Bitte Kunden wählen"
                noOptionsMessage={() => 'Kein Kunde gefunden'}
                loadOptions={this.asyncLoadKunden}
                value={
                  this.state.selectedKundeId
                    ? this.state.kunden.find(
                        kunde => kunde.value === this.state.selectedKundeId,
                      )
                    : this.state.auswertungskonfiguration.kunde &&
                      this.state.auswertungskonfiguration.kunde.id
                    ? {
                        value: this.state.auswertungskonfiguration.kunde.id,
                        label: this.state.auswertungskonfiguration.kunde.firma,
                      }
                    : null
                }
                isSearchable={true}
                defaultOptions={this.state.kunden}
                onChange={this.handleChangeKunde}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Objekttyp</label>
              <select
                className="form-control"
                onChange={this.handleChangeObjectType}
                value={this.state.selectedObjectType}
              >
                <option value="">Alle Objekttypen</option>
                {objectTypes.map(oType => (
                  <option key={oType.type} value={oType.type}>
                    {oType.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            {this.state.showPartners && (
              <div className="form-group">
                <label>Partner</label>
                <AsyncSelect
                  styles={this.customStylesSearchableSelect}
                  isClearable={true}
                  placeholder="Bitte Partner wählen"
                  defaultValue="Bitte Partner wählen"
                  noOptionsMessage={() => 'Kein Partner gefunden'}
                  loadOptions={this.asyncLoadPartner}
                  value={
                    this.state.selectedPartnerId
                      ? this.state.partner.find(
                          partner =>
                            partner.value === this.state.selectedPartnerId,
                        )
                        ? this.state.partner.find(
                            partner =>
                              partner.value === this.state.selectedPartnerId,
                          )
                        : this.state.auswertungskonfiguration.partner.id
                        ? {
                            value: this.state.auswertungskonfiguration.partner
                              .id,
                            label: this.state.auswertungskonfiguration.partner
                              .firma,
                          }
                        : null
                      : null
                  }
                  isSearchable={true}
                  defaultOptions={this.state.partner}
                  onChange={this.handleChangePartner}
                />
              </div>
            )}
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Bestand / Neubau</label>
              <select
                className="form-control"
                onChange={this.handleChangeNeubau}
                value={this.state.selectedNeubau}
              >
                <option value="">Alle</option>
                {Object.keys(this.state.neubauData).map(
                  (key: any, index: number) => (
                    <option
                      key={index + 'aussen'}
                      value={this.state.neubauData[key]}
                    >
                      {key}
                    </option>
                  ),
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Zeitraum</label>
              <select
                className="form-control"
                value={this.state.zeitraum}
                onChange={this.handleChangeZeitraum}
              >
                <option value="">Bitte wählen</option>
                <option value="thisYear">Dieses Jahr</option>
                <option value="thisMonth">Dieser Monat</option>
                <option value="thisWeek">Diese Woche</option>
                <option value="lastYear">Letztes Jahr</option>
                <option value="lastMonth">Letzter Monat</option>
                <option value="lastWeek">Letzte Woche</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="">Datenschutz</label>
              {/* <CustomCheckbox
                label={'Abgelaufene Datensätze ausblenden'}
                checked={this.state.datenschutz}
                onCheckValue={this.handleChangeDatenschutz}
              /> */}
              <CustomCheckbox
                onCheckValue={this.handleChangePersExportieren}
                label={
                  'Personenbezogene Daten mit exportieren, sofern möglich (Bitte beachten Sie die EU-DSGVO!)'
                }
                checked={this.state.persExportieren}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Von</label>
                  <br />
                  <DatePicker
                    className="form-control"
                    selected={this.state.startDate}
                    onChange={this.handleChangeStartDate}
                    dateFormat="dd.MM.yyyy"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Bis</label>
                  <br />
                  <DatePicker
                    className="form-control"
                    selected={this.state.endDate}
                    onChange={this.handleChangeEndDate}
                    dateFormat="dd.MM.yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Interne / Externe</label>
                  <select
                    className="form-control"
                    onChange={this.handleChangeInternalExternal}
                    value={this.state.selectedInternalExternal}
                  >
                    <option value="">Alle</option>
                    {Object.keys(this.state.internalExternalData).map(
                      (key: any, index: number) => (
                        <option
                          key={index + 'intern'}
                          value={this.state.internalExternalData[key]}
                        >
                          {key}
                        </option>
                      ),
                    )}
                  </select>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Tippgeber</label>
                  <select className="form-control">
                    <option value="">Alle</option>
                  </select>
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Motive</label>
              <div className="row">
                {checkboxValues.map((value, index) => (
                  <div className="col-lg-6" key={value}>
                    <CustomCheckbox
                      label={value}
                      checked={this.state.selectedGoals.includes(value)}
                      onCheckValue={this.handleCheckbox}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Marketingmaßnahmen</label>
                  <CustomCheckbox
                    onCheckValue={this.handleChangeBenutzerfreigabe}
                    label={'Zeige nur Daten mit erteilter Benutzerfreigabe'}
                    checked={this.state.benutzerfreigabe}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-4 offset-lg-4">
            <button
              className="btn btn-primary btn-block"
              onClick={this.refresh}
              disabled={this.state.loading}
              // style={{ maxWidth: '300px' }}
            >
              {this.state.loading && (
                <span>
                  <FontAwesomeIcon icon="spinner" spin />{' '}
                </span>
              )}
              Tabelle aktualisieren
            </button>
          </div>
          <div className="col-lg-4">
            <button
              className="btn btn-primary btn-block"
              onClick={this.handleShowExcelModal}
            >
              Excel Export
            </button>
            <Modal
              show={this.state.showExcelModal}
              onHide={this.handleShowExcelModal}
            >
              <Modal.Body>
                <div className="row mb-2 mt-4 justify-content-center">
                  <div className="col-lg-2">
                    <span className="float-right">
                      <FontAwesomeIcon icon={'shield-alt'} size={'3x'} />
                    </span>
                  </div>
                  <div className="col-lg-10">
                    <h3>Datenschutz beachten!</h3>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-10 offset-lg-2">
                    <p>
                      Bei dieser Aktion werden personenbezogene Daten bearbeitet
                      oder exportiert. Bitte gehen Sie besonders sorgfältig vor,
                      um nicht gegen die aktuellen Vorschriften des
                      Datenschutzes zu verstoßen.
                    </p>
                    <p>
                      Sprechen Sie im Zweifel Ihren Datenschutzbeauftragten an.
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: '#d2d2d2' }}>
                <Button variant="primary" onClick={this.handleShowExcelModal}>
                  Schließen
                </Button>

                <form action={`${Config.getAPIURL()}/API/auswertungskonfiguration/${this.props.uuid}/herunterladen`} method="post" target="_blank">

                  <input type="hidden" name="jwtToken" value={Auth.getToken()} />

                  <button
                    type="submit"
                    className="btn btn-primary"
                  >
                    Excel Export
                  </button>

                </form>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
