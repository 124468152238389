import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from "../services/API";
import { Alert } from "../services/alert";
import Config from "../services/config";
import { Button, Modal } from "react-bootstrap";
import { CustomCheckbox } from "./CustomCheckbox";
import ReactTooltip from "react-tooltip";
import Auth from "../services/auth";

let moment = require("moment");

interface State {
  show: boolean;
  tableData: Array<any>;
  searchedData: Array<any>;
  collapsedIndex: number | undefined;
  searchQuery: string;
  selectedNewEmail: string;
  ascOrder: boolean;
  noteTimeout: any;
  showDatenschutzModal: string;
  showExportModal: string;
  showPhoneModal: string;
  showNoteModal: string;
  showProtocolModal: string;
  showConfirmModal: string;
  selectedEmail: string;
  selectedKontaktyp: string;
  confirmModalTarget: string;
  showMarketingModal: string;
  // marketingSelection: string;
  statusList: any;
  kontakttypList: Array<any>;
  cockpitContacts: any;
  newCockpitContact: boolean;
  exportTermin: boolean;
  offset: number;

  aboWMAIds: number[];
  wmaMainIdMap: {
    [key:string]: number[];
  };
  wmaAboToMainIdMap: any;
  
  abosForCurrentRow: any[];
  loading: boolean;
}

interface Props {
  uuid: string;
  refreshHelper: number;
  auswertungskonfiguration: any;
}

export class CollapsibleAnalysenTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newCockpitContact: false,
      selectedNewEmail: "",
      show: true,
      tableData: [],
      searchedData: [],
      collapsedIndex: undefined,
      searchQuery: "",
      exportTermin: false,
      ascOrder: true,
      noteTimeout: 0,
      showDatenschutzModal: "",
      showNoteModal: "",
      confirmModalTarget: "",
      showExportModal: "",
      showProtocolModal: "",
      showPhoneModal: "",
      showMarketingModal: "",
      //  marketingSelection: '',
      showConfirmModal: "",
      selectedEmail: "",
      selectedKontaktyp: "",
      statusList: {},
      kontakttypList: [],
      cockpitContacts: [],
      offset: 20,

      aboWMAIds: [],
      wmaMainIdMap: {},
      wmaAboToMainIdMap: {},

      abosForCurrentRow: [],
      loading: false,
    };

    this.handleToggle = this.handleToggle.bind(this);
    //  this.toggleMarketingSelection = this.toggleMarketingSelection.bind(
    //    this,
    //  );
    this.handleSaveMarketingSelection = this.handleSaveMarketingSelection.bind(
      this
    );
    this.handleShowDatenschutzModal = this.handleShowDatenschutzModal.bind(
      this
    );
    this.handleShowExportModal = this.handleShowExportModal.bind(this);
    this.handleShowNoteModal = this.handleShowNoteModal.bind(this);
    this.handleShowPhoneModal = this.handleShowPhoneModal.bind(this);
    this.handleShowConfirmModal = this.handleShowConfirmModal.bind(this);
    this.handleShowMarketingModal = this.handleShowMarketingModal.bind(this);
    this.handleShowProtocolModal = this.handleShowProtocolModal.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
    this.handleChangeSelectedNewEmail = this.handleChangeSelectedNewEmail.bind(
      this
    );
    this.getCockpitContacts = this.getCockpitContacts.bind(this);
    this.sendNotes = this.sendNotes.bind(this);
    this.handleChangeTermin = this.handleChangeTermin.bind(this);
    this.handleChangeSearchQuery = this.handleChangeSearchQuery.bind(this);
    this.collapseRow = this.collapseRow.bind(this);
    this.getData = this.getData.bind(this);
    this.sortData = this.sortData.bind(this);
    this.extendDatenschutz = this.extendDatenschutz.bind(this);
    this.deletePersonalData = this.deletePersonalData.bind(this);
    this.showPhone = this.showPhone.bind(this);
    this.noInterest = this.noInterest.bind(this);
    this.handleChangeSelectedEmail = this.handleChangeSelectedEmail.bind(this);
    this.handleChangeSelectedKontakttyp = this.handleChangeSelectedKontakttyp.bind(
      this
    );
    this.sendExportMail = this.sendExportMail.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.getKontakttyp = this.getKontakttyp.bind(this);
    this.handleChangeExportTermin = this.handleChangeExportTermin.bind(this);
  }

  componentDidMount() {
    this.getData(true);

    this.getStatus();
    this.getKontakttyp();
    this.getCockpitContacts();
  }

  componentDidUpdate(oldProps: any) {
    const newProps = this.props;
    if (oldProps.refreshHelper !== newProps.refreshHelper) {
      this.setState({
        collapsedIndex: undefined
      });
      this.getData(true);
    }
  }

  handleChangeExportTermin() {
    this.setState((prevState: any) => ({
      exportTermin: !prevState.exportTermin
    }));
  }

  sortData(attribute: string, ascOrder?: boolean) {
    let sortedData = this.state.searchedData;
    let asc = this.state.ascOrder;
    if (typeof ascOrder !== "undefined") asc = ascOrder;
    this.setState((prevState: any) => ({
      ascOrder: !prevState.ascOrder
    }));

    sortedData.sort(function(obj1, obj2) {
      //asc
      let first = obj1;
      let second = obj2;
      //dsc
      if (asc === false) {
        first = obj2;
        second = obj1;
      }

      //sorting numbers
      if (typeof first[attribute] === "number") {
        // Ascending: first age less than the previous
        return first[attribute] - second[attribute];
      }
      //sorting strings
      if (typeof first[attribute] === "string") {
        return first[attribute].localeCompare(second[attribute]);
      }

      return 0;
    });

    this.setState({
      searchedData: sortedData
    });
  }

  async getData(initial?: boolean) {
    const self = this;

    await API.get(
      `API/auswertungsdatensatz?auswertung=${this.props.uuid}&itemsperpage=10000&page=1&sort_dir=desc`
    )
      .then(async s => {

        const aboWMAIds : any = [];
        const wmaMainIdMap : any = {};
        const wmaAboToMainIdMap : any = {};

        if (s.length > 0) {

          try {
            const mailAutomationData = await fetch(`https://api.iib-it.de/1.0/mail-automation/customers/${s[0].kunde.id}/wmas`, {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                Authorization: `Token ${Auth.accessToken}`
              },
            });

            (await mailAutomationData.json()).forEach((automationData: any) => {
              aboWMAIds.push(parseInt(automationData.wma_id));
              (wmaMainIdMap as any)[automationData.wma_id] = automationData.abos.filter((abo: any) => !!abo.wma_id).map((abo: any) => {
                aboWMAIds.push(parseInt(abo.wma_id));
                wmaAboToMainIdMap[parseInt(abo.wma_id)] = [
                  parseInt(automationData.wma_id),
                  automationData.wma_uuid
                ];
                return [
                  parseInt(abo.wma_id),
                  abo.wma_uuid
                ];
              });
            });
          } catch (e) {

          }

        }

        this.setState({
          tableData: s,
          searchedData: s,
          aboWMAIds,
          wmaMainIdMap,
          wmaAboToMainIdMap
        });
        if (typeof initial !== "undefined") this.sortData("wma_id", false);
      })
      .catch(e => {
        Alert.error("Tabellendaten konnten nicht geladen werden");
      });
  }

  getCockpitContacts() {
    API.get(
      "API/cockpitkontakt?itemsperpage=1000&page=1&sort_dir=desc&suchbegriff="
    ).then(cockpitContacts => {
      const checked : any = {};
      cockpitContacts = cockpitContacts.filter((contact : any) => !checked[contact.email] && (checked[contact.email] = true))
      this.setState({
        cockpitContacts: cockpitContacts
      });
    });
  }

  handleChangeSearchQuery(event: any) {
    this.setState({
      searchQuery: event.target.value
    });

    let results = [] as any;
    let data = this.state.tableData;
    let alreadyAdded;
    for (var i = 0; i < data.length; i++) {
      for (var key in data[i]) {
        if (
          // typeof data[i][key] === 'string' &&
          typeof data[i][key] !== "object"
        ) {
          const value = data[i][key].toString();

          if (value.indexOf(event.target.value) != -1 && alreadyAdded !== i) {
            results.push(data[i]);
            alreadyAdded = i;
          }
        }
      }
    }
    this.setState({
      searchedData: results
    });
  }
  handleToggle() {
    this.setState(prevState => ({
      show: !prevState.show
    }));
  }
  handleChangeSelectedEmail(event: any) {
    this.setState({
      selectedEmail: event.target.value
    });
  }
  handleChangeSelectedKontakttyp(event: any) {
    this.setState({
      selectedKontaktyp: event.target.value
    });
  }

  handleShowDatenschutzModal(id?: any) {
    let value = "";
    if (this.state.showDatenschutzModal === "") {
      value = id;
    }
    this.setState(prevState => ({
      showDatenschutzModal: value
    }));
  }
  handleShowNoteModal(id?: any) {
    let value = "";
    if (this.state.showNoteModal === "") {
      value = id;
    }
    this.setState(prevState => ({
      showNoteModal: value
    }));
  }
  handleShowExportModal(id?: any) {
    let value = "";
    if (this.state.showExportModal === "") {
      value = id;
    }
    this.setState(prevState => ({
      showExportModal: value,
      exportTermin: false
    }));
  }
  handleShowPhoneModal(id?: any) {
    let value = "";
    if (this.state.showPhoneModal === "") {
      value = id;
    }

    this.setState(prevState => ({
      showPhoneModal: value
    }));
  }

  handleShowMarketingModal(id?: any) {
    let value = "";
    if (this.state.showMarketingModal === "") {
      value = id;
    }
    this.setState(prevState => ({
      showMarketingModal: value
    }));
  }

  handleShowProtocolModal(id?: any) {
    let value = "";
    if (this.state.showProtocolModal === "") {
      value = id;
    }
    this.setState(prevState => ({
      showProtocolModal: value
    }));
  }

  truncate(input: string, length: number) {
    return input.length > length ? `${input.substring(0, length)}...` : input;
  }


  //  toggleMarketingSelection(event: any) {
  //    this.setState({ marketingSelection: event.target.value });
  //  }

  handleSaveMarketingSelection(id: string) {
    API.patch(`API/auswertungsdatensatz/${id}/togglebenutzerzustimmung`, {})
      .then(a => {
        Alert.success("Werbeeinwilligung wurde geändert");
        //Delete personal Data if agreement was canceled
        // if (a.benutzerzustimmung === 0) {
        //   API.patch(
        //     `API/auswertungsdatensatz/${id}/peronenbezogenedatenloeschen`,
        //     {},
        //   ).then(s => window.location.reload());
        // }
        this.getData();
        this.handleShowMarketingModal();
      })
      .catch(e => {
        Alert.error("Werbeeinwilligung konnte nicht geändert werden");
      });
  }

  handleShowConfirmModal(id?: any, type?: string) {
    let value = "";
    let modal_type = "";
    if (this.state.showConfirmModal === "") {
      value = id;
    }
    if (type) modal_type = type;

    this.setState(prevState => ({
      showConfirmModal: value,
      confirmModalTarget: modal_type
    }));
  }

  handleChangeWiedervorlage(event: any, id: string) {
    let date = moment(new Date(event)).format("YYYY-MM-DD HH:mm:ss");
    API.put(`API/auswertungsdatensatz/${id}`, {
      cockpit_wiedervorlage: date
    })
      .then(a => {
        let data = this.state.searchedData as any;
        let toChangeIndex = data.findIndex((x: any) => x.id === id);
        data[toChangeIndex].cockpit_wiedervorlage = event;
        this.setState({ searchedData: data });
        Alert.success("Wiedervorlage erfolgreich eingetragen");
      })
      .catch(e => {
        Alert.error("Wiedervorlage konnte nicht eingetragen werden");
      });
  }

  handleChangeTermin(event: any, id: number) {
    let date = moment(new Date(event)).format("YYYY-MM-DD HH:mm:ss");
    API.put(`API/auswertungsdatensatz/${id}`, {
      eigentuemer_termin: date
    })
      .then((a: any) => {
        let data = this.state.searchedData as any;
        let toChangeIndex = data.findIndex((x: any) => x.id === id);
        data[toChangeIndex].eigentuemer_termin = event;
        this.setState({ searchedData: data });
        Alert.success("Besichtigung erfolgreich eingetragen");
      })
      .catch(e => {
        Alert.error("Besichtigung konnte nicht eingetragen werden");
      });
  }

  saveNotes(event: any, id: string, index: number) {
    const value = event.target.value;
    //  const self = this;
    //  if (this.state.noteTimeout) {
    //    clearTimeout(this.state.noteTimeout);
    //  }

    let searchedData = this.state.searchedData;
    searchedData[index].cockpit_notizen = value;

    this.setState({
      searchedData
    });
    //debounce sending
    //  this.setState({
    //    noteTimeout: setTimeout(function() {
    //      self.sendNotes(value, id);
    //    }, 2000),
    //  });
  }

  async handleChangeSelectedNewEmail(event: any) {
    let selectedNewEmail = event.target.value;
    await this.setState({
      selectedNewEmail
    });
  }

  sendNotes(value: string, id: any) {
    API.put(`API/auswertungsdatensatz/${id}`, {
      cockpit_notizen: value
    })
      .then(a => {
        Alert.success("Notiz wurde gespeichert");
      })
      .catch(e => {
        Alert.error("Notiz konnte nicht gespeichert werden");
      });
    this.handleShowNoteModal();
  }

  collapseRow(row: any, index: number | undefined) {
    const self = this;
    
    return async () => {

      if (this.state.collapsedIndex === index) {
        index = undefined;
        this.setState({
          collapsedIndex: index
        });
      } else {

        await self.setState({
          collapsedIndex: index,
          abosForCurrentRow: [],
          loading: true,
        });
        
        if (self.state.wmaAboToMainIdMap[`${row.wma_id}`]){
          const [mainId, mainUuid] = self.state.wmaAboToMainIdMap[`${row.wma_id}`];
          const wmaIds = self.state.wmaMainIdMap[mainId] as any [];

          if (wmaIds) {
            const abosForCurrentRow = await Promise.all(wmaIds.map(([wmaId, wmaUuid]) => {
              return (async () => {
                const response = (await fetch(`https://forms.iib-it.de/analysen/eua/${wmaUuid}`));
                return {
                  ...(await response.json()),
                  result1_downloadlink: `https://forms.iib-it.de/analysen/download/${wmaUuid}.pdf`,
                }
              })();
            }));
            
            try{
              abosForCurrentRow.push({
                ...(await (await fetch(`https://forms.iib-it.de/analysen/eua/${mainUuid}`)).json()),
                result1_downloadlink: `https://forms.iib-it.de/analysen/download/${mainUuid}.pdf`
              });
              
              self.setState({
                abosForCurrentRow,
                loading: false,
              });
            } catch {      
              self.setState({
                abosForCurrentRow,
                loading: false,
              });
            }
            
          }
        }

      }
    }
  }

  getDate(date: any) {
    return new Date(date).toLocaleDateString('de-DE', {year: 'numeric', month: 'long', day: 'numeric' });
  }

  getShortDate(date: any) {
    const dateObj = new Date(date);
    return `${dateObj.getDate()}.${dateObj.getMonth()+1}.${dateObj.getFullYear()}`;
  }

  showPhone(id: string) {
    API.patch(`API/auswertungsdatensatz/${id}/telefonnummerangezeigt`, {})
      .then(a => {
        this.handleShowPhoneModal(id);
      })
      .catch(e => {
        Alert.error(
          "Beim Abrufen der Telefonnummer ist ein Fehler aufgetreten"
        );
      });
  }

  noInterest(id: string) {
    API.patch(`API/auswertungsdatensatz/${id}/keininteresse`, {})
      .then(a => {
        API.patch(
          `API/auswertungsdatensatz/${id}/peronenbezogenedatenloeschen`,
          {}
        ).then(a => {
          Alert.success("Kein Interesse wurde gespeichert");
          this.handleShowConfirmModal();
        });
      })
      .catch(e => {
        Alert.error("Kein Interesse konnte nicht gespeichert werden");
        this.handleShowConfirmModal();
      });
  }

  extendDatenschutz(id: string) {
    API.patch(`API/auswertungsdatensatz/${id}/datenschutzverlaengern`, {})
      .then(a => {
        Alert.success("Datenschutzfrist wurde verlängert");
        this.handleShowDatenschutzModal();
      })
      .catch(e => {
        Alert.error("Datenschutzfrist konnte nicht verlängert werden");
      });
  }

  deletePersonalData(id: string) {
    API.patch(`API/auswertungsdatensatz/${id}/peronenbezogenedatenloeschen`, {})
      .then(a => {
        Alert.success("Personenbezogene Daten erfolgreich gelöscht");
        this.handleShowConfirmModal();
        window.location.reload();
      })
      .catch(e => {
        Alert.error(
          "Beim Löschen der personenbezogenen Daten ist ein Fehler aufgetreten"
        );
        this.handleShowConfirmModal();
      });
  }
  getStatus() {
    API.get(`API/auswertungsdatensatz/statusliste`, {}).then(a => {
      this.setState({
        statusList: a
      });
    });
  }
  getKontakttyp() {
    API.get(
      `API/cockpitkontakt/newautocomplete?property=entity.kontakttyp`,
      {}
    ).then(a => {
      this.setState({
        kontakttypList: a
      });
    });
  }

  toggleEigenabruf = (id: string, index: number) => {
    let searchedData = this.state.searchedData;
    API.patch(`API/auswertungsdatensatz/${id}/toggleeigenabruf`, {})
      .then((eigenabruf: any) => {
        searchedData[index].abrufer === 1
          ? (searchedData[index].abrufer = 2)
          : (searchedData[index].abrufer = 1);
        this.setState({ searchedData });
      })
      .catch(e => {});
  };

  sendExportMail(id: number) {
    if (this.state.selectedEmail && this.state.selectedEmail !== "new") {
      let url = `API/auswertungsdatensatz/${id}/verschicken?kontakt_id=${this.state.selectedEmail}`;
      if (this.state.exportTermin === true) url += "&mit_termin=true";
      API.patch(url, {}).then(success => {
        Alert.success("Visitenkarte versendet.");
        this.handleShowExportModal();
      });
    }
    if (this.state.selectedEmail && this.state.selectedEmail === "new") {
      // Kontakt anlegen
       API.post("API/cockpitkontakt", {
        id: id,
        email: this.state.selectedNewEmail,
        kontakttyp: {
          id: this.state.selectedKontaktyp,
          aktiv: true,
          sortierung: 1
        }
      })
        .then((success: any) => {
          this.getCockpitContacts();
          API.patch(
            `API/auswertungsdatensatz/${id}/verschicken?kontakt_id=${success.id}`,
            {}
          ).then(success => {
            Alert.success("Kontakt angelegt und Visitenkarte versendet.");
            this.handleShowExportModal();
          });
        })
        .catch(error => {
          Alert.error("Beim Anlegen des Kontakts ist ein Fehler aufgetreten");
        });
    }
  }

  downloadProtocol = (benutzerprotokoll: string) => {
    var FileSaver = require("file-saver");
    var content = benutzerprotokoll;
    // any kind of extension (.txt,.cpp,.cs,.bat)
    var filename = "benutzerprotokoll.txt";

    var blob = new Blob([content], {
      type: "text/plain;charset=utf-8"
    });
    FileSaver.saveAs(blob, filename);
  };

  compareDates = (a: string, b: string) => {
    return (new Date(b)).getTime() - (new Date(a)).getTime();
  }

  render() {
    return (
      <React.Fragment>
        <input
          type="text"
          className="form-control mb-3 search-table"
          placeholder={
            this.state.searchedData.length +
            " Ergebnisse in Tabelle durchsuchen"
          }
          onChange={this.handleChangeSearchQuery}
          value={this.state.searchQuery}
        />
        <div className="table-responsive">
          <table
            className="table table-condensed analysen-table"
            style={{ overflowY: "scroll" }}
          >
            <thead>
              <tr>
                <th />
                <th>WMA</th>
                <th onClick={() => this.sortData("wma_id")}>
                  ID <FontAwesomeIcon icon={"sort"} />
                </th>
                <th>Tel</th>
                <th>E-Mail</th>
                <th onClick={() => this.sortData("objekt_plz")}>
                  PLZ <FontAwesomeIcon icon={"sort"} />
                </th>
                <th onClick={() => this.sortData("objekt_ort")}>
                  Ort <FontAwesomeIcon icon={"sort"} />
                </th>
                <th onClick={() => this.sortData("annahmedatum")}>
                  Abrufdatum <FontAwesomeIcon icon={"sort"} />
                </th>
                <th onClick={() => this.sortData("ziel")}>
                  Motiv <FontAwesomeIcon icon={"sort"} />
                </th>
                <th onClick={() => this.sortData("richtwert")}>
                  Richtwert <FontAwesomeIcon icon={"sort"} />
                </th>
                <th onClick={() => this.sortData("datenschutztage")}>
                  Kontaktfrist <FontAwesomeIcon icon={"sort"} />
                </th>
                <th>Löschung</th>
                {/* <th onClick={() => this.sortData('cockpit_wiedervorlage')}>
                  Wiedervorlage <FontAwesomeIcon icon={'sort'} />
                </th>
                <th onClick={() => this.sortData('eigentuemer_termin')}>
                  Besichtigung <FontAwesomeIcon icon={'sort'} />
                </th> */}
                <th>Lead</th>
                <th>Bearbeiter</th>
                <th onClick={() => this.sortData("cockpit_status")}>
                  Status <FontAwesomeIcon icon={"sort"} />
                </th>
                {/* <th>ToDo</th> */}
                <th>Notiz</th>
                <th>Export</th>
              </tr>
            </thead>
            <tbody>
              {this.state.searchedData.map(
                (row, index) =>
                  index < this.state.offset && (
                    <React.Fragment key={"collapsibleAnalysisTableRow" + index}>
                      <tr
                        style={
                          row.cockpit_tel_angezeigt
                            ? {}
                            : {
                                background: "#f3f3f4"
                              }
                        }
                      >
                        <td onClick={this.collapseRow(row, index)}>
                          {this.state.collapsedIndex !== index ? (
                            <FontAwesomeIcon icon={"chevron-right"} />
                          ) : (
                            <FontAwesomeIcon icon={"chevron-down"} />
                          )}
                        </td>
                        <td>
                          {row.result1_downloadlink ? (
                            <a
                              onClick={() =>
                                window.open(row.result1_downloadlink, "_blank")
                              }
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={"download"} />
                            </a>
                          ) : (
                            <FontAwesomeIcon icon={"times"} />
                          )}
                        </td>
                        <td style={{
                          whiteSpace: 'nowrap'
                        }}>
                          {row.wma_id}
                          {' '}
                          {(this.state.aboWMAIds.indexOf(row.wma_id) > -1 && this.state.wmaAboToMainIdMap[`${row.wma_id}`] && this.state.wmaMainIdMap[this.state.wmaAboToMainIdMap[`${row.wma_id}`][0]]) && <FontAwesomeIcon icon={"sync"} style={{ color: '#1ab394' }} />}

                        </td>
                        <td>
                          {row.eigentuemer_telefon ? (
                            <React.Fragment>
                              <a onClick={() => this.showPhone(row.id)}>
                                <FontAwesomeIcon icon={"phone"} />
                              </a>
                              <Modal
                                show={this.state.showPhoneModal === row.id}
                                onHide={this.handleShowPhoneModal}
                              >
                                <Modal.Body>
                                  <div className="row mb-2 mt-4 justify-content-center">
                                    <div className="col-lg-2">
                                      <span className="float-right">
                                        <FontAwesomeIcon
                                          icon={"phone"}
                                          size={"3x"}
                                        />
                                      </span>
                                    </div>
                                    <div className="col-lg-10">
                                      <h3>
                                        {row.eigentuemer_telefon && (
                                          <span>
                                            Telefonnummer:{" "}
                                            {row.eigentuemer_telefon}
                                          </span>
                                        )}
                                      </h3>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer
                                  style={{ backgroundColor: "#d2d2d2" }}
                                >
                                  <Button
                                    variant="primary"
                                    onClick={this.handleShowPhoneModal}
                                  >
                                    Schließen
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </React.Fragment>
                          ) : (
                            <FontAwesomeIcon icon={"times"} />
                          )}
                        </td>
                        <td
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden"
                          }}
                        >
                          {row.eigentuemer_email ? (
                            row.eigentuemer_email
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                        <td>
                          {row.objekt_plz ? row.objekt_plz : <span>-</span>}
                        </td>
                        <td>
                          {row.objekt_ort ? row.objekt_ort : <span>-</span>}
                        </td>
                        <td>
                          {row.annahmedatum ? (
                            this.getDate(row.annahmedatum)
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                        <td>{Config.getGoalLabel(row.ziel)}</td>
                        <td>
                          {row.richtwert ? (
                            Math.round(row.richtwert)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                        <td>
                          {!row.persoenliche_daten_geloescht_am &&
                          row.benutzerzustimmung ? (
                            <span
                              data-tip="Der Kunde hat seine Werbeeinwilligung gegeben, und darf bis zum Widerruf seiner Einwilligung kontaktiert werden. Die personenbezogenen Daten werden nicht automatisiert gelöscht."
                              data-for={"Werbeeinwilligung_erteilt"}
                            >
                              <span
                                style={{ width: "40px" }}
                                className="badge badge-primary"
                              >
                                &nbsp;
                              </span>
                              <ReactTooltip id={"Werbeeinwilligung_erteilt"} />
                            </span>
                          ) : !row.persoenliche_daten_geloescht_am &&
                            !row.benutzerzustimmung &&
                            row.datenschutztage ? (
                            <span
                              data-tip={`Gemäß DSGVO wird der Datensatz in ${
                                row.datenschutztage
                              } Tage${
                                row.datenschutztage !== 1 ? "n" : ""
                              } gelöscht.`}
                              data-for={"Werbeeinwilligung_nicht_erteilt"}
                            >
                              <span
                                style={{ width: "40px" }}
                                className={
                                  row.datenschutztage >= 30
                                    ? "badge badge-primary"
                                    : row.datenschutztage < 30 &&
                                      row.datenschutztage > 0
                                    ? "badge badge-danger"
                                    : "badge badge-secondary"
                                }
                              >
                                {row.datenschutztage}
                              </span>
                              <ReactTooltip
                                id={"Werbeeinwilligung_nicht_erteilt"}
                              />
                            </span>
                          ) : (
                                <span
                                    data-tip={"Personenbezogene Daten wurden automatisch gelöscht."}
                                    data-for={"Werbeeinwilligung_gelöscht"}
                                >
                                    <span
                                      style={{ width: "40px" }}
                                      className="badge badge-secondary"
                                    >
                                    -
                                    </span>

                                      <ReactTooltip
                                          id={"Werbeeinwilligung_gelöscht"}
                                      />
                              </span>
                          )}
                        </td>
                        <td>
                          {row.eigentuemer_email ? (
                            <React.Fragment>
                              <span
                                onClick={() =>
                                  this.handleShowConfirmModal(
                                    row.id,
                                    "löschantrag"
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={"trash-alt"} />
                              </span>
                              <Modal
                                show={this.state.showConfirmModal === row.id}
                                onHide={this.handleShowConfirmModal}
                              >
                                <Modal.Body>
                                  <div className="row mb-2 mt-4 justify-content-center">
                                    <div className="col-lg-2">
                                      <span className="float-right">
                                        <FontAwesomeIcon
                                          icon={"question"}
                                          size={"3x"}
                                        />
                                      </span>
                                    </div>
                                    <div className="col-lg-10">
                                      <h3>
                                        {this.state.confirmModalTarget ===
                                          "löschantrag" &&
                                          "Personenbezogene Daten löschen?"}
                                        {this.state.confirmModalTarget ===
                                          "interesse" &&
                                          "Kein weiteres Interesse?"}
                                      </h3>
                                      <p>
                                        Wenn Sie diesen Dialog mit "Bestätigen"
                                        beenden, bleibt der Datensatz
                                        anonymisiert erhalten, die
                                        personenbezogenen Daten des Nutzers
                                        werden jedoch gelöscht, da es keine
                                        weitere Berechtigung zur Kontaktaufnahme
                                        gibt.
                                        <br></br>
                                        <br></br>
                                        Etwaige Überorganisationen von denen das
                                        iib als Datenverarbeiter beauftragt
                                        wurde werden automatisiert zur Löschung
                                        aufgefordert.<br></br>
                                        <br></br>Sollten Sie die
                                        personenbezogenen Daten des Nutzers in
                                        anderen Systemen (z.B. CRM, Excel, etc.)
                                        abgespeichert haben, müssen Sie diese
                                        gesondert löschen.
                                        <br></br>
                                        <br></br>Gelöschte Daten können nicht
                                        wiederhergestellt werden.
                                      </p>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer
                                  style={{ backgroundColor: "#d2d2d2" }}
                                >
                                  <Button
                                    variant="primary"
                                    onClick={this.handleShowConfirmModal}
                                  >
                                    Schließen
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      this.state.confirmModalTarget ===
                                      "interesse"
                                        ? this.noInterest(row.id)
                                        : this.state.confirmModalTarget ===
                                          "löschantrag"
                                        ? this.deletePersonalData(row.id)
                                        : null
                                    }
                                  >
                                    Bestätigen
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </React.Fragment>
                          ) : (
                            <FontAwesomeIcon icon={"times"} />
                          )}
                        </td>
                        <td>
                          {row.referrer?(
                              <a href={row.referrer}>{this.truncate(row.referrer.replace(/(^\w+:|^)\/\//, ''), 20)}</a>
                          ):(
                              <span>-</span>
                          )}

                        </td>
                        {/* <td>
                          {row.cockpit_wiedervorlage ? (
                            this.getDate(row.cockpit_wiedervorlage)
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                        <td>
                          {row.eigentuemer_termin ? (
                            this.getDate(row.eigentuemer_termin)
                          ) : (
                            <span>-</span>
                          )}
                        </td> */}
                        <td>
                          {row.cockpit_lastuser ? (
                            row.cockpit_lastuser
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                        <td>
                          {row.cockpit_status ? (
                            this.state.statusList[row.cockpit_status]
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                        {/* <td>
                          {(row.cockpit_wiedervorlage ||
                            row.eigentuemer_termin ||
                            row.cockpit_status === 400) &&
                          !row.persoenliche_daten_geloescht_am &&
                          row.datenschutztage > 0 ? (
                            <span
                              data-tip={
                                row.eigentuemer_termin
                                  ? 'Es wurde ein Termin vereinbart'
                                  : row.cockpit_wiedervorlage
                                  ? 'Datensatz wurde zur Wiedervorlage makiert'
                                  : row.cockpit_status === 400
                                  ? 'Datensatz wurde an CRM übergeben'
                                  : ''
                              }
                              data-for={index + 'green'}
                            >
                              <FontAwesomeIcon
                                icon={'check-circle'}
                                size={'lg'}
                                style={{
                                  color: 'green',
                                }}
                              />
                              <ReactTooltip id={index + 'green'} />
                            </span>
                          ) : row.persoenliche_daten_geloescht_am ||
                            !row.datenschutztage ? (
                            <span
                              data-tip={`Die persönlichen Daten wurden ${
                                row.persoenliche_daten_geloescht_am
                                  ? 'manuell'
                                  : 'automatisch'
                              } gelöscht`}
                              data-for={index + 'grey'}
                            >
                              <FontAwesomeIcon
                                icon={'circle'}
                                size={'lg'}
                                style={{
                                  color: 'grey',
                                }}
                              />
                              <ReactTooltip id={index + 'grey'} />
                            </span>
                          ) : (
                            <span
                              data-tip={`Bitte vereinbaren Sie einen Termin mit dem Kunden oder wählen Sie einen Termin zur Wiedervorlage`}
                              data-for={index + 'red'}
                            >
                              <FontAwesomeIcon
                                icon={'exclamation-circle'}
                                size={'lg'}
                                style={{
                                  color: 'red',
                                }}
                              />
                              <ReactTooltip id={index + 'red'} />
                            </span>
                          )}
                        </td> */}
                        <td>
                          {row.cockpit_notizen && (
                            <span
                              onClick={() => this.handleShowNoteModal(row.id)}
                            >
                              <FontAwesomeIcon
                                icon={"sticky-note"}
                                size={"lg"}
                                style={{
                                  color: "green"
                                }}
                              />
                            </span>
                          )}
                          <Modal
                            show={this.state.showNoteModal === row.id}
                            onHide={this.handleShowNoteModal}
                          >
                            <Modal.Body>
                              <div className="row mb-2 mt-4 justify-content-center">
                                <div className="col-lg-2">
                                  <span className="float-right">
                                    <FontAwesomeIcon
                                      icon={"sticky-note"}
                                      size={"3x"}
                                    />
                                  </span>
                                </div>
                                <div className="col-lg-10">
                                  <h3>Notiz</h3>
                                  <textarea
                                    className="form-control"
                                    rows={10}
                                    value={row.cockpit_notizen}
                                    onChange={e =>
                                      this.saveNotes(e, row.id, index)
                                    }
                                  ></textarea>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer
                              style={{ backgroundColor: "#d2d2d2" }}
                            >
                              <Button
                                variant="primary"
                                onClick={this.handleShowNoteModal}
                              >
                                Schließen
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() =>
                                  this.sendNotes(row.cockpit_notizen, row.id)
                                }
                              >
                                Speichern
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </td>
                        <td>
                          {row.eigentuemer_email ? (
                            <React.Fragment>
                              <a
                                onClick={() =>
                                  this.handleShowExportModal(row.id)
                                }
                              >
                                <FontAwesomeIcon icon={"address-card"} />
                              </a>
                              <Modal
                                show={this.state.showExportModal === row.id}
                                onHide={this.handleShowExportModal}
                              >
                                <Modal.Body>
                                  <p>
                                    <FontAwesomeIcon icon={"address-card"} />{" "}
                                    Export personenbezogener Daten
                                  </p>
                                  <div className="row mb-2 mt-4 justify-content-center">
                                    <div className="col-lg-2">
                                      <span className="float-right">
                                        <FontAwesomeIcon
                                          icon={"paper-plane"}
                                          size={"3x"}
                                        />
                                      </span>
                                    </div>
                                    <div className="col-lg-10">
                                      <h3>
                                        Visitenkarte & XML per E-Mail versenden:
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-10 offset-lg-2">
                                      <select
                                        className="form-control mb-2"
                                        value={this.state.selectedEmail}
                                        onChange={
                                          this.handleChangeSelectedEmail
                                        }
                                      >
                                        <option value="" disabled>
                                          Bitte E-Mail-Adresse auswählen
                                        </option>
                                        <option value="new">
                                          Neue E-Mail-Adresse anlegen
                                        </option>
                                        {this.state.cockpitContacts.map(
                                          (user: any) => (
                                            <option
                                              value={user.id}
                                              key={user.id}
                                            >
                                              {user.email}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <p>
                                        Wählen Sie die Mailadresse aus, an die
                                        die Adresse & die XML-Datei exportiert werden sollen.
                                      </p>
                                      {row.eigentuemer_termin && (
                                        <div className="mt-1 mb-2">
                                          <CustomCheckbox
                                            onCheckValue={
                                              this.handleChangeExportTermin
                                            }
                                            label={"Termin mit exportieren"}
                                            checked={this.state.exportTermin}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.selectedEmail === "new" && (
                                    <React.Fragment>
                                      <div className="row mb-2">
                                        <div className="col-lg-10 offset-lg-2">
                                          <input
                                            type="text"
                                            className="form-control mb-2"
                                            value={this.state.selectedNewEmail}
                                            onChange= {
                                              this.handleChangeSelectedNewEmail
                                            }
                                          />
                                          <p>
                                            Geben Sie eine neue E-Mail-Adresse
                                            ein.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row mb-2">
                                        <div className="col-lg-10 offset-lg-2">
                                          <select
                                            className="form-control mb-2"
                                            value={this.state.selectedKontaktyp}
                                            onChange={
                                              this
                                                .handleChangeSelectedKontakttyp
                                            }
                                          >
                                            <option value="" disabled>
                                              Kategorie wählen
                                            </option>
                                            {this.state.kontakttypList.map(
                                              (typ: any) => (
                                                <option
                                                  value={typ.id}
                                                  key={typ.id}
                                                >
                                                  {typ.bezeichnung}
                                                </option>
                                              )
                                            )}
                                          </select>

                                          <p>
                                            Wählen Sie bei neuen Adressen aus,
                                            als was der Empfänger kategorisiert
                                            werden soll.
                                          </p>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )}
                                  <div className="row mb-2 justify-content-center">
                                    <div className="col-lg-2">
                                      <span className="float-right">
                                        <FontAwesomeIcon
                                          icon={"shield-alt"}
                                          size={"3x"}
                                        />
                                      </span>
                                    </div>
                                    <div className="col-lg-10">
                                      <h3>Datenschutz beachten!</h3>
                                    </div>
                                  </div>
                                  <div className="row mb-2">
                                    <div className="col-lg-10 offset-lg-2">
                                      <p>
                                        Bei dieser Aktion werden
                                        personenbezogene Daten bearbeitet oder
                                        exportiert. Bitte gehen Sie besonders
                                        sorgfältig vor, um nicht gegen die
                                        aktuellen Vorschriften des Datenschutzes
                                        zu verstoßen.
                                      </p>
                                      <p>
                                        Sprechen Sie im Zweifel Ihren
                                        Datenschutzbeauftragten an.
                                      </p>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer
                                  style={{ backgroundColor: "#d2d2d2" }}
                                >
                                  <Button
                                    variant="primary"
                                    onClick={this.handleShowExportModal}
                                  >
                                    Abbruch
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={() => this.sendExportMail(row.id)}
                                    disabled={
                                      !(
                                        (this.state.selectedEmail &&
                                          this.state.selectedEmail !== "new") ||
                                        (this.state.selectedNewEmail &&
                                          this.state.selectedKontaktyp)
                                      )
                                    }
                                  >
                                    Senden
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </React.Fragment>
                          ) : (
                            <FontAwesomeIcon icon={"times"} />
                          )}
                        </td>
                      </tr>
                      {this.state.collapsedIndex === index && (
                        <tr>
                          <td colSpan={100} className="">
                            <div className="row mt-2">
                              <div className="col-lg-2">
                                <div className="table-block">
                                  <strong>Personenbezogene Daten</strong>
                                  <br />
                                  {row.persoenliche_daten_geloescht_am && (
                                    <span>
                                      Personenbezogene Daten wurden manuell
                                      gelöscht am{" "}
                                      {this.getDate(
                                        row.persoenliche_daten_geloescht_am
                                      )}{" "}
                                      von {row.cockpit_lastuser}
                                    </span>
                                  )}
                                  <br></br>
                                  {!row.datenschutztage && (
                                    <span>
                                      Personenbezogene Daten wurden automatisch
                                      gelöscht am{" "}
                                      {this.getDate(row.datenschutzfrist)}
                                    </span>
                                  )}
                                  <span className="">
                                    {row.eigentuemer_vorname}{" "}
                                    {row.eigentuemer_nachname}
                                  </span>
                                  <span className="">
                                    {row.eigentuemer_email && (
                                      <a
                                        href={"mailto:" + row.eigentuemer_email}
                                      >
                                        {row.eigentuemer_email}
                                      </a>
                                    )}
                                  </span>
                                  <br />
                                  <span className="">
                                    {row.eigentuemer_telefon && (
                                      <a onClick={() => this.showPhone(row.id)}>
                                        <FontAwesomeIcon icon={"phone"} />{" "}
                                        Telefonnummer abrufen
                                      </a>
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className="col-lg-2">
                                <div className="table-block">
                                  <strong>Objektdetails</strong>
                                  <br />
                                  <span className="">
                                    {row.objekt_strasse} {row.objekt_hausnummer}
                                  </span>
                                  <span className="">
                                    {row.objekt_plz} {row.objekt_ort}
                                  </span>
                                  <br />
                                  <span className="">
                                    {Config.getOfferVariantLabelByType(row.typ)}
                                  </span>
                                  <span className="">
                                    {row.objekt_typ_text}
                                  </span>
                                  <span className="">
                                    {row.objekt_baujahr ? (
                                      <span>Baujahr {row.objekt_baujahr}</span>
                                    ) : (
                                      <span>Baujahr unbekannt</span>
                                    )}
                                  </span>
                                  <span className="">
                                    Motiv:{" "}
                                    {Config.getGoalLabel(row.ziel) ? (
                                      Config.getGoalLabel(row.ziel)
                                    ) : (
                                      <span>keine Angabe</span>
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className="col-lg-2">
                                <div className="table-block">
                                  <strong>Wohnmarktanalyse</strong>
                                  <br />
                                  <span className="">
                                    WMA-ID:{" "}
                                    {row.wma_id ? row.wma_id : <span>-</span>}
                                  </span>
                                  <span className="">
                                    Stichtag: {this.getDate(row.annahmedatum)}
                                  </span>
                                  <br />
                                  <span className="">
                                    Richtwert:{" "}
                                    {row.richtwert ? (
                                      Math.round(row.richtwert)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
                                      " €"
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </span>
                                  <br />
                                  <span className="">
                                    {row.result1_downloadlink ? (
                                      <>
                                        <a
                                          onClick={() =>
                                            window.open(
                                              row.result1_downloadlink,
                                              "_blank"
                                            )
                                          }
                                          target="_blank"
                                        >
                                          <span>
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={"download"}
                                            />{" "}
                                            WMA Langfassung herunterladen
                                          </span>
                                        </a>
                                        {row.result2_downloadlink && (
                                          <a
                                            onClick={() =>
                                              window.open(
                                                row.result2_downloadlink,
                                                "_blank"
                                              )
                                            }
                                            target="_blank"
                                          >
                                            <span>
                                              {" "}
                                              <FontAwesomeIcon
                                                icon={"download"}
                                              />{" "}
                                              WMA Kurzfassung herunterladen
                                            </span>
                                          </a>
                                        )}
                                        {row.result1_accesskey && row.kunde && row.kunde.bezeichnung && (row.kunde.bezeichnung.indexOf('sparkasse') > -1 || row.kunde.bezeichnung.indexOf('Sparkasse') > -1) && (
                                          <a
                                            onClick={() =>
                                              window.open(
                                                `https://immobilien.sparkasse.de/preisfinder-anfrage.html?hash=${row.result1_accesskey}`,
                                                "_blank"
                                              )
                                            }
                                            target="_blank"
                                          >
                                            <span>
                                              {" "}
                                              <FontAwesomeIcon
                                                icon={"download"}
                                              />{" "}
                                              SFP Landingpage
                                            </span>
                                          </a>
                                        )}
                                      </>
                                    ) : (
                                      <span>
                                        <FontAwesomeIcon icon={"times"} /> keine
                                        WMA vorhanden
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className="col-lg-3">
                                <div className="table-block">
                                  <strong>Kontaktfrist</strong>
                                  <br />
                                  {(!row.benutzerzustimmung && row.datenschutztage) ? (
                                    <span>
                                      Automatische Löschung in{" "}
                                      {row.datenschutztage} Tagen
                                    </span>
                                  ) : !row.benutzerzustimmung?(
                                    <span>
                                      Personenbezogene Daten wurden automatisch
                                      gelöscht
                                    </span>
                                  ):(<span>
                                      Werbeeinwilligung wurde erteilt
                                    </span>)}
                                  <br />
                                  {row.eigentuemer_email && (
                                    <a
                                      onClick={() =>
                                        this.handleShowConfirmModal(
                                          row.id,
                                          "löschantrag"
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon icon={"trash-alt"} />{" "}
                                      Löschantrag stellen
                                    </a>
                                  )}

                                  <br />
                                  <span className="">
                                    Überleitung an:{" "}
                                    {row.cockpit_verschickt_email
                                      ? row.cockpit_verschickt_email
                                      : "-"}
                                  </span>
                                  <span className="">
                                    Überleitung vom:{" "}
                                    {row.cockpit_verschickt_am
                                      ? this.getDate(row.cockpit_verschickt_am)
                                      : "-"}
                                  </span>
                                  <br />

                                  <Modal
                                    show={
                                      this.state.showDatenschutzModal === row.id
                                    }
                                    onHide={this.handleShowDatenschutzModal}
                                  >
                                    <Modal.Body>
                                      <p>Löschung personenbezogener Daten</p>
                                      <div className="row mb-2 mt-4 justify-content-center">
                                        <div className="col-lg-2">
                                          <span className="float-right">
                                            <FontAwesomeIcon
                                              icon={"stopwatch"}
                                              size={"3x"}
                                            />
                                          </span>
                                        </div>
                                        <div className="col-lg-10">
                                          <h3>
                                            Datenlöschfrist zurücksetzen für:
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="row mb-2">
                                        <div className="col-lg-10 offset-lg-2">
                                          <input
                                            type="text"
                                            className="form-control"
                                            disabled
                                            value={
                                              row.eigentuemer_vorname
                                                ? row.eigentuemer_vorname +
                                                  " " +
                                                  row.eigentuemer_nachname
                                                : "Kein Name bekannt"
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="row mb-2">
                                        <div className="col-lg-10 offset-lg-2">
                                          <p>
                                            Hat Ihnen der Interessent / Kunde
                                            die Erlaubnis erteilt seine
                                            Kontaktdaten weiterhin zu speichern,
                                            dann können Sie diesen Dialog mit
                                            "Zurücksetzen" bestätigen und die
                                            Frist bis zur automatischen Löschung
                                            der personenbezogenen Daten wird
                                            gem. der aktuell gültigen Löschfrist
                                            zurückgesetzt. Der Kunde erhält
                                            automatisch eine E-Mail, durch die
                                            er über diese Fristverlängerung
                                            informiert wird.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row mb-2 justify-content-center">
                                        <div className="col-lg-2">
                                          <span className="float-right">
                                            <FontAwesomeIcon
                                              icon={"shield-alt"}
                                              size={"3x"}
                                            />
                                          </span>
                                        </div>
                                        <div className="col-lg-10">
                                          <h3>Datenschutz beachten!</h3>
                                        </div>
                                      </div>
                                      <div className="row mb-2">
                                        <div className="col-lg-10 offset-lg-2">
                                          <p>
                                            Bei dieser Aktion werden
                                            personenbezogene Daten bearbeitet
                                            oder exportiert. Bitte gehen Sie
                                            besonders sorgfältig vor, um nicht
                                            gegen die aktuellen Vorschriften des
                                            Datenschutzes zu verstoßen.
                                          </p>
                                          <p>
                                            Sprechen Sie im Zweifel Ihren
                                            Datenschutzbeauftragten an.
                                          </p>
                                        </div>
                                      </div>
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{
                                        backgroundColor: "#d2d2d2"
                                      }}
                                    >
                                      <Button
                                        variant="primary"
                                        onClick={
                                          this.handleShowDatenschutzModal
                                        }
                                      >
                                        Abbruch
                                      </Button>
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          this.extendDatenschutz(row.id)
                                        }
                                      >
                                        Zurücksetzen
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                </div>
                              </div>

                              <div className="col-lg-3">
                                <div className="table-block">
                                  <strong>Status</strong>
                                  <br />
                                  <span className="">
                                    Zuletzt bearbeitet von:{" "}
                                    {row.cockpit_lastuser ? (
                                      <React.Fragment>
                                        {row.cockpit_lastuser} <br />
                                      </React.Fragment>
                                    ) : (
                                      <span>-</span>
                                    )}
                                    Zuletzt bearbeitet am:{" "}
                                    {row.cockpit_lastedit ? (
                                      this.getShortDate(row.cockpit_lastedit)
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </span>
                                  <br />
                                  <span className="">
                                    {row.cockpit_tel_angezeigt
                                      ? "Telefonnummer angezeigt"
                                      : "Telefonnummer noch nicht abgerufen"}
                                  </span>
                                  <br />
                                  <a
                                    onClick={() =>
                                      this.handleShowNoteModal(row.id)
                                    }
                                  >
                                    <strong>
                                      <FontAwesomeIcon icon={"sticky-note"} />{" "}
                                      Notiz{" "}
                                      {!row.cockpit_notizen
                                        ? "hinzufügen"
                                        : "bearbeiten"}
                                    </strong>
                                  </a>

                                  {row.eigentuemer_email && (
                                    <React.Fragment>
                                      <a
                                        onClick={() =>
                                          this.handleShowExportModal(row.id)
                                        }
                                      >
                                        <strong>
                                          <FontAwesomeIcon
                                            icon={"chevron-right"}
                                          />{" "}
                                          An CRM übergeben / Auftrag
                                        </strong>
                                      </a>

                                      <a
                                        onClick={() =>
                                          this.handleShowConfirmModal(
                                            row.id,
                                            "interesse"
                                          )
                                        }
                                      >
                                        <strong>
                                          <FontAwesomeIcon
                                            icon={"chevron-right"}
                                          />{" "}
                                          Kein weiteres Interesse
                                        </strong>
                                      </a>

                                      {/* <a TODO wieder einkommentieren
                                        onClick={() =>
                                          this.handleShowMarketingModal(row.id)
                                        }
                                      >
                                        <strong>
                                          <FontAwesomeIcon
                                            icon={'chevron-right'}
                                          />{' '}
                                          Werbeeinwilligung ändern
                                        </strong>
                                      </a> */}

                                      <Modal
                                        show={
                                          this.state.showMarketingModal ===
                                          row.id
                                        }
                                        onHide={this.handleShowMarketingModal}
                                      >
                                        <Modal.Body>
                                          <p>Werbeeinwilligung ändern</p>
                                          <div className="row mb-2 mt-4 justify-content-center">
                                            <div className="col-lg-2">
                                              <span className="float-right">
                                                <FontAwesomeIcon
                                                  icon={"stopwatch"}
                                                  size={"3x"}
                                                />
                                              </span>
                                            </div>
                                            <div className="col-lg-10">
                                              <h3>
                                                Werbeeinwilligung ändern für:
                                              </h3>
                                            </div>
                                          </div>
                                          <div className="row mb-2">
                                            <div className="col-lg-10 offset-lg-2">
                                              <input
                                                type="text"
                                                disabled
                                                className="form-control"
                                                value={
                                                  row.benutzerzustimmung
                                                    ? "Werbeeinwilligung erteilt"
                                                    : "Keine Werbeeinwilligung erteilt"
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="row mb-2">
                                            <div className="col-lg-10 offset-lg-2">
                                              <p>
                                                Der Interessent / Kunde hat
                                                Ihnen die Erlaubnis{" "}
                                                <strong>
                                                  {row.benutzerzustimmung
                                                    ? "erteilt"
                                                    : "nicht erteilt"}
                                                </strong>{" "}
                                                seine Kontaktdaten für
                                                Marketingzwecke zu verwenden.
                                                Durch einen Klick auf "
                                                {row.benutzerzustimmung
                                                  ? "Werbeeinwilligung entziehen"
                                                  : "Werbeeinwilligung erteilen"}
                                                " können Sie diesen Status
                                                ändern.
                                              </p>
                                            </div>
                                          </div>
                                          <div className="row mb-2 justify-content-center">
                                            <div className="col-lg-2">
                                              <span className="float-right">
                                                <FontAwesomeIcon
                                                  icon={"shield-alt"}
                                                  size={"3x"}
                                                />
                                              </span>
                                            </div>
                                            <div className="col-lg-10">
                                              <h3>Datenschutz beachten!</h3>
                                            </div>
                                          </div>
                                          <div className="row mb-2">
                                            <div className="col-lg-10 offset-lg-2">
                                              <p>
                                                Bei dieser Aktion werden
                                                personenbezogene Daten
                                                bearbeitet oder exportiert.
                                                Bitte gehen Sie besonders
                                                sorgfältig vor, um nicht gegen
                                                die aktuellen Vorschriften des
                                                Datenschutzes zu verstoßen.
                                              </p>
                                              <p>
                                                Sprechen Sie im Zweifel Ihren
                                                Datenschutzbeauftragten an.
                                              </p>
                                            </div>
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            backgroundColor: "#d2d2d2"
                                          }}
                                        >
                                          <Button
                                            variant="primary"
                                            onClick={
                                              this.handleShowMarketingModal
                                            }
                                          >
                                            Abbruch
                                          </Button>
                                          <Button
                                            variant="primary"
                                            onClick={() =>
                                              this.handleSaveMarketingSelection(
                                                row.id
                                              )
                                            }
                                          >
                                            {row.benutzerzustimmung
                                              ? "Werbeeinwilligung entziehen"
                                              : "Werbeeinwilligung erteilen"}
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </React.Fragment>
                                  )}

                                  {row.benutzerprotokoll && (
                                    <>
                                      <a
                                        onClick={() =>
                                          this.handleShowProtocolModal(row.id)
                                        }
                                      >
                                        <strong>
                                          <FontAwesomeIcon
                                            icon={"chevron-right"}
                                          />{" "}
                                          Bearbeitungsprotokoll einsehen
                                        </strong>
                                      </a>

                                      <Modal
                                        size="lg"
                                        show={
                                          this.state.showProtocolModal ===
                                          row.id
                                        }
                                        onHide={this.handleShowProtocolModal}
                                      >
                                        <Modal.Body>
                                          <h3>Bearbeitungsprotokoll</h3>
                                          <div className="row mb-2 mt-4 justify-content-center">
                                            <div className="col-lg-12">
                                              {row.benutzerprotokoll
                                                .split("\n")
                                                .map(
                                                  (
                                                    row: any,
                                                    indexBp: number
                                                  ) => (
                                                    <p
                                                      key={
                                                        "benutzerprotokoll" +
                                                        indexBp
                                                      }
                                                    >
                                                      {row}
                                                    </p>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer
                                          style={{
                                            backgroundColor: "#d2d2d2"
                                          }}
                                        >
                                          <Button
                                            variant="primary"
                                            onClick={() =>
                                              this.downloadProtocol(
                                                row.benutzerprotokoll
                                              )
                                            }
                                          >
                                            Download
                                          </Button>
                                          <Button
                                            variant="primary"
                                            onClick={
                                              this.handleShowProtocolModal
                                            }
                                          >
                                            Schließen
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </>
                                  )}
                                </div>
                                <div className="form-group">
                                  <CustomCheckbox
                                    onCheckValue={() =>
                                      this.toggleEigenabruf(row.id, index)
                                    }
                                    label={"Datensatz ist ein interner Abruf"}
                                    checked={row.abrufer === 1 ? true : false}
                                  />
                                </div>

                              </div>


                              {this.state.aboWMAIds.indexOf(row.wma_id) > -1 && Object.keys(this.state.wmaAboToMainIdMap).length !== 0 && this.state.wmaAboToMainIdMap[`${row.wma_id}`] && this.state.wmaMainIdMap[this.state.wmaAboToMainIdMap[`${row.wma_id}`][0]] && (
                                <div style={{
                                  padding: "2em"
                                }}>

                                  <strong>Abrufhistorie dieses Objekts</strong><br />
                                  Haupt-ID: <strong>{this.state.wmaAboToMainIdMap[`${row.wma_id}`][0]}</strong>

                                  <div>
                                    <table
                                      className="table table-condensed analysen-table"
                                      style={{ overflowY: "scroll" }}
                                    >
                                      <thead>
                                      <tr>
                                        <th>WMA</th>
                                        <th onClick={() => this.sortData("wma_id")}>
                                          ID <FontAwesomeIcon icon={"sort"} />
                                        </th>
                                        <th>Tel</th>
                                        <th>Abrufdatum</th>
                                        <th onClick={() => this.sortData("objekt_plz")}>
                                          Richtwert
                                        </th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {(this.state.abosForCurrentRow.length === 0 && this.state.loading) ? 'Lade WMAs...' : (<>
                                      {this.state.abosForCurrentRow.sort((a,b) => this.compareDates(a.annahmedatum, b.annahmedatum)).map(aboWma => (
                                        <tr>
                                          <td>
                                            {aboWma.result1_downloadlink ? (
                                              <a
                                                onClick={() =>
                                                  window.open(aboWma.result1_downloadlink, "_blank")
                                                }
                                                target="_blank"
                                              >
                                                <FontAwesomeIcon icon={"download"} />
                                              </a>
                                            ) : (
                                              <FontAwesomeIcon icon={"times"} />
                                            )}
                                          </td>
                                          <td>
                                            {aboWma.id}
                                          </td>
                                          <td>
                                            {aboWma.eigentuemer_telefon ? (
                                              <FontAwesomeIcon icon={"phone"} />
                                            ) : (
                                              <FontAwesomeIcon icon={"times"} />
                                            )}
                                          </td>
                                          <td>
                                            {aboWma.annahmedatum ? (
                                              this.getDate(aboWma.annahmedatum)
                                            ) : (
                                              <span>-</span>
                                            )}
                                          </td>
                                          <td>
                                            {aboWma.richtwert ? (
                                              Math.round(aboWma.richtwert)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
                                            ) : (
                                              <span>-</span>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                      </>
                                      )}
                                      </tbody>
                                    </table>
                                  </div>



                                </div>
                              )}


                              {/* <div className="col-lg-2">
                                <div className="table-block">
                                   <strong>Notizen</strong>
                                         <br />
                                       <textarea
                                           value={
                                             row.cockpit_notizen
                                               ? row.cockpit_notizen
                                               : ''
                                           }
                                           onChange={e =>
                                             this.saveNotes(e, row.id, index)
                                           }
                                           className="form-control"
                                         />
                                  <br></br>
                                  <strong>Wiedervorlage</strong>
                                  <DatePicker
                                    className="form-control"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={5}
                                    popperPlacement="left-start"
                                    popperModifiers={{
                                      offset: {
                                        enabled: true,
                                        offset: "5px, 10px"
                                      }
                                    }}
                                    selected={
                                      row.cockpit_wiedervorlage
                                        ? new Date(row.cockpit_wiedervorlage)
                                        : null
                                    }
                                    onChange={e =>
                                      this.handleChangeWiedervorlage(e, row.id)
                                    }
                                    dateFormat="dd.MM.yyyy HH:mm"
                                  />
                                  <br />
                                  <strong>Besichtigung am</strong>
                                  <DatePicker
                                    className="form-control"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={5}
                                    popperPlacement="left-start"
                                    popperModifiers={{
                                      offset: {
                                        enabled: true,
                                        offset: "5px, 10px"
                                      }
                                    }}
                                    selected={
                                      row.eigentuemer_termin
                                        ? new Date(row.eigentuemer_termin)
                                        : null
                                    }
                                    onChange={e =>
                                      this.handleChangeTermin(e, row.id)
                                    }
                                    dateFormat="dd.MM.yyyy HH:mm"
                                  />

                                </div>
                              </div> */}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
              )}

              {this.state.searchedData.length === 0 &&
                this.state.tableData.length !== 0 && (
                  <tr>
                    <td colSpan={100}>
                      Ihre Suche ergab leider keinen Treffer
                    </td>
                  </tr>
                )}
              {this.state.tableData.length === 0 && (
                <tr>
                  <td colSpan={100}>Keine Einträge vorhanden</td>
                </tr>
              )}
            </tbody>
          </table>
          {this.state.searchedData.length !== 0 &&
            this.state.offset < this.state.searchedData.length && (
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState((prevState: any) => ({
                      offset: prevState.offset + 20
                    }));
                  }}
                >
                  Lade weitere
                </button>
              </div>
            )}
        </div>
      </React.Fragment>
    );
  }
}
