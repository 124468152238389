import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AsyncSelect from 'react-select/async';

import { API } from '../services/API';
import { Alert } from '../services/alert';
import { Box } from './Box';
import ReactTooltip from 'react-tooltip';

interface State {
  startDate: Date;
  endDate: Date;
  kunden: Array<any>;
  partner: Array<any>;
  selectedPartnerId: number | string;
  selectedKundeId: number | string;
  zeitraum: string;
  loading: boolean;
  showPartners: boolean;
}

interface Props {
  onButtonClicked: any;
  startDate: any;
  endDate: any;
  selectedKundeId: string | number;
  selectedPartnerId: string | number;
  startseitenauswertung: any;
}

export class StatsFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPartners: false,
      startDate: new Date(),
      loading: true,
      endDate: new Date(),
      kunden: [],
      partner: [],
      selectedKundeId: '',
      selectedPartnerId: '',
      zeitraum: '',
    };

    this.loadKunden = this.loadKunden.bind(this);
    this.loadPartner = this.loadPartner.bind(this);
    this.handleChangeKunde = this.handleChangeKunde.bind(this);
    this.handleChangePartner = this.handleChangePartner.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.refresh = this.refresh.bind(this);
    this.handleChangeZeitraum = this.handleChangeZeitraum.bind(this);
    this.preselectDate = this.preselectDate.bind(this);
    this.getShowPartners = this.getShowPartners.bind(this);
  }

  componentDidMount() {
    let moment = require('moment');
    this.setState({
      startDate: moment(this.props.startDate).isValid()
        ? new Date(this.props.startDate)
        : new Date(),
      endDate: moment(this.props.endDate).isValid()
        ? new Date(this.props.endDate)
        : new Date(),
      selectedKundeId: this.props.selectedKundeId,
      selectedPartnerId: this.props.selectedPartnerId,
    });
    this.getShowPartners();
    this.loadKunden();
    this.loadPartner();
  }

  // componentDidUpdate(oldProps: any) {
  //   if (
  //     this.state.startDate !== this.props.startDate ||
  //     this.state.endDate !== this.props.endDate ||
  //     this.state.selectedKundeId !== this.props.selectedKundeId ||
  //     this.state.selectedPartnerId !== this.props.selectedPartnerId
  //   ) {
  //     this.setState({
  //       startDate: this.props.startDate,
  //       endDate: this.props.endDate,
  //       selectedKundeId: this.props.selectedKundeId,
  //       selectedPartnerId: this.props.selectedPartnerId,
  //     });
  //   }
  // }

  preselectDate(range: string) {
    let firstDay = new Date();
    let lastDay = new Date();
    var date = new Date();
    if (range === 'thisYear') {
      firstDay = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0);
      lastDay = new Date(new Date().getFullYear(), 11, 31, 0, 0, 0, 0);
    }
    if (range === 'thisMonth') {
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    if (range === 'thisWeek') {
      var first = date.getDate() - date.getDay() + 1;
      var last = first + 6;
      firstDay = new Date(date.setDate(first));
      lastDay = new Date(date.setDate(last));
    }
    if (range === 'lastYear') {
      firstDay = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0, 0);
      lastDay = new Date(new Date().getFullYear() - 1, 11, 31, 0, 0, 0, 0);
    }
    if (range === 'lastMonth') {
      firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    }
    if (range === 'lastWeek') {
      var first = date.getDate() - date.getDay() - 6;
      var last = first + 6;
      firstDay = new Date(date.setDate(first));
      lastDay = new Date(date.setDate(last));
    }

    this.setState({
      startDate: firstDay,
      endDate: lastDay,
    });
  }

  handleChangeZeitraum(event: any) {
    this.setState({
      zeitraum: event.target.value,
    });

    this.preselectDate(event.target.value);
  }

  getShowPartners() {
    API.get('API/auswertungskonfiguration/hatpartner')
      .then((a: any) => {
        this.setState({ showPartners: a });
      })
      .catch(e => {});
  }

  asyncLoadKunden = (inputValue: any, callback: any) => {
    // setTimeout(() => {
    API.get(
      `API/auswertungskonfiguration/newautocomplete?property=auswertungskonfiguration.kunde&searchfor=${inputValue}`,
    )
      .then((a: any) => {
        let kunden = [];
        for (let index = 0; index < a.length; index++) {
          kunden.push({ value: a[index].id, label: a[index].firma });
        }

        callback(kunden);
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Kunden aufgetreten');
      });
    // }, 1000);
  };

  loadKunden() {
    API.get(
      `API/auswertungskonfiguration/newautocomplete?property=auswertungskonfiguration.kunde&searchfor=`,
    )
      .then((a: any) => {
        let kunden = [];
        for (let index = 0; index < a.length; index++) {
          kunden.push({ value: a[index].id, label: a[index].firma });
        }

        this.setState({
          kunden, //: a,
          loading: false,
        });
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Kunden aufgetreten');
        this.setState({ loading: false });
      });
  }

  asyncLoadPartner = (inputValue: any, callback: any) => {
    API.get(
      `API/auswertungskonfiguration/newautocomplete?property=auswertungskonfiguration.partner&searchfor=${inputValue}`,
    )
      .then((a: any) => {
        let partner = [];
        for (let index = 0; index < a.length; index++) {
          partner.push({ value: a[index].id, label: a[index].firma });
        }

        callback(partner);
      })
      .catch(e => {
        Alert.error('Fehler beim Laden der Partner aufgetreten');
      });
  };

  loadPartner() {
    API.get(`API/auswertungskonfiguration/hatpartner`).then((success: any) => {
      if (success === true) {
        API.get(
          `API/auswertungskonfiguration/newautocomplete?property=auswertungskonfiguration.partner&searchfor=`,
        )
          .then((a: any) => {
            let partner = [];
            for (let index = 0; index < a.length; index++) {
              partner.push({
                value: a[index].id,
                label: a[index].firma,
              });
            }
            this.setState({
              partner, //:a,
            });
          })
          .catch(e => {
            Alert.error('Fehler beim Laden der Partner aufgetreten');
          });
      }
    });
  }

  handleChangeKunde(event: any) {
    let value = null;
    if (event && event.value) value = event.value;
    this.setState({
      selectedKundeId: value, //event.target.value,
    });
  }

  handleChangePartner(event: any) {
    let value = null;
    if (event && event.value) value = event.value;
    this.setState({
      selectedPartnerId: value, //event.target.value,
    });
  }

  handleChangeStartDate(date: any) {
    this.setState({
      startDate: date,
    });
  }
  handleChangeEndDate(date: any) {
    this.setState({
      endDate: date,
    });
  }

  refresh() {
    this.setState({ loading: true });
    let data = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      selectedKundeId: this.state.selectedKundeId,
      selectedPartnerId: this.state.selectedPartnerId,
    };
    this.props.onButtonClicked(data);
  }

  customStylesSearchableSelect = {
    control: (base: any, state: any) => ({
      ...base,
      height: '2.05rem',
      minHeight: '2.05rem',
      borderColor: '#e5e6e7',
    }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      color: '#000',
    }),
  };

  render() {
    return (
      <div id="statsFilter">
        <div className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>Kunde</label>
              <AsyncSelect
                styles={this.customStylesSearchableSelect}
                isClearable={true}
                placeholder="Bitte Kunden wählen"
                defaultValue="Bitte Kunden wählen"
                loadOptions={this.asyncLoadKunden}
                value={
                  this.state.selectedKundeId
                    ? this.state.kunden.find(
                        kunde => kunde.value === this.state.selectedKundeId,
                      )
                    : this.props.startseitenauswertung.kunde &&
                      this.props.startseitenauswertung.kunde.id
                    ? {
                        value: this.props.startseitenauswertung.kunde.id,
                        label: this.props.startseitenauswertung.kunde.firma,
                      }
                    : null
                }
                noOptionsMessage={() => 'Kein Kunde gefunden'}
                isSearchable={true}
                defaultOptions={this.state.kunden}
                onChange={this.handleChangeKunde}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>Zeitraum</label>
              <select
                className="form-control"
                value={this.state.zeitraum}
                onChange={this.handleChangeZeitraum}
              >
                <option value="" disabled>
                  Bitte wählen
                </option>
                <option value="thisYear">Dieses Jahr</option>
                <option value="thisMonth">Dieser Monat</option>
                <option value="thisWeek">Diese Woche</option>
                <option value="lastYear">Letztes Jahr</option>
                <option value="lastMonth">Letzter Monat</option>
                <option value="lastWeek">Letzte Woche</option>
              </select>
            </div>
          </div>
          <div className="col-lg-4">
            <span
              data-tip={`Legen Sie hier den Zeitraum fest, den Sie auswerten möchten.<br/>
Hierzu können Sie unter ‚Zeitraum‘ eine voreingestellte Zeitspanne (1 Woche, 1 Monat,...) auswählen,<br/> oder in den Eingabefeldern ‚Von‘ ‚Bis‘ einen individuellen Zeitraum eingeben.<br/>
Die Änderung hat Auswirkungen auf alle Grafiken dieser Seite.`}
              className="float-right"
            >
              <FontAwesomeIcon icon={'info-circle'} />
              <ReactTooltip multiline />
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            {this.state.showPartners && (
              <div className="form-group">
                <label>Partner</label>
                <AsyncSelect
                  styles={this.customStylesSearchableSelect}
                  isClearable={true}
                  placeholder="Bitte Partner wählen"
                  defaultValue="Bitte Partner wählen"
                  loadOptions={this.asyncLoadPartner}
                  noOptionsMessage={() => 'Kein Partner gefunden'}
                  value={
                    // this.state.selectedPartnerId
                    //   ? this.state.partner.find(
                    //       partner =>
                    //         partner.value === this.state.selectedPartnerId,
                    //     )
                    //   : null

                    this.state.selectedPartnerId
                      ? this.state.partner.find(
                          partner =>
                            partner.value === this.state.selectedPartnerId,
                        )
                        ? this.state.partner.find(
                            partner =>
                              partner.value === this.state.selectedPartnerId,
                          )
                        : this.props.startseitenauswertung.partner.id
                        ? {
                            value: this.props.startseitenauswertung.partner.id,
                            label: this.props.startseitenauswertung.partner
                              .firma,
                          }
                        : null
                      : null
                  }
                  isSearchable={true}
                  defaultOptions={this.state.partner}
                  onChange={this.handleChangePartner}
                />
              </div>
            )}
          </div>
          <div className="col-lg-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Von</label>
                  <br></br>
                  <DatePicker
                    className="form-control"
                    selected={this.state.startDate}
                    onChange={this.handleChangeStartDate}
                    dateFormat="dd.MM.yyyy"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Bis</label>
                  <br></br>
                  <DatePicker
                    className="form-control"
                    selected={this.state.endDate}
                    onChange={this.handleChangeEndDate}
                    dateFormat="dd.MM.yyyy"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 offset-lg-1">
            <button
              style={{ marginTop: '26px' }}
              className="btn btn-primary btn-block"
              onClick={this.refresh}
              disabled={this.state.loading}
            >
              {this.state.loading && (
                <span>
                  <FontAwesomeIcon icon="spinner" spin />{' '}
                </span>
              )}
              Statistiken aktualisieren
            </button>
          </div>
        </div>
      </div>
    );
  }
}
