import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from './utils/react-auth0-spa';
import history from './utils/history';
import getEnv from './utils/getEnv';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any): void => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={getEnv('REACT_APP_AUTH_DOMAIN')}
      client_id={getEnv('REACT_APP_AUTH_CLIENT_ID')}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback as any}
      audience={getEnv('REACT_APP_AUTH_AUDIENCE')}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
