import * as React from 'react';
import { Layout } from './Layout';
import { Loading } from './Loading';

import { API } from '../services/API';

import { Alert } from '../services/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  match?: any;
  phaseData: any;
  phaseNumber: number;
  phaseTitle: string;
}

interface State {
  loading: boolean;
  showDetailsPhase: null | number;
  showSubtasksOfPhase: null | number;
  showSubDetailsPhase: any;
}

export class OnboardingColumn extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,

      showDetailsPhase: null,
      showSubtasksOfPhase: null,
      showSubDetailsPhase: {},
    };

    this.handleShowDetailsPhase = this.handleShowDetailsPhase.bind(this);
    this.handleShowSubtasks = this.handleShowSubtasks.bind(this);
    this.handleShowSubDetailsPhase = this.handleShowSubDetailsPhase.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  handleShowDetailsPhase(id: number) {
    let value = null;
    if (this.state.showDetailsPhase !== id) value = id;
    this.setState({
      showDetailsPhase: value,
    });
  }

  handleShowSubtasks(id: number) {
    let value = null;
    if (this.state.showSubtasksOfPhase !== id) value = id;
    this.setState({
      showSubtasksOfPhase: value,
    });
  }

  handleShowSubDetailsPhase(index: number, subindex: number) {
    let value = null;
    let parent = null;
    if (this.state.showSubDetailsPhase[index] !== subindex) {
      value = subindex;
      parent = index as any;
    }
    this.setState({
      showSubDetailsPhase: { [parent]: value },
    });
  }

  render() {
    if (this.state.loading) return <Loading />;

    return (
            <div className="ibox">
              <div className="ibox-content">
                <h3>
                  <span className="text-muted">Phase {this.props.phaseNumber} // </span>
                  <span>{this.props.phaseTitle}</span>
                </h3>

                <ul className="sortable-list connectList agile-list">
                  {this.props.phaseData.map((entry: any, index: number) => (
                    <div key={index+"column"+this.props.phaseTitle} >
                      <li className="warning-element">
                        <strong>{entry.label}</strong> { (entry.subtasks && entry.subtasks.length>0) && <span className="badge badge-danger float-right" onClick={() => this.handleShowSubtasks(index)}>{entry.subtasks.length} Unteraufgaben <span
                            >
                              {this.state.showSubtasksOfPhase !== index ? (
                                  <FontAwesomeIcon icon="chevron-right" />
                              ) : (
                                    <FontAwesomeIcon icon="chevron-down" />
                              )}
                            </span></span>}
                        <div className="agile-detail">
                          <div className="row">
                            <span
                              className="col-4"
                              onClick={() => this.handleShowDetailsPhase(index)}
                            >
                              {this.state.showDetailsPhase !== index ? (
                                entry.text !=="" && (<span>
                                  Zeige Details{' '}
                                  <FontAwesomeIcon icon="chevron-right" />
                                </span>)
                              ) : (
                                <React.Fragment>
                                  <span>
                                    Verberge Details{' '}
                                    <FontAwesomeIcon icon="chevron-down" />
                                  </span>
                                </React.Fragment>
                              )}
                            </span>
                            <div className="col-8">
                              {entry.tags.map((tag: any) => (
                                <span
                                  key={tag+"subrow"+this.props.phaseTitle}
                                  className="float-right badge ml-1 mt-1"
                                >
                                  {tag}
                                </span>
                              ))}
                            </div>
                          </div>
                          {this.state.showDetailsPhase === index && (
                            <div className="row mt-4">
                              <div className="col-12">
                                <span className="mt-2">{entry.text}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                      {this.state.showSubtasksOfPhase === index&&  entry &&
                        typeof (entry.subtasks !== 'undefined') &&
                        entry.subtasks &&
                        entry.subtasks.map(
                          (subentry: any, subindex: number) => (
                            <li
                              key={index + '' + subindex+this.props.phaseTitle}
                              className="warning-element ml-4"
                            >
                              <strong>{subentry.label}</strong>
                              <div className="agile-detail">
                                <div className="row">
                                  <span
                                    className="col-6"
                                    onClick={() =>
                                      this.handleShowSubDetailsPhase(
                                        index,
                                        subindex,
                                      )
                                    }
                                  >
                                    {this.state.showSubDetailsPhase[index] !==
                                    subindex ? (
                                      <span>
                                        Zeige Details{' '}
                                        <FontAwesomeIcon icon="chevron-right" />
                                      </span>
                                    ) : (
                                      <span>
                                        Verberge Details{' '}
                                        <FontAwesomeIcon icon="chevron-down" />
                                      </span>
                                    )}
                                  </span>
                                  <div className="col-6">
                                    {subentry.tags.map((tag: any, index:number) => (
                                      <span key={"column"+tag+""+this.props.phaseTitle+index} className="float-right badge ml-1 mt-1">
                                        {tag}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                                {this.state.showSubDetailsPhase[index] ===
                                  subindex && (
                                  <div className="row mt-4">
                                    <div className="col-12">
                                      <span className="mt-2">
                                        {subentry.text}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>
                          ),
                        )}
                    </div>
                  ))}
                </ul>
              </div>
            </div>
    );
  }
}
