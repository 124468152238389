import * as React from 'react';
import { API } from '../../services/API';

interface State {
  loading: boolean;
  maxCount: number;
}

interface Props {
  motivaufteilung: any;
}

export class MotivWidget extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      maxCount: 0,
    };
    this.getBiggestAmount = this.getBiggestAmount.bind(this);
  }

  componentDidMount() {
    this.getBiggestAmount();
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.motivaufteilung !== prevProps.motivaufteilung)
      this.getBiggestAmount();
  }

  getBiggestAmount() {
    let maxCount = 0;
    for (let index = 0; index < this.props.motivaufteilung.length; index++) {
      if (
        this.props.motivaufteilung[index].anzahl > maxCount &&
        index < this.props.motivaufteilung.length - 1
      )
        maxCount = this.props.motivaufteilung[index].anzahl;
    }
    this.setState({
      maxCount,
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.motivaufteilung.length === 0 && (
          <p>Lade Diagramm-Daten...</p>
        )}
        {this.props.motivaufteilung.map((motiv: any) =>
          motiv.ziel !== 'gesamt' ? (
            <div className="row mb-2" key={motiv.ziel}>
              <div className="col-lg-1">
                <strong>{motiv.anzahl}</strong>
              </div>
              <div className="col-lg-2">
                <span className="text-muted">
                  {this.props.motivaufteilung[
                    this.props.motivaufteilung.length - 1
                  ].anzahl !== 0
                    ? Math.round(
                        (motiv.anzahl /
                          this.props.motivaufteilung[
                            this.props.motivaufteilung.length - 1
                          ].anzahl) *
                          100,
                      ) + '%'
                    : '0%'}
                </span>
              </div>
              <div className="col-lg-8">
                {motiv.zieltext}
                <div className="progress progress-mini">
                  <div
                    style={{
                      width: isNaN((motiv.anzahl / this.state.maxCount) * 100)
                        ? 0
                        : (motiv.anzahl / this.state.maxCount) * 100 + '%',
                    }}
                    className="progress-bar"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="row" key={motiv.ziel}>
              <div className="col-lg-3">
                {/* <strong>{motiv.anzahl} gesamt</strong> */}
              </div>
            </div>
          ),
        )}
      </React.Fragment>
    );
  }
}
