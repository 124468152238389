export default class ClientError extends Error {

    response: any;

    constructor(response: any) {
        super('Client error');
        this.name = "ClientError";
        this.response = response;
    }

}