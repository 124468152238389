import * as React from "react";
import { API } from "../../services/API";

interface State {
  loading: boolean;
  vertragstypenauswertung: any;
}

interface Props {
  vertragstypenauswertung: Array<any>;
  abrufaufteilung: any;
  startDate: any;
  endDate: any;
}

export class AbrufeWidget extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      vertragstypenauswertung: []
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: any) {
    if (
      this.props.vertragstypenauswertung !==
        prevProps.vertragstypenauswertung &&
      this.props.vertragstypenauswertung.length > 0
    ) {
      //sort in right order

      let vertragstypenauswertung = [];
      if (
        this.props.vertragstypenauswertung.filter(
          cls => cls.bezeichnung.includes("XS").length === 1
        )
      ) {
        let xs = this.props.vertragstypenauswertung.filter(cls =>
          cls.bezeichnung.includes("XS")
        );
        vertragstypenauswertung.push(xs[0]);
      }

      if (
        this.props.vertragstypenauswertung.filter(
          cls => cls.bezeichnung.includes(" S").length === 1
        )
      ) {
        let s = this.props.vertragstypenauswertung.filter(cls =>
          cls.bezeichnung.includes(" S")
        );
        vertragstypenauswertung.push(s[0]);
      }

      if (
        this.props.vertragstypenauswertung.filter(
          cls => cls.bezeichnung.includes(" M").length === 1
        )
      ) {
        let m = this.props.vertragstypenauswertung.filter(cls =>
          cls.bezeichnung.includes(" M")
        );
        vertragstypenauswertung.push(m[0]);
      }

      if (
        this.props.vertragstypenauswertung.filter(
          cls => cls.bezeichnung.includes(" L").length === 1
        )
      ) {
        let l = this.props.vertragstypenauswertung.filter(cls =>
          cls.bezeichnung.includes(" L")
        );
        vertragstypenauswertung.push(l[0]);
      }

      if (
        this.props.vertragstypenauswertung.filter(
          cls => cls.bezeichnung.includes("XL").length === 1
        )
      ) {
        let xl = this.props.vertragstypenauswertung.filter(cls =>
          cls.bezeichnung.includes("XL")
        );
        vertragstypenauswertung.push(xl[0]);
      }

      this.setState({
        loading: false,
        vertragstypenauswertung
      });
    }
  }

  getDate(date: any) {
    let moment = require("moment");
    return moment(date).format("DD.MM.YYYY");
  }

  render() {
    return (
      <React.Fragment>
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col-4">
            <span
              className="float-right"
              style={{ fontSize: "35px", fontWeight: 10 }}
            >
              {this.props.abrufaufteilung &&
                this.props.abrufaufteilung.gesamt &&
                this.props.abrufaufteilung.gesamt.anzahl &&
                this.props.abrufaufteilung.gesamt.anzahl}
            </span>
          </div>
          <div className="col-8">
            <span className="float-left">
              WMAs vom {this.getDate(this.props.startDate)} bis{" "}
              {this.getDate(this.props.endDate)} (IST)
            </span>
          </div>
        </div>
        <div className="mt-5">
          <strong>Benchmark / Vergleich</strong>
          {this.state.loading && <p className="mt-4">Lade Daten...</p>}
          {!this.state.loading && (
            <p className="mt-2">
              Durchschnittliche Wohnmarktanalyse-Abrufe pro Bankenkategorie im
              gleichen Zeitraum.
            </p>
          )}

          {this.state.vertragstypenauswertung &&
            this.state.vertragstypenauswertung.map(
              (typ: any, index: number) => (
                <div key={"abrufe" + typ.bezeichnung + index}>
                  <div
                    className="row"
                    style={index === 0 ? { marginTop: "20px" } : {}}
                  >
                    <div className="col-7">
                      <span
                        className={
                          !typ.vergleichskategorie
                            ? "text-muted"
                            : "font-weight-bold"
                        }
                      >
                        {typ.bezeichnung}{" "}
                        {typ.kundenanzahl ? "(" + typ.kundenanzahl + ")" : ""}
                      </span>
                    </div>
                    <div className="col-5">
                      <span
                        className="float-right"
                        style={{ fontSize: "21px", fontWeight: 10 }}
                      >
                        {typ.mittelwert}
                      </span>
                    </div>
                  </div>
                  {index < this.state.vertragstypenauswertung.length - 1 && (
                    <hr></hr>
                  )}
                </div>
              )
            )}
        </div>
      </React.Fragment>
    );
  }
}
