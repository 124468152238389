import * as React from "react";
import {Layout} from "../components/Layout";

export class Error404 extends React.Component<{}, {}> {
    render() {
        return (
            <Layout title='Seite nicht gefunden'>

                Unter dieser Adresse wurde leider keine Seite gefunden.

            </Layout>
        )
    }
}