import * as React from 'react';

import Highcharts from 'highcharts';
import PieChart from 'highcharts-react-official';
import { API } from '../services/API';
import Config from '../services/config';

require('highcharts/modules/exporting')(Highcharts);

interface State {
  doughnutData: any;
  loading: boolean;
  chartDataLength: number;
}

interface Props {
  dataType: string;
  uuid: string;
}

export class DoughnutChart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      chartDataLength: 0,
      doughnutData: [],
    };
  }

  highlightElement(ele: any) {
    //console.log(ele);
  }

  componentDidMount() {
    this.loadChartData();
  }

  componentDidUpdate() {
    // this.loadChartData();
  }

  async loadChartData() {
    if (this.props.dataType === 'overview') {
      const chartData = await API.get(
        `API/auswertungskonfiguration/${this.props.uuid}/abrufaufteilung`,
      );

      if (chartData.gesamt.anzahl !== 0) {
        this.setState({
          doughnutData: [
            [Config.getOfferVariantLabelByType(1), chartData[1].anzahl],
            [Config.getOfferVariantLabelByType(2), chartData[2].anzahl],
            [Config.getOfferVariantLabelByType(3), chartData[3].anzahl],
            [Config.getOfferVariantLabelByType(4), chartData[4].anzahl],
          ],
        });
      }
      this.setState({
        chartDataLength: chartData.gesamt.anzahl,
        loading: false,
      });
    }

    if (this.props.dataType === 'motiv') {
      const chartData = await API.get(
        `API/auswertungskonfiguration/${this.props.uuid}/motivaufteilung`,
      );

      if (chartData.gesamt.anzahl !== 0) {
        this.setState({
          doughnutData: [
            [chartData[0].zieltext, chartData[0].anzahl],
            [chartData[1].zieltext, chartData[1].anzahl],
            [chartData[2].zieltext, chartData[2].anzahl],
            [chartData[3].zieltext, chartData[3].anzahl],
            [chartData[4].zieltext, chartData[4].anzahl],
            [chartData[5].zieltext, chartData[5].anzahl],
            [chartData[6].zieltext, chartData[6].anzahl],
            [chartData[7].zieltext, chartData[7].anzahl],
            [chartData[8].zieltext, chartData[8].anzahl],
          ],
        });
      }
      this.setState({
        chartDataLength: chartData.gesamt.anzahl,
        loading: false,
      });
    }
  }

  render() {
    let doughnutData = {
      title: {
        text: '',
      },
      legend: {
        labelFormatter: function() {
          return this.name + ` (${this.y})`;
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },

      series: [
        {
          type: 'pie',
          name: 'Anzahl',
          data: this.state.doughnutData,
        },
      ],
      colors: [
        '#FFD44B',
        '#4F81BD',
        '#F79646',
        '#64BD63',
        '#9B0F10',
        '#E8743B',
        '#A34534',
        '#58508d',
        '#945ECF',
        '#13A4B4',
        '#6C8893',
        '#2F6497',
        '#a1dbb1',
        '#ED4A7B',
      ],
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
            ],
          },
        },
      },
    } as any;

    return (
      <React.Fragment>
        {this.state.chartDataLength === 0 && !this.state.loading && (
          <p>Keine Daten vorhanden</p>
        )}
        {this.state.loading && <p>Lade Diagramm-Daten...</p>}
        <div
          style={{
            height:
              this.state.chartDataLength !== 0 && !this.state.loading
                ? '100%'
                : 400,
          }}
        >
          {this.state.chartDataLength !== 0 && !this.state.loading && (
            <React.Fragment>
              <PieChart highcharts={Highcharts} options={doughnutData} />
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <span
                  className="align-middle"
                  style={{ fontSize: '28px', fontWeight: 10 }}
                >
                  {this.state.chartDataLength} Wohnmarktanalysen
                </span>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
