import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface State {}

interface Props {
  label: string;
  checked: boolean;
  onCheckValue: any;
}

export class CustomCheckbox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.props.onCheckValue(this.props.label);
  }

  render() {
    return (
      <div onClick={() => this.handleToggle()}>
        {!this.props.checked ? (
          <FontAwesomeIcon icon={'square-full'} />
        ) : (
          <FontAwesomeIcon icon={'check-square'} />
        )}
        <span style={{ marginLeft: '1rem' }}>{this.props.label}</span>
      </div>
    );
  }
}
