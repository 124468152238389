import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomCheckbox } from './CustomCheckbox';
import { API } from '../services/API';

interface State {
  selectedDach: string | number;
  selectedDaemmung: string | number;
  selectedFenster: string | number;
  selectedHeizung: string | number;
  selectedFussboden: string | number;
  selectedInnenwaende: string | number;
  selectedBad: string | number;
  selectedElektro: string | number;
  personenbezogeneDaten: boolean;
  mod_badData: any;
  mod_dacherneuerungData: any;
  mod_aussenwaendeData: any;
  mod_innenwaende_und_tuerenData: any;
  mod_heizungsanlageData: any;
  mod_fussboedenData: any;
  mod_fenster_und_aussentuerenData: any;
  mod_elektroinstallationenData: any;
}

interface Props {
  onNewFilterSelection: any;
}

export class TableKampagnenFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedDach: '',
      selectedDaemmung: '',
      selectedFenster: '',
      selectedHeizung: '',
      selectedFussboden: '',
      selectedInnenwaende: '',
      selectedBad: '',
      selectedElektro: '',
      personenbezogeneDaten: false,
      mod_badData: {},
      mod_dacherneuerungData: {},
      mod_aussenwaendeData: {},
      mod_innenwaende_und_tuerenData: {},
      mod_heizungsanlageData: {},
      mod_fussboedenData: {},
      mod_fenster_und_aussentuerenData: {},
      mod_elektroinstallationenData: {},
    };

    // this.handlePersonenbezogeneDaten = this.handlePersonenbezogeneDaten.bind(
    //   this,
    // );
    this.handleChangeDach = this.handleChangeDach.bind(this);
    this.handleChangeDaemmung = this.handleChangeDaemmung.bind(this);
    this.handleChangeFenster = this.handleChangeFenster.bind(this);
    this.handleChangeHeizung = this.handleChangeHeizung.bind(this);
    this.handleChangeFussboden = this.handleChangeFussboden.bind(this);
    this.handleChangeInnenwaende = this.handleChangeInnenwaende.bind(this);
    this.handleChangeBad = this.handleChangeBad.bind(this);
    this.handleChangeElektro = this.handleChangeElektro.bind(this);
  }

  // handlePersonenbezogeneDaten() {
  //   this.setState(prevState => ({
  //     personenbezogeneDaten: !prevState.personenbezogeneDaten,
  //   }));
  //   this.refresh();
  // }

  handleChangeDach(event: any) {
    this.setState({
      selectedDach: event.target.value,
    });
    this.refresh('dach', event.target.value);
  }

  handleChangeDaemmung(event: any) {
    this.setState({
      selectedDaemmung: event.target.value,
    });
    this.refresh('daemmung', event.target.value);
  }

  handleChangeFenster(event: any) {
    this.setState({
      selectedFenster: event.target.value,
    });
    this.refresh('fenster', event.target.value);
  }

  handleChangeHeizung(event: any) {
    this.setState({
      selectedHeizung: event.target.value,
    });
    this.refresh('heizung', event.target.value);
  }

  handleChangeFussboden(event: any) {
    this.setState({
      selectedFussboden: event.target.value,
    });
    this.refresh('fussboden', event.target.value);
  }

  handleChangeInnenwaende(event: any) {
    this.setState({
      selectedInnenwaende: event.target.value,
    });
    this.refresh('innenwaende', event.target.value);
  }

  handleChangeBad(event: any) {
    this.setState({
      selectedBad: event.target.value,
    });
    this.refresh('bad', event.target.value);
  }

  handleChangeElektro(event: any) {
    this.setState({
      selectedElektro: event.target.value,
    });
    this.refresh('elektro', event.target.value);
  }

  refresh(name: string, value: any) {
    let data = {
      elektro: name === 'elektro' ? value : this.state.selectedElektro,
      bad: name === 'bad' ? value : this.state.selectedBad,
      innenwaende:
        name === 'innenwaende' ? value : this.state.selectedInnenwaende,
      fussboden: name === 'fussboden' ? value : this.state.selectedFussboden,
      heizung: name === 'heizung' ? value : this.state.selectedHeizung,
      fenster: name === 'fenster' ? value : this.state.selectedFenster,
      daemmung: name === 'daemmung' ? value : this.state.selectedDaemmung,
      dach: name === 'dach' ? value : this.state.selectedDach,
    };
    this.props.onNewFilterSelection(data);
  }

  componentDidMount() {
    this.getFilters();
  }

  getFilters() {
    API.get(
      'API/auswertungskonfiguration/newchoices?property=auswertungskonfiguration.mod_bad',
    ).then(s => {
      this.setState({
        mod_badData: s,
      });
    });

    API.get(
      'API/auswertungskonfiguration/newchoices?property=auswertungskonfiguration.mod_dacherneuerung',
    ).then(s => {
      this.setState({
        mod_dacherneuerungData: s,
      });
    });

    API.get(
      'API/auswertungskonfiguration/newchoices?property=auswertungskonfiguration.mod_elektroinstallationen',
    ).then(s => {
      this.setState({
        mod_elektroinstallationenData: s,
      });
    });

    API.get(
      'API/auswertungskonfiguration/newchoices?property=auswertungskonfiguration.mod_fenster_und_aussentueren',
    ).then(s => {
      this.setState({
        mod_fenster_und_aussentuerenData: s,
      });
    });

    API.get(
      'API/auswertungskonfiguration/newchoices?property=auswertungskonfiguration.mod_fussboeden',
    ).then(s => {
      this.setState({
        mod_fussboedenData: s,
      });
    });

    API.get(
      'API/auswertungskonfiguration/newchoices?property=auswertungskonfiguration.mod_heizungsanlage',
    ).then(s => {
      this.setState({
        mod_heizungsanlageData: s,
      });
    });

    API.get(
      'API/auswertungskonfiguration/newchoices?property=auswertungskonfiguration.mod_innenwaende_und_tueren',
    ).then(s => {
      this.setState({
        mod_innenwaende_und_tuerenData: s,
      });
    });

    API.get(
      'API/auswertungskonfiguration/newchoices?property=auswertungskonfiguration.mod_aussenwaende',
    ).then(s => {
      this.setState({
        mod_aussenwaendeData: s,
      });
    });

    //initial values
    API.get(`API/auswertungskonfiguration/kampagnenfilter`).then((a: any) => {
      this.setState({
        selectedDach: a.mod_dacherneuerung,
        selectedBad: a.mod_bad,
        selectedDaemmung: a.mod_aussenwaende,
        selectedElektro: a.mod_elektroinstallationen,
        selectedFenster: a.mod_fenster_und_aussentueren,
        selectedFussboden: a.mod_fussboeden,
        selectedHeizung: a.mod_heizungsanlage,
        selectedInnenwaende: a.mod_innenwaende_und_tueren,
      });
    });
  }

  render() {
    return (
      <div className="filter-table">
        <div className="row">
          <div className="col-lg-12">
            <p>
              Wählen Sie hier relevante Modernisierungsstände aus. Zum Beispiel
              alle Abrufe bei denen Dach oder Dämmung seit mehr als 10 Jahren
              nicht modernisiert wurden.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Modernisierung: Dacherneuerung</label>
              <select
                className="form-control"
                value={this.state.selectedDach}
                onChange={this.handleChangeDach}
              >
                <option value="">Egal / Alle</option>
                {Object.keys(this.state.mod_dacherneuerungData).map(
                  (key: any, index: number) => (
                    <option
                      key={index + 'dach'}
                      value={this.state.mod_dacherneuerungData[key]}
                    >
                      {key}
                    </option>
                  ),
                )}
              </select>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Modernisierung: Dämmung</label>
              <select
                className="form-control"
                value={this.state.selectedDaemmung}
                onChange={this.handleChangeDaemmung}
              >
                <option value="">Egal / Alle</option>
                {Object.keys(this.state.mod_aussenwaendeData).map(
                  (key: any, index: number) => (
                    <option
                      key={index + 'aussen'}
                      value={this.state.mod_aussenwaendeData[key]}
                    >
                      {key}
                    </option>
                  ),
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Modernisierung: Fenster & Außentüren</label>
              <select
                className="form-control"
                value={this.state.selectedFenster}
                onChange={this.handleChangeFenster}
              >
                <option value="">Egal / Alle</option>
                {Object.keys(this.state.mod_fenster_und_aussentuerenData).map(
                  (key: any, index: number) => (
                    <option
                      key={index + 'fenster'}
                      value={this.state.mod_fenster_und_aussentuerenData[key]}
                    >
                      {key}
                    </option>
                  ),
                )}
              </select>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Modernisierung: Heizung</label>
              <select
                className="form-control"
                value={this.state.selectedHeizung}
                onChange={this.handleChangeHeizung}
              >
                <option value="">Egal / Alle</option>
                {Object.keys(this.state.mod_heizungsanlageData).map(
                  (key: any, index: number) => (
                    <option
                      key={index + 'heizung'}
                      value={this.state.mod_heizungsanlageData[key]}
                    >
                      {key}
                    </option>
                  ),
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Modernisierung: Fußböden</label>
              <select
                className="form-control"
                value={this.state.selectedFussboden}
                onChange={this.handleChangeFussboden}
              >
                <option value="">Egal / Alle</option>
                {Object.keys(this.state.mod_fussboedenData).map(
                  (key: any, index: number) => (
                    <option
                      key={index + 'fussboeden'}
                      value={this.state.mod_fussboedenData[key]}
                    >
                      {key}
                    </option>
                  ),
                )}
              </select>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Modernisierung: Innenwände & Türen</label>
              <select
                className="form-control"
                value={this.state.selectedInnenwaende}
                onChange={this.handleChangeInnenwaende}
              >
                <option value="">Egal / Alle</option>
                {Object.keys(this.state.mod_innenwaende_und_tuerenData).map(
                  (key: any, index: number) => (
                    <option
                      key={index + 'innen'}
                      value={this.state.mod_innenwaende_und_tuerenData[key]}
                    >
                      {key}
                    </option>
                  ),
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Modernisierung: Bad</label>
              <select
                className="form-control"
                value={this.state.selectedBad}
                onChange={this.handleChangeBad}
              >
                <option value="">Egal / Alle</option>
                {Object.keys(this.state.mod_badData).map(
                  (key: any, index: number) => (
                    <option
                      key={index + 'bad'}
                      value={this.state.mod_badData[key]}
                    >
                      {key}
                    </option>
                  ),
                )}
              </select>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Modernisierung: Elektroinstallation</label>
              <select
                className="form-control"
                value={this.state.selectedElektro}
                onChange={this.handleChangeElektro}
              >
                <option value="">Egal / Alle</option>
                {Object.keys(this.state.mod_elektroinstallationenData).map(
                  (key: any, index: number) => (
                    <option
                      key={index + 'elektro'}
                      value={this.state.mod_elektroinstallationenData[key]}
                    >
                      {key}
                    </option>
                  ),
                )}
              </select>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-12">
            <CustomCheckbox
              label={
                'Personenbezogene Daten mit exportieren, sofern möglich (Bitte beachten Sie die EU-DSGVO!)'
              }
              checked={this.state.personenbezogeneDaten}
              onCheckValue={this.handlePersonenbezogeneDaten}
            />
          </div>
        </div> */}

        {/*  <div className="row mt-3">
          <div className="col-lg-5 offset-lg-7">
            <button
              className="btn btn-primary btn-block"
              //  onClick={this.refresh}
            >
              Tabelle aktualisieren
            </button>
          </div> 
        </div>*/}
      </div>
    );
  }
}
