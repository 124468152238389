import Config from './config';
import { stringify } from 'querystring';
import ClientError from '../exceptions/ClientError';
import Auth from './auth';

export class API {

  static async request(
    method: string,
    path: string,
    body?: any,
    fullURL?: boolean,
  ) {
    let err, response;

    let opts: any = {
      method: method,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };

    if (Auth.accessToken) {
      opts.headers['Authorization'] = `Bearer ${Auth.accessToken}`;
    }

    if (body) {
      for (let key in body) {
        if (body.hasOwnProperty(key)) {
          if (body[key] instanceof Date) {
            let date = body[key] as Date;
            body[key] = `${date.getFullYear()}-${
              date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1
            }-${date.getDate()}T00:00:00.000`;
          }
        }
      }
      Object.assign(opts, {
        body: JSON.stringify(body),
      });
    }

    // Perform API request
    let apiURL = Config.getAPIURL();
    const win = window as any;
    if (win.apiURL) apiURL = win.apiURL;
    //${apiURL}/${path}
    response = await fetch(`${fullURL ? path : apiURL + '/' + path}`, opts);

    if (response.status === 400) {
      throw new ClientError(await response.json());
    }

    // Invalid token
    if (response.status === 401) {
      window.location.reload();
      throw new Error('Unauthorized');
    }

    // Invalid token
    if (response.status === 403) {
      window.location.reload();
      throw new Error('Access denied');
    }

    // Invalid status codes
    if (response.status > 299) {
      throw new Error(`Invalid API response: ${response.body}`);
    }

    if (['DELETE', 'PUT'].indexOf(method) > -1) {
      // Method excepts no response
      return true;
    } else {
      return await response.json();
    }
  }

  static async get(path: string, params?: any) {
    let queryString = stringify(params);
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    return API.request('GET', path + queryString);
  }

  static async postFullURL(path: string, body: any) {
    return API.request('POST', path, body, true);
  }

  static async post(path: string, body: any) {
    return API.request('POST', path, body);
  }

  static async patch(path: string, body: any) {
    return API.request('PATCH', path, body);
  }

  static async put(path: string, body: any) {
    return API.request('PUT', path, body);
  }

  static async delete(path: string) {
    return API.request('DELETE', path);
  }

}
